// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconPit = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M10.8626 20.0898V8.26747L5.50768 13.6224L3.9104 12.0001L12.0001 3.9104L20.0898 12.0001L18.4925 13.6224L13.1376 8.26747V20.0898H10.8626Z"/>
    </SvgIcon>
  )
}

export default IconPit