import { useState } from 'react'

// COMPONENTS
import MapChildrenZoom from 'components/PanelZoom/MapChildrenZoom'
import PanelChangeMap from 'components/PanelChangeMap/PanelChangeMap'
import PanelZoom from 'components/PanelZoom/PanelZoom'

// CONSTANTS
import mapOptionList from 'constants/mapOptionList'

// LEAFLET
import { 
  MapContainer, 
  TileLayer,
} from 'react-leaflet'

// STYLES
import useStyles from './mapUseStyles'

const Map = (props) => {
  const { setMap, children } = props
  const classes = useStyles()

  const [ selectedMapObject, setSelectedMapObject ] = useState(mapOptionList[0])
  const [ zoom, setZoom ] = useState(5) 

  return (
    <>
      {/* MAP */}
      <MapContainer
        center={[-0.7893, 113.9213]}
        className={`${classes.mapContainer} no-zoom`}
        zoomControl={false}
        zoom={zoom}
        whenCreated={(mapObject) => setMap && setMap(mapObject)}
        editable={true}
      >
        {/* BASE MAP */}
        <TileLayer
          // attribution='&copy <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors'
          url={selectedMapObject.url}
          subdomains={['mt1', 'mt2', 'mt3']}
          minZoom={2}
          maxZoom={20}
          key={selectedMapObject.name}
        />

        {/* MAP CHILDREN ZOOM */}
        <MapChildrenZoom 
          zoom={zoom}
          setZoom={setZoom}
        />

        {children}
      </MapContainer>

      {/* CHANGE MAP PANEL */}
      <PanelChangeMap
        mapOptionList={mapOptionList}
        selectedMapObject={selectedMapObject}
        setSelectedMapObject={setSelectedMapObject}
      />

      {/* ZOOM PANEL */}
      <PanelZoom
        zoom={zoom}
        setZoom={setZoom}
      />
    </>
  )
}

export default Map