import { useState, useEffect, useContext } from 'react'

// ASSETS
import IconRouteVehicle from 'assets/images/icons/IconRouteVehicle'

// COMPONENTS
import CustomDataGridObjects from 'components/DataGridObjects/CustomDataGridObjects'
import GroupingCell from 'components/DataGridObjects/GroupingCell'
import GroupingHeader from 'components/DataGridObjects/GroupingHeader'

// CONSTANTS
import { headerHeight } from '../reportsConstants'

// CONTEXTS
import { ReportsPageContext } from 'contexts/ReportsPageContext'

// MUIS
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI DATA GRID
import { useGridApiRef } from '@mui/x-data-grid-premium'

// SERVICES
import { getTrackerList } from 'services/trackerServices'

// UTILITIES
import { 
  getCheckboxColumn, 
  filterObjectListBySearchPanelObject, 
  handleCellClickPanelObject, 
  handleColumnHeaderClickPanelObject, 
} from 'utils/dataGrid'

const VehiclesPanel = () => {
  const { 
    objectsSelectionModel, setObjectsSelectionModel, 
    editReportObject,
  } = useContext(ReportsPageContext)

  const dataGridApiRef = useGridApiRef()

  const [ objectList, setObjectList ] = useState([])
  const [ filteredObjectList, setFilteredObjectList ] = useState([])
  const [ objectListSearch, setObjectListSearch ] = useState('')

  const objectListColumns = [{
    field: 'label',
    headerName: '',
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    renderCell: (params) => <GroupingCell {...params}/>,
    renderHeader: (params) => (
      <GroupingHeader
        {...params}
        setSearch={setObjectListSearch}
        search={objectListSearch}
        headerWidth={260}
      />
    )
  }]

  const loadObjectList = async () => {
    const resultObjectList = await getTrackerList()

    if (resultObjectList.status === 200) setObjectList(resultObjectList.data)
  }

  useEffect(() => {
    loadObjectList()
  }, [])

  useEffect(() => {
    filterObjectListBySearchPanelObject(objectListSearch, objectList, setFilteredObjectList)
  }, [objectList, objectListSearch])

  useEffect(() => {
    if (editReportObject) setObjectsSelectionModel(editReportObject?.deviceObjectList?.map(item => item.id))
  }, [editReportObject])

  return (
    <Stack
      width={300}
      height='100%'
      backgroundColor={(theme) => theme.palette.common.white}
      borderRight={(theme) => `1px solid ${theme.palette.divider}`}
    >
      {/* HEADER */}
      <Stack
        height={headerHeight}
        direction='row'
        alignItems='center'
        padding='0px 8px 0px 16px'
        gap='4px'
      >
        {/* ICON */}
        <IconRouteVehicle/>

        {/* TITLE */}
        <Typography 
          fontWeight={600} 
          noWrap
        >
          Vehicles
        </Typography>
      </Stack>

      <Divider/>

      {/* OBJECT LIST */}
      <Stack 
        flex='1' 
        width='100%'
        height='100%'
      >
        <CustomDataGridObjects
          apiRef={dataGridApiRef}
          columns={[ getCheckboxColumn(dataGridApiRef), ...objectListColumns ]}
          selectionModel={objectsSelectionModel}
          rows={filteredObjectList}
          onCellClick={(params, event, details) => handleCellClickPanelObject(params, event, details, objectsSelectionModel, setObjectsSelectionModel)}
          onColumnHeaderClick={(params, event, details) => handleColumnHeaderClickPanelObject(params, event, details, objectsSelectionModel, setObjectsSelectionModel, dataGridApiRef)}
        />
      </Stack>
    </Stack>
  )
}

export default VehiclesPanel