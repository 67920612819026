// APIS
import axios from 'apis/axios'

// SERVICES
import { postRefreshToken } from './tokenServices'

// UTILS
import queryString from 'query-string'

export const getLiveCycleList = async (inputQuery) => {
    const resultRefreshToken = await postRefreshToken()
  
    if(resultRefreshToken.status === 200) {
      try {
        const response = await axios.get(
          `devices/live-cycle?${queryString.stringify(inputQuery)}`,
          { 
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${resultRefreshToken.data.accessToken}`,
            },
          },
        )
    
        return response
      }
      catch (error) {
        if (!error.response) return { status: 'No Server Response' }
        else return error.response
      }
    }
  }