import React, { useState, useEffect, useRef, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

// COMPONENTS
import LoadingBox from 'components/LoadingBox/LoadingBox'
import DataGridFilters from 'components/DataGridFilters/DataGridFilters'
import DialogChangeStatus from './DialogChangeStatus/DialogChangeStatus'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'
import DialogAddOrEditVehicle from './DialogAddOrEditVehicle/DialogAddOrEditVehicle'
import PageHeader from 'components/PageHeader/PageHeader'

// COTEXTS
import { MainLayoutContext } from 'contexts/MainLayoutContext'

// CUSTOM COMPONENTS
import CustomDataGridPro from 'components/Customs/CustomDataGridPro'
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// MUIS
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'

// MUI ICONS
import IconDelete from '@mui/icons-material/Delete'
import IconEdit from '@mui/icons-material/Edit'

// SERVICES
import { getVehicleList, putDeleteVehicle } from 'services/vehicleServices'

// STYELS
import pageStyles from 'styles/layoutMainPageUseStyles'
import useStyles from './vehiclesUseStyles'

// UTILITIES
import { convertDateToUserTimezone } from 'utils/date'
import { getIconVehicleById } from './VehiclesUtils'

const Vehicles = () => {
  const pageClasses = pageStyles()
  const classes = useStyles()

  const pageRef = useRef()

  const { changeSnackbarObject } = useContext(MainLayoutContext)

  const navigate = useNavigate()

  const initialColumns = [
    {
      field: 'icon',
      headerName: '',
      width: 50,
      renderHeader: () => (<></>),
      sortable: false,
      renderCell: (params) => {
        const Icon = getIconVehicleById(params.value).Icon
        if (!Icon) return ''
        return (<Stack
          alignItems='center'
          justifyContent='center'
          className={classes.columnVehicleImage}
        >
          <Icon className={classes.iconVehicle} fontSize='small'/>
        </Stack>)
      },
    },
    {
      field: 'vehicleName',
      headerName: 'Vehicle',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'deviceObject',
      headerName: 'GPS ID',
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.value?.id,
    },
    {
      field: 'licenseNumber',
      headerName: 'License Plate',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'year',
      headerName: 'Year',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'lastUpdated',
      headerName: 'Last Update',
      flex: 1,
      minWidth: 200,
      valueGetter: (params) => convertDateToUserTimezone(params.value),
    },
    {
      field: 'actions',
      headerName: '',
      width: 120,
      hide: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Stack
          width='100%'
          direction='row'
          justifyContent='center'
          alignItems='center'
        >
          {/* EDIT ICON */}
          <CustomTooltipBlack
            title='Edit'
            placement='top'
          >
            <IconButton onClick={(event) => handleEditButtonClick(event, params.row)}>
              <IconEdit/>
            </IconButton>
          </CustomTooltipBlack>

          {/* DELETE ICON */}
          <CustomTooltipBlack
            title='Delete'
            placement='top'
          >
            <IconButton onClick={(event) => handleDeleteButtonClick(event, params.row)}>
              <IconDelete/>
            </IconButton>
          </CustomTooltipBlack>
        </Stack>
      ),
    },
  ]

  const [ search, setSearch ] = useState('')
  const [ tableData, setTableData ] = useState([])
  const [ selectionModel, setSelectionModel ] = useState([])
  const [ dialogAddOrEditVehicle, setDialogAddOrEditVehicle ] = useState(null)
  const [ isLoading, setIsLoading ] = useState(false)
  const [ selectedColumnList, setSelectedColumnList ] = useState(initialColumns)
  const [ dialogChangeStatus, setDialogChangeStatus ] = useState(null)
  const [ dialogDeleteVehicle, setDialogDeleteVehicle ] = useState(null)
  const [ shouldReloadData, setShouldReloadData ] = useState(true)

  const handleDownloadIconButtonClick = () => {}

  const handleDeleteButtonClick = (event, row) => {
    event.stopPropagation()

    setDialogDeleteVehicle(row)
  }

  const handleEditButtonClick = (event, row) => {
    event.stopPropagation()

    setDialogAddOrEditVehicle({
      type: 'edit',
      data: row
    })
  }

  const handleContinueDeleteButtonClick = async () => {
    const resultDeleteVehicle = await putDeleteVehicle(dialogDeleteVehicle.id)

    if (resultDeleteVehicle.status === 200) {
      setDialogDeleteVehicle(null)
      setShouldReloadData(true)
    }
    else changeSnackbarObject({
      open: true,
      severity: 'error',
      message: resultDeleteVehicle.data.message,
    })
  }

  const getVehicleListData = async () => {
    setIsLoading(true)

    const resultVehicleList = await getVehicleList()
    
    if (resultVehicleList.status === 200) {
      setTableData(resultVehicleList.data)
      setShouldReloadData(false)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    shouldReloadData && getVehicleListData()
  }, [shouldReloadData])

  return (
    <Box 
      className={pageClasses.pageRoot}
      ref={pageRef}
    >
      {/* PAGE HEADER */}
      <PageHeader
        isAddButtonAvailable={true}
        onAddButtonIsClicked={() => setDialogAddOrEditVehicle({ type: 'add' })}
        isBackButtonAvailable={false}
        targetBackUrl={null}
        title='Vehicles'
        isSearchAvailable={true}
        search={search}
        setSearch={setSearch}
        isExtraComponentAvailable={false}
        extraComponent={null}
      />

      {/* CONTENT */}
      <LoadingBox isLoading={isLoading}>
        {/* DATA GRID FILTERS */}
        <DataGridFilters
          isWithTabs={false}
          title={'Vehicle List'}
          selectedTab={null}
          setSelectedTab={null}
          tabItemList={null}
          isWithDownload={true}
          handleDownloadIconButtonClick={handleDownloadIconButtonClick}
          isWithCustomizeColumn={true}
          columns={initialColumns}
          selectedColumnList={selectedColumnList}
          setSelectedColumnList={setSelectedColumnList}
          isWithDateRange={false}
          dateRangeValue={null}
          setDateRangeValue={null}
        />

        {/* TABLE */}
        <CustomDataGridPro
          columns={selectedColumnList}
          rows={tableData}
          onRowClick={(params, event, details) => navigate(`/vehicles/${params.id}`)}
          selectionModel={selectionModel}
          setSelectionModel={setSelectionModel}
        />
      </LoadingBox>

      {/* DIALOG ADD OR EDIT VEHICLE */}
      <DialogAddOrEditVehicle
        dialogAddOrEditVehicle={dialogAddOrEditVehicle}
        setDialogAddOrEditVehicle={setDialogAddOrEditVehicle}
        pageRef={pageRef}
        setIsLoading={setIsLoading}
        setShouldReloadTableData={setShouldReloadData}
      />

      {/* DIALOG CHANGE STATUS */}
      {dialogChangeStatus &&
      <DialogChangeStatus
        dialogChangeStatus={dialogChangeStatus}
        setDialogChangeStatus={setDialogChangeStatus}
      />}

      {/* DIALOG DELETE VEHICLE */}
      <DialogConfirmation
        title='Delete Vehicle'
        caption='Are you sure you want to delete this vehicle?'
        dialogConfirmationObject={dialogDeleteVehicle}
        setDialogConfirmationObject={setDialogDeleteVehicle}
        cancelButtonText='Cancel'
        continueButtonText='Delete'
        onContinueButtonClick={handleContinueDeleteButtonClick}
        onCancelButtonClick={() => setDialogDeleteVehicle(null)}
      />
    </Box>
  )
}

export default Vehicles