// CONTEXTS
import { ReportsPageContextProvider } from 'contexts/ReportsPageContext'

// PAGES
import Canbus from 'pages/Canbus/Canbus'
import Cycles from 'pages/Cycles/Cycles'
import Dashboard from 'pages/Dashboard/Dashboard'
import FuelReport from 'pages/FuelReport/FuelReport'
import LiveCycle from 'pages/LiveCycle/LiveCycle'
import Payload from 'pages/Payload/Payload'
import Reports from 'pages/Reports/Reports'
import Routes from 'pages/Routes/Routes'
import RoutesDetail from 'pages/RoutesDetail/RoutesDetail'
import Settings from 'pages/Settings/Settings'
import SignIn from 'pages/SignIn/SignIn'
import Tracking from 'pages/Tracking/Tracking'
import VehiclesDetail from 'pages/VehiclesDetail/VehiclesDetail'
import Vehicles from 'pages/Vehicles/Vehicles'
import Zones from 'pages/Zones/Zones'

const routes = [
  {
    path: '/sign-in',
    element: <SignIn/>,
    routeType: 'authentication',
  },
  {
    path: '/',
    element: <Dashboard/>,
    routeType: 'private',
  },
  {
    path: '/fuel-report',
    element: <FuelReport/>,
    routeType: 'private',
  },
  {
    path: '/payload',
    element: <Payload/>,
    routeType: 'private',
  },
  {
    path: '/canbus',
    element: <Canbus/>,
    routeType: 'private',
  },
  {
    path: '/routes',
    element: <Routes/>,
    routeType: 'private',
  },
  {
    path: '/routes/deviceId=:deviceId/shift=:shift',
    element: <RoutesDetail/>,
    routeType: 'private',
  },
  {
    path: '/reports',
    element: (
      <ReportsPageContextProvider>
        <Reports/>
      </ReportsPageContextProvider>
    ),
    routeType: 'private',
  },
  {
    path: '/settings',
    element: <Settings/>,
    routeType: 'private',
  },
  {
    path: '/tracking',
    element: <Tracking/>,
    routeType: 'private',
  },
  {
    path: '/tracking/live-cycle',
    element: <LiveCycle/>,
    routeType: 'private',
  },
  {
    path: '/vehicles/:vehicleId',
    element: <VehiclesDetail/>,
    routeType: 'private',
  },
  {
    path: '/vehicles',
    element: <Vehicles/>,
    routeType: 'private',
  },
  {
    path: '/zones/cycles',
    element: <Cycles/>,
    routeType: 'private',
  },
  {
    path: '/zones',
    element: <Zones/>,
    routeType: 'private',
  },
]

export default routes