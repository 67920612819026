import { useEffect } from "react";
import PropType from 'prop-types'

// LEAFLET
import L from "leaflet";
import 'leaflet-editable'

const GeofenceList = (props) => {
  const { map, hasGeofenceListFinishedLoading, selectedGeofenceList, geoJsonGeofenceListRef } = props;

  const drawGeofenceListToMap = () => {
    if (geoJsonGeofenceListRef.current)
      geoJsonGeofenceListRef.current.clearLayers();

      if(map && selectedGeofenceList?.length && !hasGeofenceListFinishedLoading) {
        selectedGeofenceList?.forEach(item => {
          const coordinates = JSON.parse(item.geoJson).features[0].geometry.coordinates
          geoJsonGeofenceListRef.current?.addLayer(L.polygon(coordinates, {
            color: item.color,
            weight: 1,
          }))
        })
  
        geoJsonGeofenceListRef.current?.addTo(map);
    
        const newMapBoundList = geoJsonGeofenceListRef.current?.getBounds();
        map?.fitBounds(newMapBoundList);
      }
  };

  useEffect(() => {
    drawGeofenceListToMap()
  }, [hasGeofenceListFinishedLoading, selectedGeofenceList]);

  return null;
};

GeofenceList.propType = {
  map: PropType.object,
  hasGeofenceListFinishedLoading: PropType.bool,
  selectedGeofenceList: PropType.array,
  geoJsonGeofenceListRef: PropType.object
}

export default GeofenceList;
