import PropTypes from 'prop-types'

// MUIS
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import FormControl from '@mui/material/FormControl'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import TextField from '@mui/material/TextField'

// MUI ICONS
import IconCheckBox from '@mui/icons-material/CheckBox'
import IconCheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank'

// STYLES
import useStyles from './dialogAddOrEditCycleUseStyles'

// UTILITIES
import { lightOrDarkColor } from 'utils/color'

const ZonesAutocomplete = (props) => {
  const {
    icon,
    zoneList,
    label,
    name,
    value,
    onChange,
  } = props

  const classes = useStyles()

  const SelectedIcon = icon

  return (
    <Box 
      className={classes.autocompleteContainer}
      height='unset !important'
    >
      {/* ICON */}
      <SelectedIcon className={classes.iconFormControl}/>

      {/* AUTOCOMPLETE */}
      <FormControl 
        // className='no-zoom' 
        fullWidth
      >
        <Autocomplete
          multiple
          fullWidth
          // className='neutralize-zoom-autocomplete'
          limitTags={2}
          options={zoneList}
          getOptionLabel={(option) => option.name}
          getOptionDisabled={(option) => option.usedIn !== name && option.usedIn !== null}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderOption={(props, option, { selected }) => (
            <ListItem 
              {...props}
              className={`${props.className} ${classes.autocompleteListItem}`}
            >
              {/* CHECKBOX */}
              <ListItemIcon className={classes.autocompleteListItemIcon}>
                <Checkbox
                  checked={selected}
                  icon={<IconCheckBoxOutlineBlank fontSize='small'/>}
                  checkedIcon={<IconCheckBox fontSize='small'/>}
                />
              </ListItemIcon>
                
              {/* LABEL */}
              <Chip
                variant='filled'
                size='small'
                label={option.name} 
                sx={{ 
                  color: lightOrDarkColor(option.color) === 'light' ? '#000000' : '#FFFFFF',
                  backgroundColor: option.color, 
                }}
              />
            </ListItem>
          )}
          renderInput={(params) => (
            <TextField 
              {...params} 
              label={label}
              name={name}
              variant='standard'
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip 
                key={index}
                variant='filled'
                size='small'
                label={option.name} 
                sx={{ 
                  color: lightOrDarkColor(option.color) === 'light' ? '#000000' : '#FFFFFF',
                  backgroundColor: option.color, 
                }}
                {...getTagProps({ index })} 
              />
            ))
          }
          value={value}
          onChange={onChange}
        />
      </FormControl>
    </Box>
  )
}

ZonesAutocomplete.defaultProps = {}

ZonesAutocomplete.propTypes = {
  icon: PropTypes.func.isRequired,
  zoneList: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default ZonesAutocomplete