import React from 'react'
import PropTypes from 'prop-types'

// MUIS
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Slide from '@mui/material/Slide'

// STYLES
import useStyles from './dialogAddOrEditUseStyles'

const DialogAddOrEdit = (props) => {
  const {
    open,
    containerRef,
    children,
    direction,
    position,
  } = props

  const classes = useStyles()

  return (
    <Backdrop
      open={open}
      className={classes.root}
    >
      <Slide 
        direction={direction} 
        in={open} 
        container={containerRef.current}
      >
        <Box 
          className={classes.contentContainer}
          sx={position === 'right' ? { right: 0, position: 'absolute' } : {}}
        >
          {children}
        </Box>
      </Slide>
    </Backdrop>
  )
}

DialogAddOrEdit.defaultProps = {
  open: false,
  direction: 'right',
  position: 'left',
}

DialogAddOrEdit.propTypes = {
  open: PropTypes.bool.isRequired,
  containerRef: PropTypes.oneOfType([
    PropTypes.func, 
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
  children: PropTypes.node.isRequired,
  direction: PropTypes.oneOf(['left', 'right']).isRequired,
  position: PropTypes.oneOf(['left', 'right']).isRequired,
}

export default DialogAddOrEdit