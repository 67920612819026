// CONSTANTS
import { headerHeight } from '../reportsConstants'

// MUIS
import { alpha } from '@mui/material/styles'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    borderRight: `1px solid ${theme.palette.divider}`,
    transition: 'width 0.25s ease-in-out,min-width 0.25s ease-in-out',
  },
  title: {
    transformOrigin: 'top left', 
    position: 'absolute', 
    left: 36,
    top: 60,
  },
  toogleIconCollapse: {
    transform: 'rotateY(0deg)',
    transition: 'transform 0.25s ease-in-out',
  },
  toogleIconExpand: {
    transform: 'rotateY(180deg)',
    transition: 'transform 0.25s ease-in-out',
  },
  createReportButton: {
    margin: 4,
    textTransform: 'none',
    textAlign: 'left',
    justifyContent: 'flex-start',
    paddingLeft: 12,
    fontWeight: 600,
  },
  quickSearchInput: {
    margin: '8px 16px',
  },
  reportList: {
    padding: 4,
    flex: 1,
    maxHeight: 0,
  },
  padding4: {
    padding: 4,
  },
  listItem: {
    '& .MuiSvgIcon-root': {
      visibility: 'hidden',
    },
    '&:hover': {
      '& .MuiSvgIcon-root': {
        visibility: 'visible',
      },
    },
  },
  listItemSelected: {
    '& .MuiListItemButton-root': {
      backgroundColor: alpha(theme.palette.primary.main, 0.12),
    },
    '& .MuiListItemText-root': {
      color: `${theme.palette.primary.main} !important`,
    },
    '& .MuiListItemText-secondary': {
      color: `${theme.palette.primary.main} !important`,
    },
    '& .MuiSvgIcon-root': {
      visibility: 'visible',
      color: `${theme.palette.primary.main} !important`,
    },
  },
  listItemButton: {
    padding: '4px 8px',
    borderRadius: 4,
    height: headerHeight,
    '& .MuiListItemText-secondary': {
      fontSize: 12,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    },
  },
}))

export default useStyles