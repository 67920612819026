import { useState, useEffect, useContext } from 'react'

// COMPONENTS
import DialogAddOrEdit from 'components/DialogAddOrEdit/DialogAddOrEdit'
import ZonesAutocomplete from './ZonesAutocomplete'

// COTEXTS
import { MainLayoutContext } from 'contexts/MainLayoutContext'

// CUSTOM COMPONENTS
import CustomDialogActions from 'components/Customs/CustomDialogActions'
import CustomDialogActionButton from 'components/Customs/CustomDialogActionButton'
import CustomDialogContent from 'components/Customs/CustomDialogContent'
import CustomDialogTitle from 'components/Customs/CustomDialogTitle'
import CustomInput from 'components/Customs/CustomInput'
import CustomInputLabel from 'components/Customs/CustomInputLabel'

// MUIS
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconClose from '@mui/icons-material/Close'
import IconLabel from '@mui/icons-material/Label'

// PATH ICONS
import IconHaul from 'assets/images/icons/IconHaul'
import IconPit from 'assets/images/icons/IconPit'
import IconPort from 'assets/images/icons/IconPort'

// SERVICES
import { getZoneList } from 'services/zoneServices'
import { 
  postCreateCycle, 
  putEditCycle, 
} from 'services/cycleServices'

// STYLES
import useStyles from './dialogAddOrEditCycleUseStyles'

const DialogAddOrEditCycle = (props) => {
  const { 
    dialogType, setDialogAddOrEditType,
    dialogAddOrEditCycle, setDialogAddOrEditCycle,
    pageRef,
    setShouldReloadData,
  } = props

  const classes = useStyles()

  const { changeSnackbarObject } = useContext(MainLayoutContext)

  const initialFormObject = {
    name: '',
    pits: [],
    hauls: [],
    ports: [],
  }

  const [ zoneList, setZoneList ] = useState([])
  const [ formObject, setFormObject ] = useState(initialFormObject)

  const handleAutocompleteChange = (newValue, reason, details, source) => {
    setFormObject(current => {
      const newZoneList = [...zoneList].map(item => {
        let usedIn = null
        if (item.id === details?.option?.id && reason === 'selectOption') usedIn = source
        else if (item.id === details?.option?.id && reason === 'removeOption') usedIn = null
        else if (item.usedIn === source && reason === 'clear') usedIn = null
        else if (source === 'hauls' && reason === 'selectOption' && item.usedIn === 'hauls') usedIn = null
        else usedIn = item.usedIn
  
        return {
          ...item,
          usedIn,
        }
      })
      setZoneList(newZoneList)
  
      let newSourceValue
      if (reason === 'clear') newSourceValue = []
      else if (source === 'hauls') {
        if (reason === 'selectOption') newSourceValue = [ details?.option ]
        else if (reason === 'removeOption') newSourceValue = newValue
      }
      else newSourceValue = newValue
  
      return {
        ...current,
        [source]: newSourceValue,
      }
    })
  }

  const handleCloseDialogButtonClick = () => {
    setFormObject(initialFormObject)
    setZoneList([])
    setDialogAddOrEditType(null)
    setDialogAddOrEditCycle(null)
  }

  const fetchZoneListData = async () => {
    const result = await getZoneList()
    
    if (result.status === 200) {
      const newZoneList = result.data.map(item => {
        let usedIn = null

        if (dialogType === 'edit') {
          if (dialogAddOrEditCycle.pitList.map(pit => pit.id).includes(item.id)) usedIn = 'pits'
          else if (dialogAddOrEditCycle.haulList.map(haul => haul.id).includes(item.id)) usedIn = 'hauls'
          else if (dialogAddOrEditCycle.portList.map(port => port.id).includes(item.id)) usedIn = 'ports'
        }

        return {
          ...item,
          usedIn: dialogType === 'edit' ? usedIn : null,
        }
      })

      setZoneList(newZoneList)
    }
  }

  const updateFormObjectOnEditFeature = async () => {
    const result = await getZoneList()
    
    if (result.status === 200) {
      const newFormObject = {
        name: dialogAddOrEditCycle.calculatorName,
        pits: dialogAddOrEditCycle.pitList,
        hauls: dialogAddOrEditCycle.haulList,
        ports: dialogAddOrEditCycle.portList,
      }

      setFormObject(newFormObject)
    }
  }

  const handleSaveButtonClick = async () => {
    if (dialogType === 'add') {
      const resultCreateCycle = await postCreateCycle({
        calculatorName: formObject.name,
        pits: formObject.pits.map(item => item.id),
        hauls: formObject.hauls.map(item => item.id),
        ports: formObject.ports.map(item => item.id),
      })
  
      if (resultCreateCycle.status === 200) {
        handleCloseDialogButtonClick()
        setShouldReloadData(true)
      }
      else changeSnackbarObject({
        open: true,
        severity: 'error',
        message: resultCreateCycle.data.message,
      })
    }
    else if (dialogType === 'edit') {
      const resultEditCycle = await putEditCycle(
        dialogAddOrEditCycle.id, 
        {
          calculatorName: formObject.name,
          pits: formObject.pits.map(item => item.id),
          hauls: formObject.hauls.map(item => item.id),
          ports: formObject.ports.map(item => item.id),
        },
      )
  
      if (resultEditCycle.status === 200) {
        handleCloseDialogButtonClick()
        setShouldReloadData(true)
      }
      else changeSnackbarObject({
        open: true,
        severity: 'error',
        message: resultEditCycle.data.message,
      })
    }
  }

  useEffect(() => {
    fetchZoneListData()

    if (dialogType === 'edit') updateFormObjectOnEditFeature()
  }, [dialogType, dialogAddOrEditCycle])
    
  return (
    <DialogAddOrEdit
      open={Boolean(dialogAddOrEditCycle)}
      containerRef={pageRef}
    >
      {/* DIALOG TITLE */}
      <CustomDialogTitle className={classes.dialogTitle}>
        {/* CLOSE ICON */}
        <IconClose
          className={classes.iconClose}
          onClick={handleCloseDialogButtonClick}
        />

        {/* TITLE */}
        <Typography className={classes.titleText}>
          {dialogType === 'add' && 'Add a New Cycle'}
          {dialogType === 'edit' && 'Edit a Cycle'}
        </Typography>
      </CustomDialogTitle>

      {/* DIALOG CONTENT */}
      <CustomDialogContent>
        {/* NAME INPUT */}
        <Box className={classes.iconAndFormControlContainer}>
          <IconLabel className={classes.iconFormControl}/>
          <FormControl 
            variant='standard' 
            fullWidth
          >
            <CustomInputLabel>
              Name
            </CustomInputLabel>
            <CustomInput
              type='text'
              name='name'
              value={formObject.name}
              onChange={(event) => setFormObject(current => {
                return {
                  ...current,
                  name: event.target.value, 
                }
              })}
            />
          </FormControl>
        </Box>

        {/* PITS AUTOCOMPLETE */}
        <ZonesAutocomplete
          icon={IconPit}
          zoneList={zoneList}
          label='Pits'
          name='pits'
          value={formObject.pits}
          onChange={(event, newValue, reason, details) => handleAutocompleteChange(newValue, reason, details, 'pits')}
        />

        {/* HAUL AUTOCOMPLETE */}
        <ZonesAutocomplete
          icon={IconHaul}
          zoneList={zoneList}
          label='Hauls'
          name='hauls'
          value={formObject.hauls}
          onChange={(event, newValue, reason, details) => handleAutocompleteChange(newValue, reason, details, 'hauls')}
        />

        {/* PORTS AUTOCOMPLETE */}
        <ZonesAutocomplete
          icon={IconPort}
          zoneList={zoneList}
          label='Ports'
          name='ports'
          value={formObject.ports}
          onChange={(event, newValue, reason, details) => handleAutocompleteChange(newValue, reason, details, 'ports')}
        />
      </CustomDialogContent>

      {/* DIALOG ACTIONS */}
      <CustomDialogActions>
        {/* CANCEL BUTTON */}
        <CustomDialogActionButton 
          className={classes.cancelButton}
          onClick={handleCloseDialogButtonClick}
        >
          Cancel
        </CustomDialogActionButton>

        {/* OK BUTTON */}
        <CustomDialogActionButton onClick={handleSaveButtonClick}>
          Save
        </CustomDialogActionButton>
      </CustomDialogActions>
    </DialogAddOrEdit>
  )
}

export default DialogAddOrEditCycle