// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconVehicleOne = (props) => {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M9.43273 15.4285H12.7327C12.9016 15.4295 13.0488 15.3096 13.0882 15.1391L15.5853 4.76191H18.9659C19.1684 4.76191 19.3326 4.59137 19.3326 4.38095C19.3326 4.17054 19.1684 4 18.9659 4H15.2994C15.1307 4.00015 14.984 4.11964 14.9436 4.28959L14.5549 5.90477H2.09954C1.93096 5.90492 1.7843 6.02442 1.74391 6.19436L1.01057 9.242C0.97405 9.39706 1.03406 9.55941 1.16096 9.64944L9.22749 15.3638C9.28807 15.4062 9.35954 15.4286 9.43273 15.4285ZM10.5436 13.4248L12.0103 7.71059C12.063 7.50642 12.2649 7.38544 12.4613 7.44005C12.6577 7.49481 12.7743 7.70464 12.7216 7.90866L11.255 13.6228C11.2118 13.7896 11.0657 13.9054 10.8994 13.9047C10.8672 13.9044 10.8354 13.9005 10.804 13.8932C10.6086 13.837 10.4928 13.6286 10.5436 13.4248ZM3.92185 7.90866L3.62121 9.07801C3.57638 9.24393 3.43129 9.35896 3.26543 9.36001C3.23349 9.35971 3.20155 9.35599 3.17018 9.34855C2.97496 9.2923 2.85909 9.08381 2.90994 8.87994L3.21057 7.71059C3.26314 7.50642 3.46509 7.38544 3.66146 7.44005C3.85783 7.49481 3.97441 7.70464 3.92185 7.90866ZM6.12171 7.90866L5.49479 10.3503C5.45139 10.5175 5.30559 10.6331 5.1393 10.6323C5.10707 10.6322 5.07513 10.6283 5.04376 10.621C4.84854 10.5646 4.73267 10.3561 4.78352 10.1523L5.41043 7.71059C5.46314 7.50642 5.66509 7.38544 5.86146 7.44005C6.05783 7.49481 6.17441 7.70464 6.12171 7.90866ZM8.32171 7.90866L7.36837 11.6189C7.32526 11.7861 7.17917 11.9017 7.01274 11.9009C6.98079 11.9009 6.94871 11.8971 6.91734 11.8895C6.72227 11.8332 6.60639 11.6248 6.6571 11.4209L7.61043 7.71059C7.663 7.50642 7.86495 7.38544 8.06146 7.44005C8.25783 7.49481 8.37441 7.70464 8.32171 7.90866ZM8.88632 13.1696C8.85438 13.1694 8.82229 13.1657 8.79107 13.1579C8.59585 13.1018 8.47997 12.8934 8.53082 12.6895L9.81043 7.71059C9.863 7.50642 10.0649 7.38544 10.2613 7.44005C10.4577 7.49481 10.5743 7.70464 10.5217 7.90866L9.2421 12.8876C9.19712 13.0535 9.05217 13.1684 8.88632 13.1696Z" />
      <path d="M5.03287 17.7141C5.64031 17.7141 6.13287 17.2026 6.13287 16.5712C6.13287 15.9401 5.64031 15.4285 5.03287 15.4285C4.42529 15.4285 3.93287 15.9401 3.93287 16.5712C3.93287 17.2026 4.42529 17.7141 5.03287 17.7141Z" />
      <path d="M18.2326 17.7141C18.84 17.7141 19.3326 17.2026 19.3326 16.5712C19.3326 15.9401 18.84 15.4285 18.2326 15.4285C17.625 15.4285 17.1326 15.9401 17.1326 16.5712C17.1326 17.2026 17.625 17.7141 18.2326 17.7141Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M20.3666 9.33323H21.5326C21.9255 9.31894 22.3067 9.4749 22.5849 9.76374C22.8629 10.0527 23.013 10.4487 22.9991 10.8571V15.8133C22.9988 16.2958 22.7046 16.7255 22.2659 16.8837C22.1501 16.9299 22.0269 16.9532 21.9028 16.9522H21.5105C21.4984 17.0805 21.4789 17.2077 21.4518 17.3331C21.3925 17.597 21.3039 17.8526 21.1877 18.095C20.536 19.4553 19.1004 20.2076 17.6602 19.9436C16.2199 19.6794 15.1189 18.462 14.9545 16.9522H8.31082C8.11617 18.7604 6.59608 20.0958 4.84754 19.9946C3.09914 19.8933 1.7313 18.3909 1.7313 16.5712C1.7313 14.7519 3.09914 13.2493 4.84754 13.1481C6.59608 13.0469 8.11617 14.3824 8.31082 16.1903H13.5466L16.0436 5.81342C16.0832 5.64288 16.2302 5.52294 16.3993 5.52383H19.3326C19.5068 5.52383 19.657 5.65121 19.692 5.82874L20.3666 9.33323ZM3.19954 16.5712C3.19954 17.6233 4.02025 18.476 5.03288 18.476C6.04537 18.476 6.86621 17.6233 6.86621 16.5712C6.86621 15.5194 6.04537 14.6666 5.03288 14.6666C4.02025 14.6666 3.19954 15.5194 3.19954 16.5712ZM16.9427 6.28574C16.8407 6.28574 16.7536 6.36223 16.737 6.46669L16.4455 8.28233C16.4341 8.35435 16.4535 8.42787 16.4991 8.48337C16.5443 8.53918 16.611 8.57117 16.6813 8.57132H19.326L19.0325 6.28574H16.9427ZM15.402 11.619L15.2223 12.3733L15.2186 12.3809H19.9301L22.2659 14.4608V13.459L20.3043 11.7105C20.2374 11.6521 20.1532 11.6197 20.0659 11.619H15.402ZM16.3993 16.5712C16.3993 17.6233 17.22 18.476 18.2326 18.476C19.2451 18.476 20.0659 17.6233 20.0659 16.5712C20.0659 15.5194 19.2451 14.6666 18.2326 14.6666C17.22 14.6666 16.3993 15.5194 16.3993 16.5712Z" />
    </SvgIcon>
  )
}

export default IconVehicleOne