// MUIS
import MuiGlobalStyles from '@mui/material/GlobalStyles'

const GlobalStyles = () => {
  return (
    <MuiGlobalStyles
      styles={(theme) => ({
        // ALL ELEMENTS
        '*, *::before, *::after': {
          boxSizing: 'border-box',
          shapeRendering: 'geometricPrecision',
          textRendering: 'geometricPrecision',
          imageRendering: 'optimizeQuality',
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          pageBreakInside: 'avoid'
        },

        // GENERAL
        '.colorActionActive': {
          color: `${theme.palette.action.active} !important`,
        },
        '.flexDirectionRow': {
          flexDirection: 'row !important',
        },
        '.heightUnset': {
          height: 'unset !important',
        },
        '.textTransformCapitalize': {
          textTransform: 'capitalize !important',
        },
        '.textTransformNone': {
          textTransform: 'none !important',
        },
      })}
    />
  )
}

export default GlobalStyles