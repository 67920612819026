import React, { createContext, useState } from 'react'

const ReportsPageContext = createContext()

const ReportsPageContextProvider = (props) => {
  // ROOT
  const [ isLoading, setIsLoading ] = useState(false)

  // AVAILABLE REPORTS PANEL
  const reportTypeList = [{
    label: 'Canbus',
    value: 'canbus',
  }, {
    label: 'Speed Detail',
    value: 'speed',
  }, {
    label: 'Trip Summarise With Zone',
    value: 'trip_zone',
  }]
  const [ selectedReportType, setSelectedReportType ] = useState(reportTypeList[0].value)

  // OBJECT PANEL
  const [ objectsSelectionModel, setObjectsSelectionModel ] = useState([])

  // REPORT LIST
  const [ selectedReport, setSelectedReport ] = useState(null)
  const [ shouldReloadReportListData, setShouldReloadReportListData ] = useState(true)

  // DELETE REPORT
  const [ dialogDeleteReport, setDialogDeleteReport ] = useState(null)

  // EDIT REPORT
  const [ editReportObject, setEditReportObject ] = useState(null)

  return (
    <ReportsPageContext.Provider
      value={{
        reportTypeList,
        selectedReportType, setSelectedReportType,
        objectsSelectionModel, setObjectsSelectionModel,
        isLoading, setIsLoading,
        selectedReport, setSelectedReport,
        shouldReloadReportListData, setShouldReloadReportListData,
        dialogDeleteReport, setDialogDeleteReport,
        editReportObject, setEditReportObject,
      }}
    >
      {props.children}
    </ReportsPageContext.Provider>
  )
}

export { ReportsPageContextProvider, ReportsPageContext }