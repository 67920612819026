import { useState, useEffect, useContext } from 'react'

// ASSETS
import IconDrawerToggle from 'assets/images/icons/IconDrawerToggle'

// CONSTANTS
import { headerHeight } from '../reportsConstants'

// CONTEXTS
import { MainLayoutContext } from 'contexts/MainLayoutContext'
import { ReportsPageContext } from 'contexts/ReportsPageContext'

// DATE AND TIME
import { format } from 'date-fns'

// MUIS
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconAdd from '@mui/icons-material/Add'
import IconSearch from '@mui/icons-material/Search'
import IconSettings from '@mui/icons-material/Settings'

// SERVICES
import { getReportList } from 'services/reportServices'

// STYLES
import useStyles from './leftContentUseStyles'

const LeftContent = (props) => {
  const { 
    isLeftContentExpanded, setIsLeftContentExpanded, 
    isCreatingReport, setIsCreatingReport,
  } = props

  const classes = useStyles()

  const { changeSnackbarObject } = useContext(MainLayoutContext)
  const {
    setIsLoading,
    reportTypeList,
    shouldReloadReportListData, setShouldReloadReportListData,
    selectedReportType, setSelectedReportType,
    selectedReport, setSelectedReport,
    editReportObject, setEditReportObject,
  } = useContext(ReportsPageContext)

  const [ reportList, setReportList ] = useState([])

  const loadReportListData = async () => {
    setIsLoading(true)

    const resultReportList = await getReportList()

    if (resultReportList.status === 200) setReportList(resultReportList.data)
    else changeSnackbarObject({
      open: true,
      severity: 'error',
      message: 'Failed to get reports data',
    })

    setShouldReloadReportListData(false)
    setIsLoading(false)
  }

  useEffect(() => {
    if (shouldReloadReportListData) loadReportListData()
  }, [shouldReloadReportListData])

  useEffect(() => {
    if (editReportObject) setSelectedReportType(editReportObject?.type?.toLowerCase())
  }, [editReportObject])

  return (
    <Stack 
      width={isLeftContentExpanded ? 300 : 48} 
      minWidth={isLeftContentExpanded ? 300 : 48}
      height='100%'
      className={classes.root}
    >
      {/* HEADER */}
      <Stack
        height={isLeftContentExpanded ? headerHeight : '100%'}
        direction={isLeftContentExpanded ? 'row' : 'column'}
        justifyContent={isLeftContentExpanded ? 'space-between' : 'unset'}
        alignItems='center'
        padding={isLeftContentExpanded ? '0px 8px 0px 16px' : '16px 0px'}
        position='relative'
      >
        {/* TITLE */}
        <Typography 
          fontWeight={600} 
          className={isLeftContentExpanded ? '' : classes.title}
          sx={{ rotate: isLeftContentExpanded ? 0 : '90deg' }}
          noWrap
        >
          {!isCreatingReport ? 'Generate Reports' : 'Available Reports'}
        </Typography>

        {/* EXPAND/COLLAPSE ICON */}
        <IconButton 
          size='small'
          onClick={() => setIsLeftContentExpanded(current => !current)}
        >
          <IconDrawerToggle 
            color='primary'
            fontSize='small'
            className={isLeftContentExpanded 
              ? classes.toogleIconCollapse 
              : classes.toogleIconExpand
            }
          />
        </IconButton>
      </Stack>

      {isLeftContentExpanded && <Divider/>}

      {isLeftContentExpanded && (!isCreatingReport ?
      // CREATE REPORT BUTTON
      <Button
        startIcon={<IconAdd/>}
        className={classes.createReportButton}
        onClick={() => setIsCreatingReport(true)}
      >
        Create Report
      </Button> : 
      // QUICK SEARCH
      <FormControl 
        variant='standard'
        className={classes.quickSearchInput}
      >
        <Input
          placeholder='Quick Search'
          disableUnderline
          endAdornment={
            <InputAdornment position='end'>
              <IconSearch fontSize='small'/>
            </InputAdornment>
          }
        />
      </FormControl>
      )}

      {isLeftContentExpanded && <Divider/>}

      {isLeftContentExpanded && (!isCreatingReport ?
      // REPORT LIST
      <Stack 
        height={0}
        flex={1}
        overflow='auto'
      >
        <List className={classes.reportList}>
          {reportList.map((item, index) => (
            <ListItem 
              key={index}
              secondaryAction={
                <IconButton 
                  edge='end' 
                  size='small'
                  onClick={() => setEditReportObject(item)}
                >
                  <IconSettings fontSize='small'/>
                </IconButton>
              }
              className={`${classes.padding4} ${classes.listItem} ${selectedReport?.id === item.id ? classes.listItemSelected : ''}`}
            >
              <ListItemButton 
                className={classes.listItemButton}
                onClick={() => setSelectedReport(item)}
              >
                <ListItemText
                  primary={item.name}
                  secondary={`Generated: ${format(new Date(item.createdAt), 'yyyy/MM/dd')} at ${format(new Date(item.createdAt), 'hh:mm a')}`}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Stack> :
      // REPORT TYPE LIST
      <List className={classes.padding4}>
        {reportTypeList.map((item, index) => (
          <ListItem
            key={index}
            className={`${classes.padding4} ${selectedReportType === item.value ? classes.listItemSelected : ''}`}
          >
            <ListItemButton 
              className={`${classes.listItemButton} heightUnset`}
              onClick={() => setSelectedReportType(item.value)}
            >
              <ListItemText primary={item.label}/>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      )}
    </Stack>
  )
}

export default LeftContent