// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  cancelButton: {
    color: colors.icon,
    '&:hover': {
      backgroundColor: alpha(colors.icon, 0.1),
    },
  },
  colorPicker: {
    width: 18,
    height: 18,
    cursor: 'pointer',
    marginRight: 16,
    marginBottom: 4,
    borderRadius: 2,
  },
  colorPickerMenu: {
    marginTop: 12,
  },
  colorWrap: {
    width: 200,
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center',
    paddingLeft: 4,
  },
  dialogContent: {
    padding: 24,
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    padding: 24,
  },
  formControl: {
    width: '100%',
  },
  iconAndFormControlContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%',
  },
  iconFormControl: {
    marginRight: 12,
    marginBottom: 2,
    color: colors.iconDark,
  },
  iconClose: {
    marginRight: 16,
    cursor: 'pointer',
  },
  itemColor: {
    width: 18,
    height: 18,
    marginRight: 6,
    marginBottom: 6,
    cursor: 'pointer',
  },
  panelContainer: {
    position: 'absolute',
    zIndex: 2,
    backgroundColor: 'white',
    width: 460,
    left: -15,
    height: "100%",
    display: 'flex',
    flexDirection: 'column',
  },
  titleText: {
    fontWeight: 600,
    fontSize: 24,
    marginRight: 'auto',
  },
}))

export default useStyles