import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import moment from 'moment'

// COMPONENTS
import CustomDataGridPro from 'components/Customs/CustomDataGridPro'
import LoadingBox from 'components/LoadingBox/LoadingBox'
import PageHeader from 'components/PageHeader/PageHeader'

// MUIS
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// PATH ICONS
import IconHaul from 'assets/images/icons/IconHaul'
import iconMiningTruck from 'assets/images/pictures/iconMiningTruck.svg'
import IconPit from 'assets/images/icons/IconPit'
import IconPort from 'assets/images/icons/IconPort'
import IconRouteVehicle from 'assets/images/icons/IconRouteVehicle'

// SERVICES
import { getLiveCycleList } from 'services/deviceServices'

// STYLES
import useStyles from './liveCycleUseStyles'
import pageStyles from 'styles/layoutMainPageUseStyles'

// UTILS
import { 
  convertCurrentDateToGMT8, 
  convertTimerToText, 
  totalDurationBetweenDate, 
} from 'utils/date'

const LiveCycle = () => {
  const classes = useStyles()
  const pageClasses = pageStyles()

  const columns = [
    {
      field: 'deviceName',
      headerName: 'Vehicle Name',
      width: 160,
      sortable: false,
      renderHeader: (params) => (
        <Typography 
          variant='caption' 
          fontWeight={700} 
          display='block'
        >
          {params.colDef.headerName}
        </Typography>
      ),
      renderCell: (params) => (
        <Stack 
          height='100%' 
          justifyContent='center' 
          padding='0 20px'
        >
          <Box className={classes.columnDevice}>
            {/* ICON */}
            <IconRouteVehicle 
              color='info' 
              className={classes.columnDeviceIcon} 
              inheritViewBox={true} 
            />

            {/* TEXT */}
            <Typography 
              variant='subtitle2' 
              className={classes.columnDeviceText}
            >
              {params.value}
            </Typography>
          </Box>
        </Stack>
      ),
    },
    {
      field: 'totalCycle',
      headerName: 'Cycle',
      width: 88,
      sortable: false,
      renderHeader: (params) => (
        <Typography 
          variant='caption' 
          fontWeight={700} 
          display='block'
        >
          {params.colDef.headerName}
        </Typography>
      ),
      renderCell: (params) => (
        <Stack 
          height='100%' 
          justifyContent='center' 
          padding='0 20px'
        >
          {params.value}
        </Stack>
      )
    },
    {
      field: 'pit',
      headerName: 'PIT',
      flex: 1,
      sortable: false,
      renderHeader: (params) => (
        <Stack 
          direction='row' 
          alignItems='center'
        >
          <IconPit 
            fontSize='small' 
            className={classes.headerColumnIcon} 
          />
          <Typography 
            variant='caption' 
            fontWeight={700} 
            display='block'
          >
            {params.colDef.headerName}
          </Typography>
        </Stack>
      ),
      renderCell: (params) => {
        const isEnded = params.value.isEnded
        const splitDuration = convertTimerToText(params.value.duration)

        return (
          <Stack width='100%'>
            <Stack 
              flex={1} 
              width='100%' 
              alignItems='center' 
              justifyContent='center'
            >
              {(!isEnded && params.value?.currentActive && params.row.positionList) && 
              infoActiveColumn(params.row?.state?.gps?.speed, params.row?.state?.duration ?? '00:00:00', params.row?.state?.mileage?? 0)}

              {isEnded && 
              <Typography variant='subtitle2'>
                {splitDuration?.hour}h {splitDuration?.minute}m
              </Typography>}
            </Stack>

            <Box 
              backgroundColor={`${getColorTimeline(isEnded && 'pit')} !important`} 
              className={classes.timelineBar}
            />

            <Stack 
              className={classes.backgroundInfotimeline} 
              direction='row' 
              justifyContent='space-between'
            >
              <Box 
                marginLeft='-2px' 
                className={classes.timelineCircle} 
                marginTop='-12px'
              />

              <Box className={`${classes.timelineCircle} ${!isEnded && 'inactive'} circleEnd`}/>
            </Stack>

            <Stack 
              className={classes.backgroundInfotimeline} 
              direction='row' 
              justifyContent='space-between'
            >
              <Typography className={classes.timelineClock}>
                {params.value.begin ? format(new Date(params.value.begin), 'HH:mm') : '--:--'}
              </Typography>

              <Typography 
                marginRight={`${params.value.end ? '-16px' : '-10px'} !important`} 
                className={classes.timelineClock}
              >
                {(params.value.end && !params.value?.currentActive) ? format(new Date(params.value.end), 'HH:mm') : '--:--'}
              </Typography>
            </Stack>
          </Stack>
        )
    }},
    {
      field: 'haul',
      headerName: 'HAUL',
      flex: 1,
      sortable: false,
      renderHeader: (params) => (
        <Stack 
          direction='row' 
          alignItems='center'
        >
          <IconHaul 
            fontSize='small' 
            className={classes.headerColumnIcon} 
          />

          <Typography 
            variant='caption' 
            fontWeight={700} display='block'
          >
            {params.colDef.headerName}
          </Typography>
        </Stack>
      ),
      renderCell: (params) => {
        const isEnded = params.value.isEnded
        const splitDuration = convertTimerToText(params.value.duration)

        return (
        <Stack width='100%'>
          <Stack 
            flex={1} 
            width='100%' 
            alignItems='center' 
            justifyContent='center'
          >
            {(!isEnded && params.value?.currentActive && params.row.positionList) && 
            infoActiveColumn(params.row?.state?.gps?.speed, params.row?.state?.duration ?? '00:00:00', params.row?.state?.mileage?? 0)}

            {isEnded && 
            <Typography variant='subtitle2'>
              {splitDuration?.hour}h {splitDuration?.minute}m
            </Typography>}
          </Stack>

          <Box 
            backgroundColor={`${getColorTimeline(isEnded && 'haul')} !important`} 
            className={classes.timelineBar}
          />

          <Stack 
            className={classes.backgroundInfotimeline} 
            direction='row' 
            justifyContent='space-between'
          >
            <Box />

            <Box className={`${classes.timelineCircle} ${!isEnded && 'inactive'} circleEnd`}/>
          </Stack>

          <Stack 
            className={classes.backgroundInfotimeline} 
            direction='row' 
            justifyContent='space-between'
          >
            <Box />

            <Typography 
              marginRight={`${params.value.end ? '-16px' : '-10px'} !important`} 
              className={classes.timelineClock}
            >
              {(params.value.end && !params.value?.currentActive) ? format(new Date(params.value.end), 'HH:mm') : '--:--'}
            </Typography>
          </Stack>
        </Stack>
        )
      }
    },
    {
      field: 'port',
      headerName: 'PORT',
      flex: 1,
      sortable: false,
      renderHeader: (params) => (
        <Stack 
          direction='row' 
          alignItems='center'
        >
          <IconPort 
            fontSize='small' 
            className={classes.headerColumnIcon} 
          />

          <Typography 
            variant='caption' 
            fontWeight={700} 
            display='block'
          >
            {params.colDef.headerName}
          </Typography>
        </Stack>
      ),
      renderCell: (params) => {
        const isEnded = params.value.isEnded
        const splitDuration = convertTimerToText(params.value.duration)

        return (
        <Stack width='100%'>
          <Stack 
            flex={1} 
            width='100%' 
            alignItems='center' 
            justifyContent='center'
          >
            {(!isEnded && params.value?.currentActive && params.row.positionList) && 
            infoActiveColumn(params.row?.state?.gps?.speed, params.row?.state?.duration ?? '00:00:00', params.row?.state?.mileage?? 0)}

            {isEnded && 
            <Typography variant='subtitle2'>
              {splitDuration?.hour}h {splitDuration?.minute}m
            </Typography>}
          </Stack>

          <Box 
            backgroundColor={`${getColorTimeline(isEnded && 'port')} !important`} 
            className={classes.timelineBar}
          />

          <Stack 
            className={classes.backgroundInfotimeline} 
            direction='row' 
            justifyContent='space-between'
          >
            <Box />

            <Box className={`${classes.timelineCircle} ${!isEnded && 'inactive'} circleEnd`}/>
          </Stack>

          <Stack 
            className={classes.backgroundInfotimeline} 
            direction='row' 
            justifyContent='space-between'
          >
            <Box />

            <Typography 
              marginRight={`${params.value.end ? '-16px' : '-10px'} !important`} 
              className={classes.timelineClock}
            >
              {(params.value.end && !params.value?.currentActive) ? format(new Date(params.value.end), 'HH:mm') : '--:--'}
            </Typography>
          </Stack>
        </Stack>
        )
      }
    },
    {
      field: 'return',
      headerName: 'RETURN',
      flex: 1,
      sortable: false,
      renderHeader: (params) => (
        <Stack 
          direction='row' 
          alignItems='center'
        >
          <IconHaul 
            fontSize='small' 
            className={classes.headerColumnIcon} 
          />

          <Typography 
            variant='caption' 
            fontWeight={700} 
            display='block'
          >
            {params.colDef.headerName}
          </Typography>
        </Stack>
      ),
      renderCell: (params) => {
        const isEnded = params.value?.isEnded
        const splitDuration = convertTimerToText(params.value.duration)

        return (
        <Stack width='100%'>
          <Stack 
            flex={1} 
            width='100%' 
            alignItems='center' 
            justifyContent='center'
          >
            {params.value?.currentActive && 
            infoActiveColumn(params.row?.state?.gps?.speed, params.row?.state?.duration ?? '00:00:00', params.row?.state?.mileage?? 0)}

            {isEnded && 
            <Typography variant='subtitle2'>
              {splitDuration?.hour}h {splitDuration?.minute}m
            </Typography>}
          </Stack>

          <Box 
            backgroundColor={`${getColorTimeline(isEnded && 'return')} !important`} 
            className={classes.timelineBar}
          />

          <Stack 
            className={classes.backgroundInfotimeline} 
            direction='row' 
            justifyContent='space-between'
          >
            <Box />

            <Box 
              marginRight='-1px !important' 
              className={`${classes.timelineCircle} ${!isEnded && 'inactive'} circleEnd`}
            />
          </Stack>

          <Stack 
            className={classes.backgroundInfotimeline} 
            direction='row' 
            justifyContent='space-between'
          >
            <Box />

            <Typography 
              marginRight='2px !important' 
              className={classes.timelineClock}
            >
                {(params.value.end && !params.value?.currentActive) ? format(new Date(params.value.end), 'HH:mm') : '--:--'}
            </Typography>
          </Stack>
        </Stack>
        )
      }
    },
  ]

  const [ dataLiveCycle, setDataLiveCycle ] = useState([])
  const [ dataSocketState, setDataSocketState ] = useState([])
  const [ search, setSearch ] = useState('')
  const [ isLoading, setIsLoading ] = useState(false)

  const getCurrentShiftHour = () => {
    const start =  6 * 60 + 0 // 06:00
    const end   = 17 * 60 + 59 // 17:59
    const current = convertCurrentDateToGMT8(new Date()).getHours() * 60 + convertCurrentDateToGMT8(new Date()).getMinutes()

    if (current >= start && current < end) return 1 // shift 06:00 - 17:59
    else return 2 // shift 18:00 - 05:59
  }

  const fetchDataLiveCycle = async () => {
    setIsLoading(true)

    const response = await getLiveCycleList({ shift: getCurrentShiftHour(), deviceName: search })

    setDataLiveCycle(response?.data?.length ? restructureData(response?.data) : [])

    setIsLoading(false)
  }

  const infoActiveColumn = (speed, duration, journey) => {
    return (
      <Stack direction='row'>
        <Box 
          component='img' 
          src={iconMiningTruck} 
          width='28px' 
        />

        <Box marginLeft='4px'>
          <Typography 
            fontWeight={500} 
            fontSize='10px' 
            color='text.secondary'
          >
            {speed ?? 0} km/h
          </Typography>

          <Typography 
            fontWeight={500} 
            fontSize='10px'
          >
            {duration} // {journey !== 'No Data' ? `${journey.toFixed(2)} km` : 'No Data'}
          </Typography>
        </Box>
      </Stack>
    )
  }

  const getColorTimeline = (type) => {
    if (type === 'pit') return '#2BC628'
    else if (type === 'haul') return '#0066FF'
    else if (type === 'port') return '#ED0D28'
    else if (type === 'return') return '#FFB400'
    else return '#EEEEEE'
  }

  const combineMultipleField = (positionList, type) => {
    if (!positionList) return null

    const filterData = positionList?.filter(item => item.zone.toLowerCase().includes(type))

    if (filterData?.length === 1) return filterData[0]
    else if (filterData?.length >= 2) {
      const beginArr = filterData.map(item => moment(item.begin).utcOffset(8))
      const endArr = filterData.map(item => moment(item.end).utcOffset(8))
      const convertTimer = totalDurationBetweenDate(moment.min(beginArr).toString(), moment.max(endArr).toString())

      return {
        begin: moment.min(beginArr).toString(),
        end: moment.max(endArr).toString(),
        duration: `${convertTimer.hour}:${convertTimer.minute}`,
        zone: 'PORT'
      }
    }
    else return null
  }

  const getLastPositionDetail = (positionList = []) => positionList[positionList.length-1]

  const restructureData = (inputData) => {
    return inputData.map((item, index) => {
      const currentZoneActive = getLastPositionDetail(item.positionList)?.zone?.toLowerCase()
      let newItem = {...item, id: index+1 }
      
      if (combineMultipleField(item.positionList, 'pit')) {
        newItem.pit = {
          ...combineMultipleField(item.positionList, 'pit'),
          currentActive: currentZoneActive?.includes('pit'),
          isEnded: Boolean(combineMultipleField(item.positionList, 'haul'))
        }
      } else {
        newItem.pit = {}
      }

      if (combineMultipleField(item.positionList, 'haul')) {
        newItem.haul = {
          ...combineMultipleField(item.positionList, 'haul'),
          currentActive: currentZoneActive?.includes('haul'),
          isEnded: Boolean(combineMultipleField(item.positionList, 'port'))
        }
      } else {
        newItem.haul = {}
      }

      if (combineMultipleField(item.positionList, 'port')) {
        newItem.port = {
          ...combineMultipleField(item.positionList, 'port'),
          currentActive: currentZoneActive?.includes('port'),
          isEnded: Boolean(combineMultipleField(item.positionList, 'return'))
        }
      } else {
        newItem.port = {}
      }

      if (combineMultipleField(item.positionList, 'return')) {
        newItem.return = {
          ...combineMultipleField(item.positionList, 'return'),
          currentActive: currentZoneActive?.includes('return'),
          isEnded: false
        }
      } else {
        newItem.return = {}
      }
      
      return {...newItem, state: {}}
    })
  }

  const updateDataLiveCycleStates = (inputDataLiveCycle, inputDataStates) => {
    const newLiveCycle = inputDataLiveCycle.map(itemLiveCycle => {
      const findState = inputDataStates.find(stateItem => {
        const idState = Number(Object.keys(stateItem)[0])
        if (idState === itemLiveCycle.deviceId) return true
      })

      return {
        ...itemLiveCycle,
        state: findState ? Object.values(findState)[0] : itemLiveCycle.state
      }
    })

    setDataLiveCycle([...newLiveCycle])
  }

  useEffect(() => {
    fetchDataLiveCycle()

    const ws = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL)

    ws.onopen = (e) => {
      ws.send(JSON.stringify({
        key: '07431f88-4731-4017-b361-3c3ea32d622c',
        action: 'subscribe',
        event: 'state'
     }))
    }

    // TEMP DATA STATE SOCKET
    let dataState = []

    // ACTION INTERVAL
    const flush = () => {
      // SET TEMP TO STATE
      setDataSocketState(dataState)

      // CLEAR TEMP
      dataState = []
    }

    let timer = setInterval(flush, 3000)

    ws.onmessage = (e) => {
      const message = JSON.parse(e.data)
      // PUSH ITEM STATE TO TEMP
      message?.states && dataState.push(message.states)
    }

    return () => {
      clearInterval(timer)
      if (ws) {
        ws.close()
      }
    }
  }, [])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchDataLiveCycle()
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  useEffect(() => {
    if (dataSocketState?.length) {
      updateDataLiveCycleStates(dataLiveCycle, dataSocketState)
    }
  }, [dataSocketState])

  return (
    <Stack className={pageClasses.pageRoot}>
      <PageHeader
        isAddButtonAvailable={false}
        onAddButtonIsClicked={null}
        isBackButtonAvailable={false}
        targetBackUrl={null}
        title='Live Cycle'
        isSearchAvailable={true}
        search={search}
        setSearch={setSearch}
        isExtraComponentAvailable={false}
        extraComponent={null}
      />

      <LoadingBox isLoading={isLoading}>
        <Typography 
          padding='24px' 
          variant='subtitle1' 
          fontWeight={600}
        >
          {getCurrentShiftHour() === 1 && 'Shift 1, 06:00-17:59'}
          {getCurrentShiftHour() === 2 && 'Shift 2, 18:00-05:59'}
        </Typography>

        <Divider />
        
        <CustomDataGridPro
          checkboxSelection={false}
          columns={columns}
          rows={dataLiveCycle}
          className={classes.dataGridLiveCycle}
          headerHeight={40}
          rowHeight={64}
        />
      </LoadingBox>
    </Stack>
  )
}

export default LiveCycle