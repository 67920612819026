import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import debounce from 'lodash.debounce'

// COMPONENTS
import DataGridFilters from 'components/DataGridFilters/DataGridFilters'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'
import GeofenceList from 'components/GeofenceList/GeofenceList'
import LoadingBox from 'components/LoadingBox/LoadingBox'
import MapChildrenZoom from 'components/PanelZoom/MapChildrenZoom'
import PageHeader from 'components/PageHeader/PageHeader'
import PanelChangeMap from 'components/PanelChangeMap/PanelChangeMap'
import PanelCreateOrEditZone from './PanelCreateEditZone/PanelCreateEditZone'
import PanelZoom from 'components/PanelZoom/PanelZoom'

// CONSTANTS
import mapOptionList from 'constants/mapOptionList'

// CONTEXTS
import { MainLayoutContext } from 'contexts/MainLayoutContext'

// CUSTOM COMPONENTS
import CustomDataGridPro from 'components/Customs/CustomDataGridPro'
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// LEAFLET
import L from 'leaflet'
import { 
  MapContainer, 
  TileLayer,
} from 'react-leaflet'

// MUIS
import Box from '@mui/material/Box'
import Circle from '@mui/icons-material/Circle'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconDelete from '@mui/icons-material/Delete'
import IconEdit from '@mui/icons-material/Edit'

// SERVICES
import { deleteZone, getZoneList } from 'services/zoneServices'

// STYLES
import pageStyles from 'styles/layoutMainPageUseStyles'
import useStyles from './zonesUseStyles'

const Zones = () => {
  const pageClasses = pageStyles()
  const classes = useStyles()

  const pageRef = useRef()

  const initialColumns = [
    {
      field: "name",
      headerName: "Zone Name",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <Stack direction="row" alignItems="center">
          {/* CIRCLE ICON */}
          <Circle
            className={classes.circleStyle}
            sx={{
              color: params.row.color,
            }}
          />

          {/* TEXT */}
          <Typography variant="subtitle2">{params.value}</Typography>
        </Stack>
      ),
    },
    {
      field: "actions",
      headerName: "",
      width: 120,
      hide: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Box className={classes.columnActions}>
          {/* EDIT ICON */}
          <CustomTooltipBlack
            title='Edit'
            placement='top'
          >
            <IconButton onClick={(event) => handleEditIconButtonClick(event, params.row)}>
              <IconEdit />
            </IconButton>
          </CustomTooltipBlack>

          {/* DELETE ICON */}
          <CustomTooltipBlack
            title='Delete'
            placement='top'
          >
            <IconButton onClick={(event) => handleDeleteIconButtonClick(event, params.row)}>
              <IconDelete />
            </IconButton>
          </CustomTooltipBlack>
        </Box>
      ),
    },
  ];
  
  const minimalZoom = 2
  const maximalZoom = 20
  
  const { changeSnackbarObject } = useContext(MainLayoutContext)
  const geoJsonGeofenceListRef = useRef(L.featureGroup())

  const [ selectedMapObject, setSelectedMapObject ] = useState(mapOptionList[0])
  const [ map, setMap ] = useState(null)
  const [ selectionModel, setSelectionModel ] = useState([])
  const [ zoom, setZoom ] = useState(5) 
  const [isPanelCreateOrEditZoneShown, setIsPanelCreateOrEditZoneShown] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  const [selectedColumnList, setSelectedColumnList] = useState(initialColumns)
  const [dialogDeleteObject, setDialogDeleteObject] = useState(null)
  const [selectedTableData, setSelectedTableData] = useState([])

  const loadZoneList = useCallback(async (search = "all") => {
    setIsLoading(true)
    try {
      const {data: resultZoneList} = await getZoneList(search)
      setTableData(resultZoneList)
    } catch (error) {
      
    }
    setIsLoading(false)
  }, [setTableData, setIsLoading])

  const debouncedSearch = useMemo(() => debounce((search) => loadZoneList(search), 500), [loadZoneList])

  const handleChange = (value) => {
    setSearch(value);
    debouncedSearch(value);
  }

  const handleEditIconButtonClick = (inputEvent, inputParams) => {
    inputEvent.stopPropagation()
    setIsPanelCreateOrEditZoneShown({
      mode: 'edit',
      data: inputParams
    })
  }

  const handleDeleteIconButtonClick = (inputEvent, inputParams) => {
    inputEvent.stopPropagation()

    setDialogDeleteObject(inputParams)
  }

  const handleDelete = async () => {
    const res = await deleteZone(dialogDeleteObject.id)

    if(res.status === 200){
      changeSnackbarObject({
        open: true,
        severity: 'success',
        message: 'Successfully deleted a zone',
      })
      setSelectionModel(prev => {
        return prev.filter(id => id !== dialogDeleteObject.id)
      })
      loadZoneList()
    }

    setDialogDeleteObject(null)
  }

  useEffect(() => {
    loadZoneList()
  }, [loadZoneList])

  useEffect(() => {
    setSelectedTableData(selectionModel?.length ? selectionModel.map(id => tableData?.find(item => item.id === id)) : tableData)
  }, [selectionModel, tableData])

  return (
    <Box className={pageClasses.pageRoot} ref={pageRef}>
      {/* PAGE HEADER */}
      <PageHeader
        isAddButtonAvailable={true}
        onAddButtonIsClicked={() => setIsPanelCreateOrEditZoneShown({ mode: 'add' })}
        isBackButtonAvailable={false}
        targetBackUrl={null}
        isSearchAvailable={true}
        search={search}
        setSearch={handleChange}
        title="Zones"
        isExtraComponentAvailable={false}
        extraComponent={null}
        isAddButtonDisabled={Boolean(isPanelCreateOrEditZoneShown)}
      />
      <>
        <Stack direction='row' spacing={2} height='100%' position='relative'>
          <LoadingBox isLoading={isLoading} className={classes.tableContainer}>
            {/* DATA GRID FILTERS */}
            <DataGridFilters
              isWithTabs={false}
              title={"Zone List"}
              selectedTab={null}
              setSelectedTab={null}
              tabItemList={null}
              isWithCustomizeColumn={false}
              selectedColumnList={selectedColumnList}
              setSelectedColumnList={setSelectedColumnList}
              isWithDateRange={false}
              dateRangeValue={null}
              setDateRangeValue={null}
              handleDownloadIconButtonClick={() => {}}
            />

            {/* TABLE */}
            <CustomDataGridPro 
              columns={selectedColumnList} 
              rows={tableData}
              selectionModel={selectionModel}
              setSelectionModel={setSelectionModel}
            />
          </LoadingBox>
          <LoadingBox isLoading={isLoading}>
            <Box
              className={classes.pageRoot}
            >
              {/* MAP */}
              <MapContainer
                center={[-0.7893, 113.9213]}
                className={`${classes.mapContainer} no-zoom`}
                zoomControl={false}
                zoom={zoom}
                whenCreated={(mapObject) => setMap(mapObject)}
                editable={true}
              >
                {/* BASE MAP */}
                <TileLayer
                  // attribution='&copy <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors'
                  url={selectedMapObject.url}
                  subdomains={['mt1', 'mt2', 'mt3']}
                  minZoom={minimalZoom}
                  maxZoom={maximalZoom}
                  key={selectedMapObject.name}
                />

                {/* MAP CHILDREN ZOOM */}
                <MapChildrenZoom 
                  zoom={zoom}
                  setZoom={setZoom}
                />

                {/* GEOFENCE LIST */}
                <GeofenceList 
                  map={map}
                  selectedGeofenceList={selectedTableData}
                  hasGeofenceListFinishedLoading={isLoading}
                  geoJsonGeofenceListRef={geoJsonGeofenceListRef}
                />
              </MapContainer>

              {/* CHANGE MAP PANEL */}
              <PanelChangeMap
                mapOptionList={mapOptionList}
                selectedMapObject={selectedMapObject}
                setSelectedMapObject={setSelectedMapObject}
              />

              {/* ZOOM PANEL */}
              <PanelZoom
                zoom={zoom}
                setZoom={setZoom}
              />
            </Box>
          </LoadingBox>
          <PanelCreateOrEditZone
            isPanelCreateOrEditZoneShown={isPanelCreateOrEditZoneShown}
            setIsPanelCreateOrEditZoneShown={setIsPanelCreateOrEditZoneShown}
            pageRef={pageRef}
            refetchData={() => {
              loadZoneList(search)
            }}
            map={map}
            geoJsonGeofenceListRef={geoJsonGeofenceListRef}
          />
        </Stack>

        {/* DIALOG DELETE GROUP NAME */}
        <DialogConfirmation
          title={`Delete Zone`}
          caption={`Are you sure you want to delete the zone?`}
          dialogConfirmationObject={dialogDeleteObject}
          setDialogConfirmationObject={setDialogDeleteObject}
          cancelButtonText='Cancel'
          continueButtonText='Delete'
          onContinueButtonClick={handleDelete}
          onCancelButtonClick={() => setDialogDeleteObject(null)}
        />
      </>
    </Box>
  );
}

export default Zones