// APIS
import axios from 'apis/axios'

// SERVICES
import { postRefreshToken } from './tokenServices'

export const getZoneList = async (search = "all") => {
  const resultRefreshToken = await postRefreshToken()

  if(resultRefreshToken.status === 200) {
    try {
      const response = await axios.get(
        'zone/list',
        { 
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${resultRefreshToken.data.accessToken}`,
          },
          params: {
            name: search,
          }
        },
      )
  
      return response
    }
    catch (error) {
      if (!error.response) return { status: 'No Server Response' }
      else return error.response
    }
  }
}

export const postCreateZone = async (name, color, coordinates) => {
  const resultRefreshToken = await postRefreshToken()

  if(resultRefreshToken.status === 200) {
    try {
      const response = await axios.post(
        "zone/create",
        JSON.stringify({
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: {
                name,
                color,
              },
              geometry: {
                coordinates,
                type: "Polygon",
              },
            },
          ],
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${resultRefreshToken.data.accessToken}`,
          },
        }
      );
  
      return response
    }
    catch (error) {
      if (!error.response) return { status: 'No Server Response' }
      else return error.response
    }
  }
}

export const putUpdateZone = async (id, name, color, coordinates) => {
  const resultRefreshToken = await postRefreshToken()

  if(resultRefreshToken.status === 200) {
    try {
      const response = await axios.put(
        `zone/${id}/update`,
        JSON.stringify({
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: {
                name,
                color,
              },
              geometry: {
                coordinates,
                type: "Polygon",
              },
            },
          ],
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${resultRefreshToken.data.accessToken}`,
          },
        }
      );
  
      return response
    }
    catch (error) {
      if (!error.response) return { status: 'No Server Response' }
      else return error.response
    }
  }
}

export const deleteZone = async (id) => {
  const resultRefreshToken = await postRefreshToken()

  if(resultRefreshToken.status === 200) {
    try {
      const response = await axios.delete(
        `zone/${id}/delete`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${resultRefreshToken.data.accessToken}`,
          },
        }
      );
  
      return response
    }
    catch (error) {
      if (!error.response) return { status: 'No Server Response' }
      else return error.response
    }
  }
}