const dashboardType = process.env.REACT_APP_DASHBOARD_TYPE
const dashboardTypePpa = 'PPA'
const dashboardTypeIot = 'IOT'
const dashboardTypeGmm = 'GMM'

const getSiteOptionList = () => {
  if (dashboardType === dashboardTypePpa) return [ 'MHU', 'BIB', 'KJB', 'RUB', 'SKS', 'AHM' ]
  else if (
    dashboardType === dashboardTypeIot 
    || dashboardType === dashboardTypeGmm
  ) return [ 'Site-A', 'Site-B', 'Site-C', 'Site-D', 'Site-E', 'Site-F', ]
}

export const values = {
  drawerWidth: 256,
  drawerWidthOnMinimized: 72,
  siteOptionList: getSiteOptionList(),
  initialSnackbarObject: {
    open: false,
    severity: 'success',
    message: ''
  },
  baseApiUrlPpa: process.env.REACT_APP_PPA_API_BASE_URL,
  apiRequestInterval: process.env.REACT_APP_API_REQUEST_INTERVAL, // IN MILISECONDS
  dashboardType: dashboardType,
  dashboardTypePpa: dashboardTypePpa,
  dashboardTypeIot: dashboardTypeIot,
  dashboardTypeGmm: dashboardTypeGmm,
  
  // COLORS
  colorsCst: ['#E2523E', '#D87972', '#D43A64', '#E26C92', '#9036AD', '#AF6DC3', '#6041AD', '#795BBA', 
    '#4052B4', '#7A87C7', '#4397F2', '#77B3EC', '#46A8F4', '#6BC3F3', '#51B9CE', '#70D1DC',
    '#419489', '#68B5A8', '#67AC5B', '#8FC586', '#99C057', '#B6D28D', '#D2DB59', '#E0E48B',
    '#FCE95E', '#FDF184', '#F6C043', '#F9D669', '#F29938', '#F5B660', '#EC6036', '#F08C6D',
    '#735748', '#9E887E', '#9E9E9E', '#E0E0E0', '#667C8A', '#93A4AD', '#010002', '#424242'
  ],
}