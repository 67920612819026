import React, { useEffect, useState } from 'react'

// CUSTOM COMPONENTS
import CustomDialogActions from 'components/Customs/CustomDialogActions'
import CustomDialogActionButton from 'components/Customs/CustomDialogActionButton'
import CustomDialogContent from 'components/Customs/CustomDialogContent'
import CustomDialogTitle from 'components/Customs/CustomDialogTitle'

// MUIS
import Dialog from '@mui/material/Dialog'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// STYLES
import useStyles from './dialogUploadImageUseStyles'

// UTILS
import { iconVehicles } from '../VehiclesUtils'

const DialogUploadImage = (props) => {
  const {
    isDialogUploadImageShown, 
    setIsDialogUploadImageShown,
    setSelectedImage,
    selectedImage
  } = props

  const classes = useStyles()

  const [tempSelectedImage, setTempSelectedImage] = useState(selectedImage)

  const handleSaveCancel = () => {
    setTempSelectedImage(selectedImage)
    setIsDialogUploadImageShown(false)
  }

  const handleSaveClick = () => {
    setSelectedImage(tempSelectedImage)
    setIsDialogUploadImageShown(false)
  }
  
  useEffect(() => {
    if (selectedImage) setTempSelectedImage(selectedImage)
    else if (!selectedImage) setTempSelectedImage(null)
  }, [selectedImage])

  return (
    <Dialog 
      open={Boolean(isDialogUploadImageShown)}
      onClose={() => setIsDialogUploadImageShown(false)} 
      className={classes.dialogRoot}
    >
      {/* DIALOG TITLE */}
      <CustomDialogTitle className={classes.dialogTitle}>
        Image
      </CustomDialogTitle>

      {/* DIALOG SUBTITLE */}
      <Typography className={classes.dialogSubtitle} variant='subtitle2'>
        Select an image
      </Typography>

      {/* DIALOG CONTENT */}
      <CustomDialogContent className={classes.dialogContent}>
        <Stack direction='row' flexWrap='wrap'>
          {iconVehicles.map(item => (
            <Stack
              onClick={() => setTempSelectedImage(item.id)}
              key={item.id}
              alignItems='center'
              justifyContent='center'
              className={`${classes.boxIcon}${tempSelectedImage === item.id ? ' selected' : ''}`}
            >
              <item.Icon className={classes.iconVehicle} />
            </Stack>
          ))}
        </Stack>
      </CustomDialogContent>

      <CustomDialogActions className={classes.dialogActions}>
        {/* CANCEL BUTTON */}
        <CustomDialogActionButton
          className={classes.cancelButton}
          onClick={handleSaveCancel}
        >
          Cancel
        </CustomDialogActionButton>

        {/* OK BUTTON */}
        <CustomDialogActionButton
          className={classes.saveButton}
          onClick={handleSaveClick}
        >
          Save
        </CustomDialogActionButton>
      </CustomDialogActions>
    </Dialog>
  )
}

export default DialogUploadImage