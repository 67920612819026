import IconVehicleOne from 'assets/images/icons/IconVehicleOne'
import IconVehicleTwo from 'assets/images/icons/IconVehicleTwo'
import IconVehicleThree from 'assets/images/icons/IconVehicleThree'
import IconVehicleFour from 'assets/images/icons/IconVehicleFour'
import IconVehicleFive from 'assets/images/icons/IconVehicleFive'
import IconVehicleSix from 'assets/images/icons/IconVehicleSix'
import IconVehicleSeven from 'assets/images/icons/IconVehicleSeven'
import IconVehicleEight from 'assets/images/icons/IconVehicleEight'
import IconVehicleNine from 'assets/images/icons/IconVehicleNine'
import IconVehicleTen from 'assets/images/icons/IconVehicleTen'
import IconVehicleEleven from 'assets/images/icons/IconVehicleEleven'
import IconVehicleTwelve from 'assets/images/icons/IconVehicleTwelve'

export const iconVehicles = [
    {
        id: 1,
        Icon: IconVehicleOne
    },
    {
        id: 2,
        Icon: IconVehicleTwo
    },
    {
        id: 3,
        Icon: IconVehicleThree
    },
    {
        id: 4,
        Icon: IconVehicleFour
    },
    {
        id: 5,
        Icon: IconVehicleFive
    },
    {
        id: 6,
        Icon: IconVehicleSix
    },
    {
        id: 7,
        Icon: IconVehicleSeven
    },
    {
        id: 8,
        Icon: IconVehicleEight
    },
    {
        id: 9,
        Icon: IconVehicleNine
    },
    {
        id: 10,
        Icon: IconVehicleTen
    },
    {
        id: 11,
        Icon: IconVehicleEleven
    },
    {
        id: 12,
        Icon: IconVehicleTwelve
    }
]

export const getIconVehicleById = (id) => {
  const findIconObject = iconVehicles.find(item => item.id === id)
  return findIconObject ?? {}
}