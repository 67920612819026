import { useState, useEffect, useContext } from 'react'

// CONSTANTS
import { 
  columnsCanbus, 
  columnsSpeed,
  columnsTrip,
} from '../reportsConstants'

// CONTEXTS
import { MainLayoutContext } from 'contexts/MainLayoutContext'
import { ReportsPageContext } from 'contexts/ReportsPageContext'

// COMPONENTS
import CanbusPanel from '../CanbusPanel/CanbusPanel'
import VehiclesPanel from '../VehiclesPanel/VehiclesPanel'
import ReportsContent from '../ReportsContent/ReportsContent'

// MUIS
import Backdrop from '@mui/material/Backdrop'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconStickyNote2Outlined from '@mui/icons-material/StickyNote2Outlined'

// SERVICES
import { getDetailReport } from 'services/reportServices'

// STYLES
import useStyles from './rightContentUseStyles'

const RightContent = (props) => {
  const { isCreatingReport, setIsCreatingReport } = props
  
  const classes = useStyles()

  const { changeSnackbarObject } = useContext(MainLayoutContext)
  const { 
    setIsLoading,
    reportTypeList,
    selectedReport, 
  } = useContext(ReportsPageContext)

  const [ detailReportObject, setDetailReportObject ] = useState(null)
  const [ tabList, setTabList ] = useState([])
  const [ currentTab, setCurrentTab ] = useState(null)
  const [ selectedTableColumns, setSelectedTableColumns ] = useState([])
  const [ selectedTableRows, setSelectedTableRows ] = useState([])

  const loadDetailReportData = async () => {
    setIsLoading(true)

    const resultDetailReport = await getDetailReport(selectedReport?.id)
    
    if (resultDetailReport.status === 200) {
      const reportDetailObject = resultDetailReport.data.canbusResponseList 
      || resultDetailReport.data.speedResponseList 
      || resultDetailReport.data.tripSummariseList

      setDetailReportObject(reportDetailObject)

      if (Boolean(reportDetailObject)) {
        setTabList(Object.keys(reportDetailObject).map(key => {
          return {
            name: key,
            id: key,
          }
        }))
      }
      else changeSnackbarObject({
        open: true,
        severity: 'info',
        message: 'Currently, no report data',
      })
    }
    else {
      setDetailReportObject(null)
      setTabList([])

      changeSnackbarObject({
        open: true,
        severity: 'error',
        message: 'Failed to get the selected report data',
      })
    }

    setIsLoading(false)
  }

  const updateTableData = () => {
    setSelectedTableColumns([])
    setSelectedTableRows([])

    if (selectedReport.type.toLowerCase() === reportTypeList[0].value) setSelectedTableColumns(columnsCanbus)
    else if (selectedReport.type.toLowerCase() === reportTypeList[1].value) setSelectedTableColumns(columnsSpeed)
    else if (selectedReport.type.toLowerCase() === reportTypeList[2].value) setSelectedTableColumns(columnsTrip)

    setSelectedTableRows(detailReportObject[currentTab]?.map((item, index) => {
      return {
        ...item,
        id: index,
      }
    }))

    setIsLoading(false)
  }

  useEffect(() => {
    if (Boolean(selectedReport)) loadDetailReportData()
    else setTabList([])
  }, [selectedReport])

  useEffect(() => {
    if (Boolean(detailReportObject) && Boolean(currentTab)) updateTableData()
  }, [detailReportObject, currentTab])

  return (
    <Stack
      flex='1'
      height='100%'
      position='relative'
      maxWidth='calc(100% - 300px)'
    >
      {/* SELECTED REPORT */}
      {tabList?.length ?
      // HEADER 
      <ReportsContent 
        tabList={tabList} 
        setTabList={setTabList}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        columns={selectedTableColumns}
        rows={selectedTableRows}
      /> : 
      // NO SELECTED REPORT
      <Stack 
        justifyContent='center' 
        alignItems='center' 
        height='100%'
      >
        <IconStickyNote2Outlined className={classes.iconNoReport} />
        <Typography 
          fontWeight={600} 
          variant='h5' 
          marginBottom='8px'
        >
          No Report Selected
        </Typography>
        <Typography 
          color='text.secondary' 
          textAlign='center' 
          variant='subtitle1' 
          width='272px'
        >
          Please select a generated report to see the details.
        </Typography>
      </Stack>}

      {/* CREATE REPORT BACKDROP */}
      <Backdrop
        open={isCreatingReport}
        className={classes.createReportBackdrop}
      >
        <VehiclesPanel/>
        <CanbusPanel setIsCreatingReport={setIsCreatingReport} />
      </Backdrop>
    </Stack>
  )
}

export default RightContent