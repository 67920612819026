// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconPort = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M12.0001 20.0898L3.9104 12.0001L5.50768 10.3778L10.8626 15.7327V3.9104H13.1376V15.7327L18.4925 10.3778L20.0898 12.0001L12.0001 20.0898Z"/>
    </SvgIcon>
  )
}

export default IconPort