import { memo, useState, useEffect, useRef } from 'react'

// MUIS
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  )
}

const DataGridCellExpand = memo(function GridCellExpand(props) {
  const { width, value } = props
  const wrapper = useRef(null)
  const cellDiv = useRef(null)
  const cellValue = useRef(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [showFullCell, setShowFullCell] = useState(false)
  const [showPopper, setShowPopper] = useState(false)

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current)
    setShowPopper(isCurrentlyOverflown)
    setAnchorEl(cellDiv.current)
    setShowFullCell(true)
  }

  const handleMouseLeave = () => {
    setShowFullCell(false)
  }

  useEffect(() => {
    if (!showFullCell) {
      return undefined
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false)
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [setShowFullCell, showFullCell])

  return (
    <Stack
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      direction='row'
      alignItems='center'
      lineHeight='24px'
      width='100%'
      height='100%'
      position='relative'
    >
      <Stack
        ref={cellDiv}
        height='100%'
        width={width}
        position='absolute'
        top={0}
      />
      <Typography
        ref={cellValue}
        variant='inherit'
        noWrap
      >
        {value}
      </Typography>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          sx={{ width: width }}
        >
          <Paper
            elevation={1}
            sx={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography 
              variant='body2' 
              padding='8px'
            >
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Stack>
  )
})

export default DataGridCellExpand