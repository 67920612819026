// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconVehicleTwo = (props) => {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.5983 14.3493L2.16079 9.62702C2.01075 9.54304 1.95602 9.35289 2.03821 9.20111L3.91321 5.73814C3.96797 5.637 4.07317 5.57408 4.18754 5.57408H11.8999L14.0059 4.05863C14.0589 4.0205 14.1224 4 14.1875 4H19.5C19.6726 4 19.8125 4.14096 19.8125 4.31482V5.25926C19.8125 5.40191 19.7174 5.5267 19.5806 5.56345L16.3212 6.43904C15.6169 6.62824 15.1251 7.27322 15.1251 8.00749V9.35183C15.1251 9.42758 15.098 9.50078 15.0487 9.558L10.9862 14.2802C10.9251 14.3512 10.8382 14.3889 10.7499 14.3889C10.6982 14.3889 10.646 14.376 10.5983 14.3493ZM12.625 6.8333H5.43754C5.26496 6.8333 5.12504 6.97426 5.12504 7.14811C5.12504 7.32197 5.26496 7.46293 5.43754 7.46293H6.68754V9.35182C6.68754 9.52568 6.82746 9.66664 7.00004 9.66664C7.17262 9.66664 7.31254 9.52568 7.31254 9.35182V7.46293H9.50003V11.2407C9.50003 11.4146 9.63995 11.5555 9.81253 11.5555C9.98511 11.5555 10.125 11.4146 10.125 11.2407V7.46293H12.3125V9.66664C12.3125 9.8405 12.4524 9.98146 12.625 9.98146C12.7976 9.98146 12.9375 9.8405 12.9375 9.66664V7.14811C12.9375 6.97422 12.7976 6.8333 12.625 6.8333Z"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.06254 21C8.30619 21 10.125 19.1677 10.125 16.9074C10.125 14.6471 8.30619 12.8148 6.06254 12.8148C3.81889 12.8148 2.00005 14.6471 2.00005 16.9074C2.00005 19.1677 3.81889 21 6.06254 21ZM6.06254 18.7963C7.09807 18.7963 7.93753 17.9506 7.93753 16.9074C7.93753 15.8642 7.09807 15.0185 6.06254 15.0185C5.027 15.0185 4.18754 15.8642 4.18754 16.9074C4.18754 17.9506 5.027 18.7963 6.06254 18.7963Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M22 16.9074C22 19.1677 20.1812 21 17.9375 21C15.6938 21 13.875 19.1677 13.875 16.9074C13.875 14.6471 15.6938 12.8148 17.9375 12.8148C20.1812 12.8148 22 14.6471 22 16.9074ZM17.9375 18.7963C18.973 18.7963 19.8125 17.9506 19.8125 16.9074C19.8125 15.8642 18.973 15.0185 17.9375 15.0185C16.902 15.0185 16.0625 15.8642 16.0625 16.9074C16.0625 17.9506 16.902 18.7963 17.9375 18.7963Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M19.5 6.51848H17.7112C16.9732 6.51848 16.375 7.12116 16.375 7.86463V10.1908C16.375 10.4229 16.5618 10.6111 16.7921 10.6111H20.3328C20.5632 10.6111 20.7499 10.4229 20.7499 10.1908V8.72219C20.7499 8.67332 20.7387 8.62511 20.7169 8.58139L19.7794 6.6925C19.7266 6.58585 19.6184 6.51848 19.5 6.51848ZM19.7175 7.97591L20.0904 8.72682C20.1131 8.77267 20.125 8.82324 20.125 8.87451V9.30801C20.125 9.50607 19.9656 9.66663 19.769 9.66663H19.2136C18.6605 9.66663 18.2132 9.21038 18.2524 8.65465C18.2869 8.16539 18.6932 7.77773 19.1875 7.77773H19.3992C19.534 7.77773 19.6572 7.85447 19.7175 7.97591Z" />
      <path d="M22 10.6563V14.6352C22 14.7242 21.9352 14.782 21.8636 14.7923C21.8133 14.7995 21.7648 14.7704 21.7397 14.726C20.9866 13.3963 19.5663 12.5 17.9375 12.5C15.5211 12.5 13.5625 14.4731 13.5625 16.9074C13.5625 16.9114 13.5625 16.9153 13.5626 16.9192L13.5627 16.9229L13.5629 16.9271C13.5625 16.9357 13.5625 16.944 13.5625 16.9527V16.9593C13.5625 17.1288 13.4249 17.2675 13.2567 17.2675H10.791C10.5966 17.2675 10.4375 17.1072 10.4375 16.9113V16.9074C10.4375 16.3624 10.3395 15.8402 10.1598 15.3585C10.059 15.0886 10.3477 14.8387 10.5981 14.9788C10.6461 15.0055 10.6981 15.0185 10.75 15.0185C10.8383 15.0185 10.925 14.9807 10.9863 14.9099L12.2082 13.4897H13.8684C14.0446 13.4897 14.1875 13.3457 14.1875 13.1682V12.2304C14.1875 12.147 14.2207 12.0667 14.2793 12.0073L15.8414 10.4336C15.8422 10.4328 15.8426 10.4324 15.8434 10.432C15.9242 10.3514 16.0625 10.4143 16.0625 10.5292V10.8381C16.0625 10.9116 16.1216 10.9712 16.1945 10.9712H20.9305C21.0034 10.9712 21.0625 10.9116 21.0625 10.8381V10.4745C21.0625 10.4011 21.1216 10.3415 21.1945 10.3415H21.6875C21.8601 10.3415 22 10.4824 22 10.6563Z"/>
    </SvgIcon>
  )
}

export default IconVehicleTwo