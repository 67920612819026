// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  cycleTitle: {
    padding: 0,
    '& .MuiListItemText-root': {
      margin: 0,
    },
    '& .MuiListItemText-primary': {
      fontSize: 20,
      fontWeight: 600,
    },
    '& .MuiListItemText-secondary': {
      fontSize: 12,
    },
  },
  cycleDivider: {
    marginTop: 16,
  },
  deviceButton: {
    textTransform: 'none',
  },
}))

export default useStyles