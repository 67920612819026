import { useState, useEffect, useContext, useMemo } from 'react'

// COMPONENTS
import DateRangeAndTimePicker from 'components/DateRangeAndTimePicker/DateRangeAndTimePicker'

// CONSTANTS
import { headerHeight } from '../reportsConstants'

// CONTEXTS
import { MainLayoutContext } from 'contexts/MainLayoutContext'
import { ReportsPageContext } from 'contexts/ReportsPageContext'

// DATE AND TIME
import moment from 'moment'
import { format } from 'date-fns'

// MUIS
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import Slider from '@mui/material/Slider'
import Stack from '@mui/material/Stack'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Typography from '@mui/material/Typography'

// SERVICES
import { 
  postCreateReport, 
  putUpdateReport,
} from 'services/reportServices'

// STYLES
import useStyles from './canbusPanelUseStyles'

const CanbusPanel = (props) => {
  const { setIsCreatingReport } = props

  const classes = useStyles()

  const { changeSnackbarObject } = useContext(MainLayoutContext)
  const { 
    setIsLoading,
    reportTypeList,
    selectedReportType, 
    setShouldReloadReportListData,
    objectsSelectionModel,
    editReportObject,
  } = useContext(ReportsPageContext)

  const initialDateRange = [
    moment().startOf('day').toISOString(),
    moment().endOf('day').toISOString(),
  ]
  const dayOptions = [ 'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday' ]
  const maxMinutes = 1439
  const initialCheckboxs = {
    fuel: true,
    hm: true,
    odo: true,
    avg_speed: true,
    max_speed: true,
  }

  const [ reportTitle, setReportTitle ] = useState('Report')
  const [ isDateRangePickerOpen, setIsDateRangePickerOpen ] = useState(false)
  const [ reportDateRange, setReportDateRange ] = useState(initialDateRange)
  const [ selectedDays, setSelectedDays ] = useState(dayOptions)
  const [ selectedTimeRange, setSelectedTimeRange ] = useState([ 0, 100 ])
  const [ calculatedTimeRange, setCalculatedTimeRange ] = useState([ 0, maxMinutes ])
  const [ checkboxs, setCheckboxs ] = useState(initialCheckboxs)

  const formatDateRangeAndTimeText = (inputDateRangeAndTime) => {
    const formatedDateRange = `${format(new Date(inputDateRangeAndTime[0]), 'yyyy-MM-dd'
    )} - ${format(new Date(inputDateRangeAndTime[1]), 'yyyy-MM-dd')}`
    return formatedDateRange
  }

  const handleChangeTimeSlider = (event, newValue) => {
    setCalculatedTimeRange([ Math.floor(newValue[0] / 100 * maxMinutes), Math.floor(newValue[1] / 100 * maxMinutes) ])
    setSelectedTimeRange(newValue)
  }

  const getTimeFromMins = (mins) => {
    if (mins >= 24 * 60 || mins < 0) {
      throw new RangeError('Valid input should be greater than or equal to 0 and less than 1440.')
    }
    const h = mins / 60 | 0
    const m = mins % 60 | 0
    return moment.utc().hours(h).minutes(m).format('HH:mm A')
  }

  const startTimeText = useMemo(() => getTimeFromMins(calculatedTimeRange[0]), [calculatedTimeRange])
  const endTimeText = useMemo(() => getTimeFromMins(calculatedTimeRange[1]), [calculatedTimeRange])

  const handleTimeRangeButtonClick = (type) => {
    if (type === 'always') {
      setCalculatedTimeRange([ Math.floor(0 / 100 * maxMinutes), Math.floor(100 / 100 * maxMinutes) ])
      setSelectedTimeRange([ 0, 100 ])
    }
    if (type === 'day') {
      setCalculatedTimeRange([ 540, 1080 ])
      setSelectedTimeRange([ Math.floor(540 / maxMinutes * 100), Math.floor(1080 / maxMinutes * 100) ])
    }
    if (type === 'night') {
      setCalculatedTimeRange([ 0, 360 ])
      setSelectedTimeRange([ 0, Math.floor(360 / maxMinutes * 100) ])
    }
  }

  const getCheckboxLabelByKey = (key) => {
    if (key === 'fuel') return 'Fuel Consumed (L)'
    else if (key === 'hm') return 'HM (Hour)'
    else if (key === 'odo') return 'ODO (km)'
    else if (key === 'avg_speed') return 'Speed Avg'
    else if (key === 'max_speed') return 'Speed Max'
  }

  const handleCheckboxsChange = (event) => {
    setCheckboxs(current => {
      return {
        ...current,
        [event.target.name]: event.target.checked,
      }
    });
  }

  const handleCloseCreateReport = () => {
    setIsCreatingReport(false)
    setReportTitle('Report')
    setReportDateRange(initialDateRange)
    setSelectedDays(dayOptions)
    setSelectedTimeRange([ 0, 100 ])
    setCalculatedTimeRange([ 0, maxMinutes ])
    setCheckboxs(initialCheckboxs)
  }

  const handleBuildReportButtonClick = async () => {
    // ERROR CONDITIONS
    if (objectsSelectionModel.length === 0) changeSnackbarObject({
      open: true,
      severity: 'error',
      message: 'Vehicles must be selected',
    })
    else if (reportTitle.length === 0) changeSnackbarObject({
      open: true,
      severity: 'error',
      message: 'Report title can not be empty',
    })
    else if (selectedDays.length === 0) changeSnackbarObject({
      open: true,
      severity: 'error',
      message: 'Control days can not be empty',
    })
    // SUCCESS CONDITIONS
    else {
      setIsLoading(true)

      let bodyRequest = {
        deviceObjectId: objectsSelectionModel,
        type: selectedReportType?.toUpperCase(),
        name: reportTitle,
        startDate: format(new Date(reportDateRange[0]), 'yyyy-MM-dd'),
        endDate: format(new Date(reportDateRange[1]), 'yyyy-MM-dd'),
        controlDays: selectedDays.map(item => item.toUpperCase()),
        controlStartTime: `${startTimeText.slice(0, -3)}:00`,
        controlEndTime: calculatedTimeRange[1] === maxMinutes ? '23:59:59' : `${endTimeText.slice(0, -3)}:00`,
      }

      if (selectedReportType === reportTypeList[0].value) {
        bodyRequest.canbusOptions = Object.keys(checkboxs).filter((key, index) => checkboxs[key]).map(item => item.toUpperCase())
      }

      let resultBuildReport = null

      // EDIT REPORT
      if (editReportObject) resultBuildReport = await putUpdateReport(editReportObject?.id, bodyRequest)
      // CREATE REPORT
      else resultBuildReport = await postCreateReport(bodyRequest)
      
      if (resultBuildReport.status === 200) {
        changeSnackbarObject({
          open: true,
          severity: 'success',
          message: `Success ${(editReportObject ? 'editing' : 'creating')} report`,
        })
        handleCloseCreateReport()
      }
      else changeSnackbarObject({
        open: true,
        severity: 'error',
        message: `Failed ${(editReportObject ? 'editing' : 'creating')} report`,
      })
  
      setIsLoading(false)
      setShouldReloadReportListData(true)
    }    
  }

  // EDIT FORM
  useEffect(() => {
    if (editReportObject) {
      // REPORT TITLE
      setReportTitle(editReportObject?.name)
      
      // DATE RANGE
      setReportDateRange([
        new Date(editReportObject?.startDate)?.toISOString(),
        new Date(editReportObject?.endDate)?.toISOString(),
      ])

      // DAYS CONTROL
      setSelectedDays(editReportObject?.controlDays?.map(item => item?.toLowerCase()))

      // TIME CONTROL
      const splittedStartTime = editReportObject?.controlStartTime?.split(':')
      const startMinutes = Number(splittedStartTime[0] * 60) + Number(splittedStartTime[1])
      
      const splittedEndTime = editReportObject?.controlEndTime?.split(':')
      const endMinutes = Number(splittedEndTime[0] * 60) + Number(splittedEndTime[1])

      setSelectedTimeRange([ startMinutes * 100 / maxMinutes, endMinutes * 100 / maxMinutes ])
      setCalculatedTimeRange([ startMinutes, endMinutes ])

      // CANBUS OPTIONS
      if (editReportObject?.type?.toLowerCase() === reportTypeList[0].value) {
        let editCheckboxs = {}

        Object.keys(initialCheckboxs).map((key, index) => key).forEach(item => {
          if (editReportObject?.canbusOptions?.includes(item?.toUpperCase())) editCheckboxs[item] = true
          else editCheckboxs[item] = false
        })
        
        setCheckboxs(editCheckboxs)
      }
    }
  }, [editReportObject])

  return (
    <Stack
      width={300}
      height='100%'
      backgroundColor={(theme) => theme.palette.common.white}
      borderRight={(theme) => `1px solid ${theme.palette.divider}`}
    >
      {/* HEADER */}
      <Stack
        height={headerHeight}
        direction='row'
        alignItems='center'
        padding='0px 8px 0px 16px'
        gap='4px'
      >
        {/* TITLE */}
        <Typography 
          fontWeight={600} 
          noWrap
        >
          {reportTypeList.find(item => item.value === selectedReportType)?.label}
        </Typography>
      </Stack>

      <Divider/>

      {/* FORM */}
      <Stack
        padding='16px'
        spacing='12px'
        flex={1}
      >
        {/* REPORT TITLE INPUT */}
        <FormControl variant='standard'>
          <InputLabel>
            Report Title
          </InputLabel>

          <Input
            value={reportTitle}
            onChange={(event) => setReportTitle(event.target.value)}
          />
        </FormControl>

        {/* REPORT DATE RANGE INPUT */}
        <FormControl variant='standard'>
          <InputLabel>
            Report Date Range
          </InputLabel>

          <Input
            onClick={(event) => setIsDateRangePickerOpen(true)}
            value={formatDateRangeAndTimeText(reportDateRange)}
            onChange={(event) => setReportDateRange(event.target.value)}
          />
        </FormControl>

        {/* DAYS BUTTON GROUP */}
        <Stack marginTop='8px'>
          <Typography
            variant='caption'
            color='text.secondary'
            marginBottom='4px'
          >
            Control Days
          </Typography>

          <ToggleButtonGroup
            value={selectedDays}
            onChange={(event, newFormats) => setSelectedDays(newFormats)}
            className={classes.daysToggleButtonGroup}
          >
            {dayOptions.map((item, index) => (
              <ToggleButton 
                key={index}
                value={item}
                className={classes.daysToggleButton}
              >
                {item.slice(0, 2)}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Stack>

        {/* TIME SLIDER */}
        <Stack marginTop='20px !important'>
          <Typography
            variant='caption'
            color='text.secondary'
          >
            Control Time (from {startTimeText} to {endTimeText})
          </Typography>

          {/* SLIDER */}
          <Slider
            value={selectedTimeRange}
            onChange={handleChangeTimeSlider}
            valueLabelDisplay='off'
            className={classes.timeRangeSlider}
          />

          {/* ACTIONS */}
          <Stack
            direction='row'
            justifyContent='center'
          >
            {['day', 'night', 'always'].map((item, index) => (
              <Button
                key={index}
                className='textTransformCapitalize'
                size='small'
                onClick={() => handleTimeRangeButtonClick(item)}
              >
                {item}
              </Button>
            ))}
          </Stack>
        </Stack>

        {/* CHECKBOXS */}
        {selectedReportType === reportTypeList[0].value &&
        <Stack>
          <Typography
            variant='caption'
            color='text.secondary'
          >
            Canbus
          </Typography>

          <FormControl 
            component='fieldset' 
            variant='standard'
          >
            <FormGroup>
              {Object.keys(initialCheckboxs).map((key, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox 
                      checked={checkboxs[key]} 
                      onChange={handleCheckboxsChange} 
                      name={key}
                      size='small'
                    />
                  }
                  label={getCheckboxLabelByKey(key)}
                  className={classes.canbusCheckboxs}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Stack>}
      </Stack>

      {/* ACTIONS BUTTONS */}
      <Stack
        direction='row'
        alignSelf='flex-end'
        padding='16px'
        gap='8px'
      >
        {/* CANCEL BUTTON */}
        <Button
          variant='text'
          className='textTransformNone colorActionActive'
          onClick={handleCloseCreateReport}
        >
          Cancel
        </Button>

        {/* BUILD BUTTON */}
        <Button
          variant='text'
          color='primary'
          className='textTransformNone'
          onClick={handleBuildReportButtonClick}
        >
          Build
        </Button>
      </Stack>

      {/* DATE RANGE AND TIME PICKERS DIALOG */}
      <Dialog 
        open={isDateRangePickerOpen}
        onClose={() => setIsDateRangePickerOpen(false)} 
        className={classes.dateRangePickerDialog}
      >
        <DateRangeAndTimePicker
          value={reportDateRange}
          setValue={setReportDateRange}
          setOpen={setIsDateRangePickerOpen}
          isWithTimePicker={false}
        />
      </Dialog>
    </Stack>
  )
}

export default CanbusPanel