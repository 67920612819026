// MUI STYLES
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  iconClose: {
    position: 'absolute',
    top: 16,
    left: 16,
    zIndex: 2,
    backgroundColor: alpha(theme.palette.common.black, 0.4),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.6),
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.common.white,
    },
  },
  fullScreenButtonGroup: {
    position: 'absolute',
    bottom: 16,
    left: 16,
    zIndex: 2,
    backgroundColor: theme.palette.common.white,
    borderRadius: 2,
  },
  fullScreenButton: {
    width: 40,
    height: 40,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))

export default useStyles