// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  menu: {
    '& .MuiPaper-root': {
      width: 400,
      maxWidth: 400,
    },
    '& .MuiList-root': {
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
      maxHeight: 400,
    },
  },
  menuSearchInput: {
    '& .MuiInput-input': {
      padding: 0,
      height: 42,
      margin: 0,
      borderBottom: 'unset',
      outline: 'unset',
    },
    '& .MuiInputAdornment-root': {
      marginLeft: 8,
      marginRight: 4,
    },
    '& .MuiInput-root:before': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  menuList: {
    overflow: 'auto',
    flex: 1,
  },
  menuOption: {
    padding: '0px 8px',
    '& .MuiListItemIcon-root': {
      minWidth: 'unset', 
      marginRight: '4px',
    },
  },
}))

export default useStyles