// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { makeStyles } from '@mui/styles'
import { alpha } from '@mui/material/styles'

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  iconClose: {
    marginRight: 16,
    cursor: 'pointer',
  },
  titleText: {
    fontWeight: 600,
    fontSize: 24,
    marginRight: 'auto',
  },
  iconAndFormControlContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    height: 65,
    width: '100%',
  },
  autocompleteContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%',
    marginTop: 20,
  },
  iconFormControl: {
    marginRight: 12,
    marginBottom: 2,
    color: colors.iconDark,
  },
  autocompleteListItem: {
    padding: '0px !important',
  },
  autocompleteListItemIcon: {
    minWidth: 'unset !important',
  },
  cancelButton: {
    color: colors.icon,
    '&:hover': {
      backgroundColor: alpha(colors.icon, 0.1),
    },
  },
}))

export default useStyles