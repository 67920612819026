import { useRef, useState } from 'react'

// COMPONENTS
import Map from '../Map/Map'
import GeofenceList from 'components/GeofenceList/GeofenceList'

// LEAFLET
import L from 'leaflet'

// MUIS
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'

// STYLES
import useStyles from './dialogMapUseStyles'

const DialogMap = (props) => {
  const { isDialogMapOpen, setIsDialogMapOpen, cycleDetail } = props

  const classes = useStyles()
  const geoJsonGeofenceListRef = useRef(L.featureGroup())
  const [mapDialog, setMapDialog] = useState(null)

  return (
    <Dialog
      open={isDialogMapOpen}
      onClose={() => {
        setMapDialog(null)
        setIsDialogMapOpen(false)
      }}
      className={classes.dialogRoot}
    >
      {/* CONTENT */}
      <DialogContent className={classes.dialogContent}>
        <Map setMap={setMapDialog}>
          {(Boolean(cycleDetail) && Boolean(mapDialog)) && 
          <GeofenceList
            map={mapDialog}
            selectedGeofenceList={[...cycleDetail?.haulList, ...cycleDetail?.pitList, ...cycleDetail?.portList]}
            hasGeofenceListFinishedLoading={!Boolean(cycleDetail)}
            geoJsonGeofenceListRef={geoJsonGeofenceListRef}
          />}
        </Map>
      </DialogContent>

      {/* ACTIONS */}
      <DialogActions>
        <Button onClick={() => {
          setMapDialog(null)
          setIsDialogMapOpen(false)
        }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogMap