// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconVehicleSeven = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M3.15009 8.52919L6.58354 2L9.74987 8.01439L9.51683 8.52919H3.15009Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.5 18.8304H2V10.114H10.6169L13.1169 4.56722H17V5.94602L22 10.5736V17.2456C22 18.1172 21.25 18.8304 20.3333 18.8304H19.5C19.5 20.5816 18.0083 22 16.1667 22C14.325 22 12.8333 20.5816 12.8333 18.8304H11.1667C11.1667 20.5816 9.675 22 7.83333 22C5.99167 22 4.5 20.5816 4.5 18.8304ZM20.3333 11.2392L17 8.15681V11.6988H20.3333V11.2392ZM17.8333 18.8304C17.8333 17.9588 17.0833 17.2456 16.1667 17.2456C15.25 17.2456 14.5 17.9588 14.5 18.8304C14.5 19.702 15.25 20.4152 16.1667 20.4152C17.0833 20.4152 17.8333 19.702 17.8333 18.8304ZM9.5 18.8304C9.5 17.9588 8.74996 17.2456 7.83333 17.2456C6.91671 17.2456 6.16667 17.9588 6.16667 18.8304C6.16667 19.702 6.91671 20.4152 7.83333 20.4152C8.74996 20.4152 9.5 19.702 9.5 18.8304Z" />
    </SvgIcon>
  )
}

export default IconVehicleSeven