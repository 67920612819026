// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  dateRangePickerDialog: {
    '& .MuiPaper-root': {
      maxWidth: 'unset',
    },
  },
  daysToggleButtonGroup: {
    gap: 7,
  },
  daysToggleButton: {
    '&.MuiToggleButtonGroup-grouped': {
      width: 32,
      height: 32,
      borderRadius: '4px !important',
      textTransform: 'capitalize',
      border: `1px solid transparent !important`,
      backgroundColor: `${theme.palette.action.selected}`,
    },
    '&.MuiToggleButton-root.Mui-selected': {
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.primary.main} !important`,
    },
  },
  timeRangeSlider: {
    margin: '0px 8px',
    width: 'unset',
    '& .MuiSlider-thumb': {
      height: 16,
      width: 16,
      backgroundColor: '#fff',
      boxShadow: theme.shadows[4],
      '&:focus, &:hover, &.Mui-active': {
        boxShadow: theme.shadows[4],
        '@media (hover: none)': {
          boxShadow: theme.shadows[4],
        },
      },
    },
  },
  canbusCheckboxs: {
    '& .MuiCheckbox-root': {
      height: 28,
      width: 28,
      marginLeft: 4,
    },
    '& .MuiTypography-root': {
      fontSize: '14px',
    },
  },
}))

export default useStyles