import React, { useState, useContext, Fragment } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

// ASSETS
import IconDrawerToggle from 'assets/images/icons/IconDrawerToggle'

// CONSTANTS
import { values } from 'constants/values'

// CONTEXTS
import { MainLayoutContext } from 'contexts/MainLayoutContext'

// CUSTOM COMPONENTS
import CustomPermanentDrawer from 'components/Customs/CustomPermanentDrawer'
import CustomMenu from 'components/Customs/CustomMenu'
import CustomMenuItem from 'components/Customs/CustomMenuItem'

// DATA
import {
  getBottomNavigationList,
  getTopNavigationList, 
} from './drawerNavigationList'

// MUIS
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconExpandLess from '@mui/icons-material/ExpandLess';
import IconExpandMore from '@mui/icons-material/ExpandMore';
import IconLanguage from '@mui/icons-material/Language'
import IconLogout from '@mui/icons-material/Logout'

// STYLES
import useStyles from './drawerUseStyles'

// UTILS
import { getCompanyLogo } from 'utils/assets'
import { isNavigationItemActive } from 'utils/drawerUtil'
import { removeAllDataFromLocalStorage } from 'utils/localStorage'

const Drawer = () => {
  const classes = useStyles()

  const { 
    changeExpandParent,
    changeLastClicked,
    changeIsDrawerExpanded, 
    expandParent, 
    isDrawerExpanded, 
  } = useContext(MainLayoutContext)
  
  const navigate = useNavigate()
  const location = useLocation()

  const [ siteMenuAnchor, setSiteMenuAnchor ] = useState(null)
  const [ selectedSite, setSelectedSite ] = useState(values.siteOptionList[0])

  const handleSiteMenuItemClick = (inputItem, inputIndex) => {
    setSelectedSite(inputItem)
    setSiteMenuAnchor(null)
  }

  const handleChildrenItemClick = (inputEvent, inputChildrenItem) => {
    inputEvent.preventDefault()

    changeLastClicked('children')
    navigate(inputChildrenItem.path)
  }

  const handleParentItemClick = (inputEvent, inputParentItem) => {
    inputEvent.preventDefault()

    if (inputParentItem.type === 'single') {
      changeLastClicked('parent')
    }
    else if(inputParentItem.type === 'collection' && isDrawerExpanded) {
      if (expandParent === inputParentItem.title) {
        changeExpandParent(null)
        changeLastClicked('parent')
      }
      else {
        changeExpandParent(inputParentItem.title)
        changeLastClicked('parent')
      }
    }

    navigate(inputParentItem.path)
  }

  const logOutUser = () => {
    navigate('/sign-in')
    removeAllDataFromLocalStorage()
  }

  return (
    <CustomPermanentDrawer
      variant="permanent"
      open={isDrawerExpanded}
      className={`${classes.drawerRoot} zoom`}
    >
      {/* HEADER */}
      <Box className={classes.drawerHeader}>
        {/* TOGGLE ICON */}
        <IconButton
          className={
            isDrawerExpanded ? classes.iconToggle : classes.iconToogleOnClose
          }
          onClick={() => changeIsDrawerExpanded((current) => !current)}
        >
          <IconDrawerToggle color="primary" />
        </IconButton>

        {/* COMPANY LOGO */}
        {isDrawerExpanded && (
          <Box
            component="img"
            src={getCompanyLogo("full")}
            alt=""
            className={classes.logoCompany}
          />
        )}
      </Box>

      {/* SITE SELECT */}
      <List>
        <ListItemButton
          className={classes.siteSelect}
          onClick={(event) => setSiteMenuAnchor(event.currentTarget)}
        >
          {/* ICON */}
          <Avatar className={classes.siteAvatar}>
            <IconLanguage className={classes.siteIcon} />
          </Avatar>

          {/* SITE NAME */}
          <Box>
            <Typography variant="subtitle2" className={classes.siteTitle}>
              Site Profile
            </Typography>

            <Typography variant="subtitle2" className={classes.siteValue}>
              {selectedSite}
            </Typography>
          </Box>

          {/* DROPDOWN ICON */}
          <IconExpandMore className={classes.siteIconDropDown} />
        </ListItemButton>

        {/* SITE MENU */}
        <CustomMenu
          anchorEl={siteMenuAnchor}
          open={Boolean(siteMenuAnchor)}
          onClose={() => setSiteMenuAnchor(null)}
          className={classes.siteMenu}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          {/* SITE OPTION LIST */}
          {values.siteOptionList.map((item, index) => (
            <CustomMenuItem
              key={index}
              onClick={() => handleSiteMenuItemClick(item, index)}
            >
              {item}
            </CustomMenuItem>
          ))}
        </CustomMenu>
      </List>

      {/* NAVIGATION LIST */}
      <List>
        {getTopNavigationList().map((item, index) => (
          <Fragment key={index}>
            <ListItemButton
              href={item.type === "single" ? item.path : null}
              className={
                isNavigationItemActive(location.pathname, item.path)
                  ? `${classes.navigationItem} ${classes.navigationItemActive}`
                  : classes.navigationItem
              }
              onClick={(event) => handleParentItemClick(event, item)}
            >
              {/* ICON */}
              <ListItemIcon>
                <item.icon
                  color={
                    isNavigationItemActive(location.pathname, item.path)
                      ? "primary"
                      : "default"
                  }
                  className={`${classes.navigationIcon} ${classes.navigationIconCustom}`}
                />
              </ListItemIcon>

              {/* TEXT */}
              <ListItemText className={classes.navigationText}>
                {item.title}
              </ListItemText>

              {/* EXPAND/COLLAPSE ICON */}
              {item.type === "collection" &&
                isDrawerExpanded &&
                (expandParent === item.title ? (
                  <IconExpandLess
                    className={classes.siteIconDropDown}
                  />
                ) : (
                  <IconExpandMore
                    className={classes.siteIconDropDown}
                  />
                ))}
            </ListItemButton>

            {/* NAVIGATION ITEM - CHILDREN */}
            <Collapse
              in={
                item.children && expandParent === item.title && isDrawerExpanded
              }
              timeout="auto"
            >
              {item.children &&
                item.children.map((childrenItem, childrenIndex) => (
                  <ListItemButton
                    key={childrenIndex}
                    href={childrenItem.path}
                    className={
                      isNavigationItemActive(
                        location.pathname,
                        childrenItem.path
                      )
                        ? `${classes.navigationItem} ${classes.navigationItemActive}`
                        : classes.navigationItem
                    }
                    onClick={(event) =>
                      handleChildrenItemClick(event, childrenItem)
                    }
                  >
                    {/* ICON */}
                    <ListItemIcon>
                      {childrenItem.icon && (
                        <childrenItem.icon
                          color={
                            isNavigationItemActive(location.pathname, item.path)
                              ? "primary"
                              : "default"
                          }
                          className={`${classes.navigationIcon} ${classes.navigationIconCustom}`}
                        />
                      )}
                    </ListItemIcon>

                    {/* TEXT */}
                    <ListItemText className={classes.navigationText}>
                      {childrenItem.title}
                    </ListItemText>
                  </ListItemButton>
                ))}
            </Collapse>
          </Fragment>
        ))}
      </List>

      {/* BOTTOM LIST */}
      <List className={classes.navigationBottom}>
        {getBottomNavigationList().map((item, index) => (
          <ListItemButton
            key={index}
            className={
              isNavigationItemActive(location.pathname, item.path)
                ? `${classes.navigationItem} ${classes.navigationItemActive}`
                : classes.navigationItem
            }
            onClick={() => navigate(item.path)}
          >
            {/* ICON */}
            <ListItemIcon>
              <item.icon
                color={
                  isNavigationItemActive(location.pathname, item.path)
                    ? "primary"
                    : "default"
                }
                className={`${classes.navigationIcon} ${classes.navigationIconCustom}`}
              />
            </ListItemIcon>

            {/* TEXT */}
            <ListItemText className={classes.navigationText}>
              {item.title}
            </ListItemText>
          </ListItemButton>
        ))}

        {/* LOGOUT */}
        <ListItemButton className={classes.navigationItem} onClick={logOutUser}>
          <ListItemIcon className={classes.navigationIcon}>
            <IconLogout />
          </ListItemIcon>
          <ListItemText className={classes.navigationText}>
            Log Out
          </ListItemText>
        </ListItemButton>
      </List>
    </CustomPermanentDrawer>
  );
}

export default Drawer