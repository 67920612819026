// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconHaul = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M11 22V18H6L3 15L6 12H11V10H4V4H11V2H13V4H18L21 7L18 10H13V12H20V18H13V22H11Z"/>
    </SvgIcon>
  )
}

export default IconHaul