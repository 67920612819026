// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    '& .MuiPaper-root': {
      maxWidth: 'unset',
      maxHeight: 'unset',
      width: 1000,
      height: 600,
    },
  },
  dialogContent: {
    backgroundColor: 'orange',
    position: 'relative',
    padding: 0,
  },
}))

export default useStyles