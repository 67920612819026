import { useState, useEffect, useContext } from 'react'

// COMPONENTS
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'
import LeftContent from './LeftContent/LeftContent'
import LoadingBox from 'components/LoadingBox/LoadingBox'
import RightContent from './RightContent/RightContent'

// CONTEXTS
import { MainLayoutContext } from 'contexts/MainLayoutContext'
import { ReportsPageContext } from 'contexts/ReportsPageContext'

// MUIS
import Stack from '@mui/material/Stack'

// SERVICES
import { deleteReport } from 'services/reportServices'

// STYLES
import pageStyles from 'styles/layoutMainPageUseStyles'

const Reports = () => {
  const pageClasses = pageStyles()

  const { changeSnackbarObject } = useContext(MainLayoutContext)
  const { 
    isLoading, setIsLoading,
    setShouldReloadReportListData,
    setSelectedReport,
    dialogDeleteReport, setDialogDeleteReport,
    editReportObject,
  } = useContext(ReportsPageContext)
  
  const [ isLeftContentExpanded, setIsLeftContentExpanded ] = useState(true)
  const [ isCreatingReport, setIsCreatingReport ] = useState(false)

  const handleContinueDeleteReportButtonClick = async () => {
    setIsLoading(true)

    const resultDeleteReport = await deleteReport(dialogDeleteReport?.id)
    
    if (resultDeleteReport.status === 204) {
      setDialogDeleteReport(null)
      setSelectedReport(null)
      setShouldReloadReportListData(true)

      changeSnackbarObject({
        open: true,
        severity: 'success',
        message: 'Success to delete report',
      })
    }
    else changeSnackbarObject({
      open: true,
      severity: 'error',
      message: 'Failed to delete report',
    })

    setIsLoading(false)
  }

  useEffect(() => {
    if (editReportObject) setIsCreatingReport(true)
  }, [editReportObject])

  return (
    <Stack className={pageClasses.pageRoot}>
      <LoadingBox 
        isLoading={isLoading}
        className='flexDirectionRow'
      >
        <LeftContent
          isLeftContentExpanded={isLeftContentExpanded}
          setIsLeftContentExpanded={setIsLeftContentExpanded}
          isCreatingReport={isCreatingReport} 
          setIsCreatingReport={setIsCreatingReport}
        />

        <RightContent 
          isCreatingReport={isCreatingReport}
          setIsCreatingReport={setIsCreatingReport}
        />
      </LoadingBox>

      <DialogConfirmation
        title='Delete Report'
        caption='Are you sure you want to delete this report?'
        dialogConfirmationObject={dialogDeleteReport}
        setDialogConfirmationObject={setDialogDeleteReport}
        cancelButtonText='Cancel'
        continueButtonText='Delete'
        onContinueButtonClick={handleContinueDeleteReportButtonClick}
        onCancelButtonClick={() => setDialogDeleteReport(null)}
      />
    </Stack>
  )
}

export default Reports