import { useRef, useEffect, useContext } from 'react'

// COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'
import CustomDataGridPro from 'components/Customs/CustomDataGridPro'

// CONTEXTS
import { MainLayoutContext } from 'contexts/MainLayoutContext'
import { ReportsPageContext } from 'contexts/ReportsPageContext'

// MUIS
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

// MUI ICONS
import IconClose from '@mui/icons-material/Close'
import IconDelete from '@mui/icons-material/Delete'
import IconDownload from '@mui/icons-material/Download'

// SERVICES
import { getDownloadReport } from 'services/reportServices'

// STYLES
import useStyles from './reportsContentUseStyles'

// UTILITIES
import { downloadFileFromFileObject } from 'utils/file'

const ReportsContent = (props) => {
  const { 
    tabList, setTabList,
    currentTab, setCurrentTab, 
    columns, 
    rows, 
  } = props
  
  const classes = useStyles()
  const isOnDeleted = useRef(false)

  const { changeSnackbarObject } = useContext(MainLayoutContext)
  const {
    setIsLoading,
    selectedReport,
    setDialogDeleteReport,
  } = useContext(ReportsPageContext)

  const actions = [
    {
      value: 'download',
      icon: IconDownload,
    },
    {
      value: 'delete',
      icon: IconDelete,
    },
  ]
  
  const handleDownloadReport = async () => {
    setIsLoading(true)

    const resultDownloadReport = await getDownloadReport(selectedReport?.id)
    
    if (resultDownloadReport.status === 200) {
      downloadFileFromFileObject(
        new Blob([ resultDownloadReport.data ]),
        'Report Details.xlsx',
      )
    }
    else changeSnackbarObject({
      open: true,
      severity: 'error',
      message: 'Failed to download the report',
    })

    setIsLoading(false)
  }

  const handleActionButtonClick = (action) => {
    if (action === actions[0].value) handleDownloadReport()
    else if (action === actions[1].value) setDialogDeleteReport(selectedReport)
  }

  const handleDeleteTab = (inputId) => {
    const tempData = [...tabList].filter(item => item.id !== inputId)
    isOnDeleted.current = true

    // current tab is deleted
    if (currentTab === inputId) {
      setCurrentTab(tempData?.length ? tempData[0].id : 0)
      isOnDeleted.current = false
    }
    setTabList(tempData)
  }

  const handleTabsChange = (event, newValue) => {
    if (!isOnDeleted.current) setCurrentTab(newValue)
    isOnDeleted.current = false
  }

  useEffect(() => {
    if (tabList.length > 0) setCurrentTab(tabList[0].id)
  }, [tabList])

  return (
  <>
    <Stack className={classes.headerRoot}>
      {/* HEADER & ACTION */}
      <Stack className={classes.headerContent}>
        <Stack className={classes.headerAction}>
          {/* ACTIONS */}
          {actions.map((item, index) => (
            <CustomTooltipBlack 
              key={index}
              placement='top' 
              title={item.value}
              className='textTransformCapitalize'
            >
              <IconButton 
                className={classes.iconButtonAction} 
                disableRipple 
                size='small'
                onClick={() => handleActionButtonClick(item.value)}
              >
                <item.icon fontSize='small'/>
              </IconButton>
            </CustomTooltipBlack>
          ))}
        </Stack>

        <Typography 
          variant='subtitle1' 
          fontWeight={600}
        >
          {selectedReport?.name}
        </Typography>
      </Stack>

      <Divider />

      {/* TABS */}
      <Stack>
        <Tabs 
          variant='scrollable'
          scrollButtons='auto'
          className={classes.headerTabs} 
          value={currentTab} 
          onChange={handleTabsChange}
        >
          {tabList?.map((item, index) => (
          <Tab
            key={item.id}
            value={item.id}
            label={
              <>
                <Typography variant='subtitle2' color='black' fontWeight={500}>{item.name}</Typography>
                <IconClose
                  fontSize='small'
                  onClick={() => handleDeleteTab(item.id)}
                  className={classes.iconButtonTabClose}
                />
              </>
            }
            className={classes.headerTab}
            disableRipple
          />))}
        </Tabs>
      </Stack>
    </Stack>

    <Stack 
      flex={1} 
      height='100%'
    >
      {(rows && columns) &&
      <CustomDataGridPro
        columns={columns}
        rows={rows}
        checkboxSelection={false}
      />}
    </Stack>
  </>
  )
}

export default ReportsContent