// MUIS
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
}));

const CustomTooltip = (props) => {
  return (
    <Stack className="neutralize-zoom-tooltip">
      <StyledTooltip className="no-zoom" {...props} />
    </Stack>
  );
};

export default CustomTooltip;
