import React, { useState } from 'react'
import { Navigate } from 'react-router-dom'

// COMPONENTS
import CardsColumnChart from './CardsColumnChart/CardsColumnChart'
import CardsOverall from './CardsOverall/CardsOverall'
import CardStatisticsReport from './CardStatisticsReport/CardStatisticsReport'
import Header from './Header/Header'

// CONSTANTS
import { values } from 'constants/values'

// MUIS
import Box from '@mui/material/Box'

// STYELS
import pageStyles from 'styles/layoutMainPageUseStyles'
import useStyles from './dashboardUseStyles'

// UTILS
import {
  getStartDateFromLocalStorage,
  getEndDateFromLocalStorage,
} from 'utils/localStorage'

const Dashboard = () => {
  const pageClasses = pageStyles()
  const classes = useStyles()

  const initialDateRangeValue = [ 
    getStartDateFromLocalStorage(), 
    getEndDateFromLocalStorage() 
  ]

  const [ dateRangeValue, setDateRangeValue ] = useState(initialDateRangeValue)

  if (values.dashboardType === values.dashboardTypeGmm) return (
    <Navigate
      replace 
      to='/routes'
    />
  )

  return (
    <Box className={`${pageClasses.pageRoot} ${classes.root}`}>
      {/* HEADER */}
      <Header
        dateRangeValue={dateRangeValue}
        setDateRangeValue={setDateRangeValue}
      />

      {/* OVERALL CARDS */}
      <CardsOverall dateRangeValue={dateRangeValue}/>

      {/* STATISTICS CARD */}
      <CardStatisticsReport dateRangeValue={dateRangeValue}/>

      {/* COLUMN CHART CARDS */}
      <CardsColumnChart dateRangeValue={dateRangeValue}/>
    </Box>
  )
}

export default Dashboard