import PropTypes from 'prop-types'

// MUIS
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// UTILITIES
import { lightOrDarkColor } from 'utils/color'

const FlyoutChipList = (props) => {
  const { 
    title, 
    list,
    ...others
  } = props

  return (
    <Stack 
      {...others}
      width='100%'
      maxWidth='100%'
    >
      {/* TITLE */}
      <Typography
        variant='caption'
        fontWeight={700}
        color='text.secondary'
      >
        {title}
      </Typography>

      {/* CHIP LIST */}
      {list && list.length > 0 ? (
        <Stack
          direction='row'
          gap='4px'
          flexWrap='wrap'
        >
          {list.map((item, index) => (
            <Chip
              key={index}
              label={item.name}
              variant='filled'
              size='small'
              sx={{
                backgroundColor: item.color,
                color: lightOrDarkColor(item.color) === 'light' ? '#000000' : '#FFFFFF',
              }}
            />
          ))}
        </Stack>
      ) : (
        <Typography
          variant='caption'
          color='text.primary'
        >
          No data
        </Typography>
      )}
    </Stack>
  )
}

FlyoutChipList.defaultProps = {
  title: '',
  list: [],
}

FlyoutChipList.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
}

export default FlyoutChipList