// APIS
import axios from 'apis/axios'

// CONSTANTS
import { values } from 'constants/values'

// SERVICES
import { postRefreshToken } from './tokenServices'

// UTILS
import { 
  convertTimeStampToTimeStampAt12PM,
  convertSecondToHMS, 
  convertValueTo1DecimalPoint,
  convertTimeStampToDateAndTimeGMT8,
} from 'utils/date'

// SUMMARY SECTION

export const getSummaryList = async (
  inputBeginTime, 
  inputEndTime, 
  inputShift,
) => {
  const resultRefreshToken = await postRefreshToken()

  if(resultRefreshToken.status === 200) {
    try {
      let completeUrl ='/devices/all/summaries'
      completeUrl = `${completeUrl}?beginTs=${Math.round(convertTimeStampToTimeStampAt12PM(inputBeginTime) / 1000)}`
      completeUrl = `${completeUrl}&endTs=${Math.round(convertTimeStampToTimeStampAt12PM(inputEndTime) / 1000)}`
      completeUrl = `${completeUrl}&shift=${inputShift}`

      const response = await axios.get(
        completeUrl,
        { 
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${resultRefreshToken.data.accessToken}`,
          },
        },
      )
  
      return response
    }
    catch (error) {
      if (!error.response) return { status: 'No Server Response' }
      else return error.response
    }
  }
}

export const formatSummaryListOutput = (inputResponseJSON) => {
  const output = inputResponseJSON.map((item, index) => {
    return {
      id: item.deviceId,
      no: index + 1,
      deviceId: item.deviceId,
      deviceName: item.deviceName,
      averageLoadedDriveTime: convertSecondToHMS(item.summary.averageLoadedDriveTime),
      averageEmptyDriveTime: convertSecondToHMS(item.summary.averageEmptyDriveTime),
      averageEmptyStopTime: convertSecondToHMS(item.summary.averageEmptyStopTime),
      averageLoadingTime: convertSecondToHMS(item.summary.averageLoadingTime),
      averageLoadedStopTime: convertSecondToHMS(item.summary.averageLoadedStopTime),
      averageCycleTime: convertSecondToHMS(item.summary.averageCycleTime),
      averageLoadedSpeed: convertValueTo1DecimalPoint(item.summary.averageLoadedSpeed),
      averageEmptySpeed: convertValueTo1DecimalPoint(item.summary.averageEmptySpeed),
      averageSpeed: convertValueTo1DecimalPoint(item.summary.averageSpeed),
      averagePayload: convertValueTo1DecimalPoint(item.summary.averagePayload),
      averageEmptyDriveDistance: convertValueTo1DecimalPoint(item.summary.averageEmptyDriveDistance),
      averageLoadedDriveDistance: convertValueTo1DecimalPoint(item.summary.averageLoadedDriveDistance),
      totalCycle: item.summary.totalCycle
    }
  })
  return output
}

// CYCLE SECTION

export const getCycleList = async (
  inputBeginTime, 
  inputEndTime, 
  inputDeviceId, 
  inputShift,
) => {
  const resultRefreshToken = await postRefreshToken()

  if(resultRefreshToken.status === 200) {
    try {
      let completeUrl = `/devices/${inputDeviceId}/cycles`
      completeUrl = `${completeUrl}?beginTs=${Math.round(convertTimeStampToTimeStampAt12PM(inputBeginTime) / 1000)}`
      completeUrl = `${completeUrl}&endTs=${Math.round(convertTimeStampToTimeStampAt12PM(inputEndTime) / 1000)}`
      completeUrl = `${completeUrl}&shift=${inputShift}`

      const response = await axios.get(
        completeUrl,
        { 
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${resultRefreshToken.data.accessToken}`,
          },
        },
      )
  
      return response
    }
    catch (error) {
      if (!error.response) return { status: 'No Server Response' }
      else return error.response
    }
  }
}

export const formatCycleListOutput = (inputResponseJSON) => {
  const output = inputResponseJSON.map(item => {
    const cyclesList = item.cycles.map((cycleItem, cycleIndex) => {
      let cyclePortTimestamp, port
      if (values.dashboardType === values.dashboardTypeGmm) {
        cyclePortTimestamp = cycleItem.cycleRomTimestamp
        port = cycleItem.rom
      }
      else {
        cyclePortTimestamp = cycleItem.cyclePortTimestamp
        port = cycleItem.port
      }

      return {
        id: cycleIndex + 1,
        no: cycleIndex + 1,
        cycleBeginTimestamp: convertTimeStampToDateAndTimeGMT8(cycleItem.cycleBeginTimestamp * 1000),
        cyclePortTimestamp: convertTimeStampToDateAndTimeGMT8(cyclePortTimestamp * 1000),
        loadingTime: convertSecondToHMS(cycleItem.loadingTime),
        loadedStopTime: convertSecondToHMS(cycleItem.loadedStopTime),
        loadedDriveTime: convertSecondToHMS(cycleItem.loadedDriveTime),
        loadedDriveDistance: convertValueTo1DecimalPoint(cycleItem.loadedDriveDistance),
        loadedSpeed: Math.round(cycleItem.loadedSpeed),
        payload: convertValueTo1DecimalPoint(cycleItem.payload),
        emptyStopTime: convertSecondToHMS(cycleItem.emptyStopTime),
        emptyDriveTime: convertSecondToHMS(cycleItem.emptyDriveTime),
        emptyDriveDistance: convertValueTo1DecimalPoint(cycleItem.emptyDriveDistance),
        emptySpeed: Math.round(cycleItem.emptySpeed),
        speed: Math.round(cycleItem.speed),
        cycleTime: convertSecondToHMS(cycleItem.cycleTime),
        unitLoading: cycleItem.unitLoading,
        port: port,
        cycleEndTimestamp: convertTimeStampToDateAndTimeGMT8(cycleItem.cycleEndTimestamp * 1000),
      }
    })

    return { 
      cycles: cyclesList, 
      deviceName: item.deviceName, 
      deviceId: item.deviceId, 
    }
  })
  return output
}