import React, { useState, useContext, useEffect, useRef } from 'react'

// CONSTANTS
import { values } from 'constants/values'

// CONTEXTS
import { MainLayoutContext } from 'contexts/MainLayoutContext'

// CUSTOM COMPONENTS
import CustomDialogActions from 'components/Customs/CustomDialogActions'
import CustomDialogActionButton from 'components/Customs/CustomDialogActionButton'
import CustomDialogContent from 'components/Customs/CustomDialogContent'
import CustomDialogTitle from 'components/Customs/CustomDialogTitle'
import CustomInput from 'components/Customs/CustomInput'
import CustomInputLabel from 'components/Customs/CustomInputLabel'
import CustomTooltip from 'components/Customs/CustomTooltip'

// LEAFLET
import L from 'leaflet'
import 'leaflet-editable'
import 'leaflet-geometryutil'
import 'leaflet.path.drag'

// MUIS
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import Menu from '@mui/material/Menu'
import Slide from '@mui/material/Slide'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconClose from '@mui/icons-material/Close'

// SERVICES
import { postCreateZone, putUpdateZone } from 'services/zoneServices'

// STYLES
import useStyles from './panelCreateEditZoneUseStyles'

const PanelCreateOrEditZone = (props) => {
  const {
    isPanelCreateOrEditZoneShown, 
    setIsPanelCreateOrEditZoneShown,
    pageRef,
    refetchData,
    map,
    geoJsonGeofenceListRef
  } = props

  const classes = useStyles()
  const polygonRef = useRef()
  
  const { changeSnackbarObject } = useContext(MainLayoutContext)

  const initialPanelParams = {
    name: '',
    color: '#D43A64',
  }

  const [zone, setZone] = useState(initialPanelParams)
  const [colorPickerAnchorElement, setColorPickerAnchorElement] = useState(null)
  const [polygonCoordinates, setPolygonCoordinates] = useState([])
  
  const closePanel = () => {
    setZone(initialPanelParams)
    refetchData()
    polygonRef.current && map.removeLayer(polygonRef.current)
    setIsPanelCreateOrEditZoneShown(false)
  }

  const handleNameChangeEvent = (event) => {
    setZone(p => ({
      ...p,
      name: event.target.value,
    }))
  }

  const onSelectColor = (color) => {
    setColorPickerAnchorElement(null)
    setZone(p => ({
      ...p,
      color,
    }))
    polygonRef.current.setStyle({
      color,
    })
    setStyleButtonVertex(polygonRef.current.editor.editLayer.getLayers(), color)
  }

  const handleSaveButtonClick = async () => {
    if(isPanelCreateOrEditZoneShown?.mode === 'add'){
      const res = await postCreateZone(zone.name, zone.color, polygonCoordinates)

      if(res.status === 200) {
        changeSnackbarObject({
          open: true,
          severity: 'success',
          message: 'Successfully created a new zone',
        })
        closePanel();
      }
    } else if(isPanelCreateOrEditZoneShown?.mode === 'edit' && zone.id){
      const res = await putUpdateZone(zone.id, zone.name, zone.color, polygonCoordinates)

      if(res.status === 200) {
        changeSnackbarObject({
          open: true,
          severity: 'success',
          message: 'Successfully updated zone',
        })
        closePanel();
      }
    }
    refetchData();
  }

  const meterPerPixel = () => {
    const southEastPoint = map.getBounds().getSouthEast()
    const northEastPoint = map.getBounds().getNorthEast()
    const mapHeightInMeter = southEastPoint.distanceTo(northEastPoint)
    const mapHeightInPixels = map.getSize().y

    // return in meter
    return mapHeightInMeter / mapHeightInPixels
  }

  const convertLatLngsToArray = (latLngs) => {
    return latLngs.map(point => [point.lat, point.lng])
  }

  const createEventHandleEditable = () => {
    map?.on('editable:editing', event => {
      setStyleButtonVertex(event.layer.editor.editLayer._layers, event.layer.options.color)
    })

    map?.on('editable:vertex:dragend editable:vertex:deleted editable:dragend', event => {
      setPolygonCoordinates(convertLatLngsToArray(event.layer.getLatLngs()[0]))
      setStyleButtonVertex(event.layer.editor.editLayer.getLayers(), event.layer.options.color)
    })
  }

  const setStyleButtonVertex = (listLayers, color) => {
    if(listLayers?.length) {
      listLayers?.forEach(item => {
        item._icon.style.backgroundColor = color
      })
    }
  }

  const createPolygonLatLngFromCenter = () => {
    // when edit the geofence, dont show other geofences
    if(geoJsonGeofenceListRef.current) geoJsonGeofenceListRef.current.clearLayers()

    // radius = (map height / 4) x meter per pixels
    const radiusInMeter = (map.getContainer().offsetWidth/4) * meterPerPixel()
    const pentagonDegrees = [0, 72, -220, 220, -72, 0]

    // generate lat lng from deg
    const getLatLngPentagon = pentagonDegrees.map(deg => {
      return L.GeometryUtil.destination(map.getCenter(), deg, radiusInMeter)
    })

    polygonRef.current = L.polygon(getLatLngPentagon, {
      color: initialPanelParams.color,
      weight: 1
    })
    map.addLayer(polygonRef.current)
    polygonRef.current.enableEdit()
    polygonRef.current.dragging.enable()

    createEventHandleEditable()
    setPolygonCoordinates(convertLatLngsToArray(getLatLngPentagon))
    setStyleButtonVertex(polygonRef.current.editor.editLayer.getLayers(), initialPanelParams.color)
  }

  const createPolygonFromLatLngs = (latLngs) => {
    // when edit the geofence, dont show other geofences
    if(geoJsonGeofenceListRef.current) geoJsonGeofenceListRef.current.clearLayers()

    polygonRef.current = L.polygon(latLngs, {
      color: isPanelCreateOrEditZoneShown?.data?.color,
      weight: 1
    })
    map.addLayer(polygonRef.current)
    polygonRef.current.enableEdit()
    polygonRef.current.dragging.enable()

    createEventHandleEditable()
    setPolygonCoordinates(latLngs)
    setStyleButtonVertex(
      polygonRef.current.editor.editLayer.getLayers(),
      isPanelCreateOrEditZoneShown?.data?.color
    )
  }

  useEffect(() => {
    if(isPanelCreateOrEditZoneShown && isPanelCreateOrEditZoneShown?.mode === 'add') {
      createPolygonLatLngFromCenter()
    } else if(isPanelCreateOrEditZoneShown?.mode === 'edit') {
      setZone(isPanelCreateOrEditZoneShown?.data)
      const coordinates = JSON.parse(isPanelCreateOrEditZoneShown?.data?.geoJson).features[0].geometry.coordinates
      map.fitBounds(coordinates)
      createPolygonFromLatLngs(coordinates)
    }
  }, [isPanelCreateOrEditZoneShown])

  return (
    <Slide
      direction="right"
      in={Boolean(isPanelCreateOrEditZoneShown)}
      container={pageRef.current}
      mountOnEnter
      unmountOnExit
    >
      <Box className={classes.panelContainer}>
        {/* DIALOG TITLE */}
        <CustomDialogTitle className={classes.dialogTitle}>
          {/* CLOSE ICON */}
          <IconClose
            className={classes.iconClose}
            onClick={closePanel}
          />

          {/* TITLE */}
          <Typography className={classes.titleText}>
            {isPanelCreateOrEditZoneShown?.mode === "add" && "Add New Zone"}
            {isPanelCreateOrEditZoneShown?.mode === "edit" && "Edit Zone"}
          </Typography>
        </CustomDialogTitle>

        {/* DIALOG CONTENT */}
        <CustomDialogContent className={classes.dialogContent}>
          {/* NAME INPUT */}
          <Box className={classes.iconAndFormControlContainer}>
            {/* COLOR PICKER */}
            <CustomTooltip title="Change Color">
              <Stack
                className={classes.colorPicker}
                sx={{ backgroundColor: zone ? zone?.color : initialPanelParams.color }}
                onClick={(event) =>
                  setColorPickerAnchorElement(event.currentTarget)
                }
              />
            </CustomTooltip>
            <FormControl variant="standard" className={classes.formControl}>
              <CustomInputLabel>Zone Name</CustomInputLabel>
              <CustomInput
                type="text"
                name="name"
                value={zone?.name}
                onChange={handleNameChangeEvent}
              />
            </FormControl>
          </Box>
        </CustomDialogContent>

        {/* DIALOG ACTIONS */}
        <CustomDialogActions>
          {/* CANCEL BUTTON */}
          <CustomDialogActionButton
            className={classes.cancelButton}
            onClick={closePanel}
          >
            Cancel
          </CustomDialogActionButton>

          {/* OK BUTTON */}
          <CustomDialogActionButton onClick={handleSaveButtonClick} disabled={!zone?.name || !zone?.color}>
            Save
          </CustomDialogActionButton>
        </CustomDialogActions>

        {/* COLOR PICKER MENU */}
        <Menu
          open={Boolean(colorPickerAnchorElement)}
          anchorEl={colorPickerAnchorElement}
          onClose={() => setColorPickerAnchorElement(null)}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          className={`${classes.colorPickerMenu} neutralize-zoom-menu`}
        >
          <Stack direction="row" className={classes.colorWrap}>
            {values?.colorsCst?.map((item, index) => {
              return (
                <Stack
                  key={index}
                  className={classes.itemColor}
                  onClick={() => onSelectColor(item)}
                  sx={{ backgroundColor: item }}
                />
              );
            })}
          </Stack>
        </Menu>
      </Box>
    </Slide>
  );
}

export default PanelCreateOrEditZone