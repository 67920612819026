// APIS
import axios from 'apis/axios'

// SERVICES
import { postRefreshToken } from './tokenServices'

export const postCreateReport = async (bodyparameters) => {
  const resultRefreshToken = await postRefreshToken()

  if(resultRefreshToken.status === 200) {
    try {
      const response = await axios.post(
        '/reports/create',
        JSON.stringify(bodyparameters),
        { 
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${resultRefreshToken.data.accessToken}`,
          },
        },
      )
  
      return response
    }
    catch (error) {
      if (!error.response) return { status: 'No Server Response' }
      else return error.response
    }
  }
}

export const getReportList = async () => {
  const resultRefreshToken = await postRefreshToken()

  if(resultRefreshToken.status === 200) {
    try {
      const response = await axios.get(
        '/reports/list',
        { 
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${resultRefreshToken.data.accessToken}`,
          },
        },
      )
  
      return response
    }
    catch (error) {
      if (!error.response) return { status: 'No Server Response' }
      else return error.response
    }
  }
}

export const getDetailReport = async (reportId) => {
  const resultRefreshToken = await postRefreshToken()

  if(resultRefreshToken.status === 200) {
    try {
      const response = await axios.get(
        `/reports/${reportId}`,
        { 
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${resultRefreshToken.data.accessToken}`,
          },
        },
      )
  
      return response
    }
    catch (error) {
      if (!error.response) return { status: 'No Server Response' }
      else return error.response
    }
  }
}

export const deleteReport = async (reportId) => {
  const resultRefreshToken = await postRefreshToken()

  if(resultRefreshToken.status === 200) {
    try {
      const response = await axios.delete(
        `/reports/${reportId}/delete`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${resultRefreshToken.data.accessToken}`,
          },
        }
      );
  
      return response
    }
    catch (error) {
      if (!error.response) return { status: 'No Server Response' }
      else return error.response
    }
  }
}

export const getDownloadReport = async (reportId) => {
  const resultRefreshToken = await postRefreshToken()

  if(resultRefreshToken.status === 200) {
    try {
      const response = await axios.get(
        `/reports/${reportId}/download`,
        { 
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${resultRefreshToken.data.accessToken}`,
          },
        },
      )
  
      return response
    }
    catch (error) {
      if (!error.response) return { status: 'No Server Response' }
      else return error.response
    }
  }
}

export const putUpdateReport = async (reportId, bodyparameters) => {
  const resultRefreshToken = await postRefreshToken()

  if(resultRefreshToken.status === 200) {
    try {
      const response = await axios.put(
        `/reports/${reportId}/update`,
        bodyparameters,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${resultRefreshToken.data.accessToken}`,
          },
        }
      );
  
      return response
    }
    catch (error) {
      if (!error.response) return { status: 'No Server Response' }
      else return error.response
    }
  }
}