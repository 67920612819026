// MUI STYLES
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  pageRoot: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  mapContainer: {
    position: 'relative',
    zIndex: 1,
    '&.leaflet-container': {
      width: '100%',
      height: '100%',
    },
    flex: 1,
    '& .leaflet-vertex-icon, .leaflet-middle-icon': {
      height: '12px !important',
      width: '12px !important',
      marginLeft: '-6px !important',
      marginTop: '-6px !important',
      borderRadius: '50%',
      border: `1px solid ${alpha(theme.palette.common.black, 0.6)}`
    },
    '& .leaflet-middle-icon': {
      backgroundColor: 'inherit'
    }
  },
  tableContainer: {
    flexBasis: '460px',
    flexGrow: '0',
  },
  cellContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  circleStyle: {
    width: '10px',
    height: '10px',
    marginRight: '8px',
  },
  columnActions: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export default useStyles