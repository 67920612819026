// CONSTANTS
import { colors, entLacakColors, iotColors, gmmColors } from 'constants/colors'
import { values } from 'constants/values'

// MUIS
import { createTheme } from '@mui/material/styles'

const getPaletteColors = () => {
  if (values.dashboardType === values.dashboardTypePpa) {
    return {
      primary: {
        main: colors.red1,
      },
      error: {
        main: entLacakColors.error,
      },
    }
  }
  else if (values.dashboardType === values.dashboardTypeIot) {
    return {
      primary: iotColors.primary,
      secondary: iotColors.secondary,
      error: {
        main: entLacakColors.error,
      },
    }
  }
  else if (values.dashboardType === values.dashboardTypeGmm) {
    return {
      primary: gmmColors.primary,
      error: {
        main: entLacakColors.error,
      },
    }
  }
}

const customTheme = createTheme({
  components: {
    MuiFilledInput: {
      defaultProps: {
        sx: {
          fontSize: 14,
        },
      },
    },
    MuiInput: {
      defaultProps: {
        sx: {
          fontSize: 14,
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontSize: 14,
        },
      },
    },
    MuiListItemText: {
      defaultProps: {
        sx: {
          '& .MuiTypography-root': {
            fontSize: 14,
          },
        },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        sx: {
          fontSize: 14,
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        sx: {
          fontSize: 14,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        sx: {
          fontSize: 14,
          '& .MuiInputBase-input': {
            fontSize: 14,
          },
          '& .MuiInputLabel-root': {
            fontSize: 14,
          },
        },
      },
    },
  },
  palette: getPaletteColors(),
  typography: {
    fontFamily: [
      'Open Sans', 'sans-serif',
    ].join(','),
  },
})

export default customTheme