// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconVehicleFour = (props) => {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.6896 4H15.1034C15.4848 4 15.7931 4.32509 15.7931 4.72727C15.7931 5.12945 15.4848 5.45455 15.1034 5.45455H13.3792V12.7273C13.3792 12.928 13.2248 13.0909 13.0344 13.0909H3.07225C2.89776 13.0909 2.75018 12.9527 2.73018 12.7695L2.00465 6.26691C1.98189 6.06036 2.04327 5.85382 2.17431 5.69891C2.30466 5.54327 2.49293 5.45455 2.68949 5.45455H11.9999V4.72727C11.9999 4.32582 12.3089 4 12.6896 4ZM4.93089 10.5455C5.21641 10.5455 5.44813 10.3011 5.44813 10V8.18182C5.44813 7.88073 5.21641 7.63636 4.93089 7.63636C4.64537 7.63636 4.41364 7.88073 4.41364 8.18182V10C4.41364 10.3011 4.64537 10.5455 4.93089 10.5455ZM7.68953 10.5455C7.97505 10.5455 8.20678 10.3011 8.20678 10V8.18182C8.20678 7.88073 7.97505 7.63636 7.68953 7.63636C7.40401 7.63636 7.17228 7.88073 7.17228 8.18182V10C7.17228 10.3011 7.40401 10.5455 7.68953 10.5455ZM10.4482 10.5455C10.7337 10.5455 10.9654 10.3011 10.9654 10V8.18182C10.9654 7.88073 10.7337 7.63636 10.4482 7.63636C10.1627 7.63636 9.93093 7.88073 9.93093 8.18182V10C9.93093 10.3011 10.1627 10.5455 10.4482 10.5455Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M20.1034 6.90909C20.4069 6.90909 20.6759 7.12727 20.7655 7.44L22 12V16.3636C22 16.7636 21.6897 17.0909 21.3103 17.0909H19.931C19.931 18.6953 18.6938 20 17.1724 20C15.651 20 14.4137 18.6953 14.4137 17.0909H9.5861C9.5861 18.6953 8.34885 20 6.82745 20C5.30606 20 4.06881 18.6953 4.06881 17.0909H3.37915C2.99845 17.0909 2.68949 16.7651 2.68949 16.3636V14.9091C2.68949 14.5076 2.99845 14.1818 3.37915 14.1818H13.7241C14.1048 14.1818 14.4137 13.856 14.4137 13.4545V7.63636C14.4137 7.23491 14.7227 6.90909 15.1034 6.90909H20.1034ZM5.44813 17.0909C5.44813 17.8931 6.06676 18.5455 6.82745 18.5455C7.58815 18.5455 8.20678 17.8931 8.20678 17.0909C8.20678 16.2887 7.58815 15.6364 6.82745 15.6364C6.06676 15.6364 5.44813 16.2887 5.44813 17.0909ZM15.793 17.0909C15.793 17.8931 16.4117 18.5455 17.1724 18.5455C17.9331 18.5455 18.5517 17.8931 18.5517 17.0909C18.5517 16.2887 17.9331 15.6364 17.1724 15.6364C16.4117 15.6364 15.793 16.2887 15.793 17.0909ZM19.8414 8H17.1724V12H20.9241L19.8414 8Z" />
    </SvgIcon>
  )
}

export default IconVehicleFour