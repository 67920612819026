import React, { useState, useContext, useEffect } from 'react'

// COMPONENTS
import DialogAddOrEdit from 'components/DialogAddOrEdit/DialogAddOrEdit'
import DialogUploadImage from '../DialogUploadImage/DialogUploadImage'

// CONTEXTS
import { MainLayoutContext } from 'contexts/MainLayoutContext'

// CUSTOM COMPONENTS
import CustomDialogActions from 'components/Customs/CustomDialogActions'
import CustomDialogActionButton from 'components/Customs/CustomDialogActionButton'
import CustomDialogContent from 'components/Customs/CustomDialogContent'
import CustomDialogTitle from 'components/Customs/CustomDialogTitle'
import CustomInput from 'components/Customs/CustomInput'
import CustomInputLabel from 'components/Customs/CustomInputLabel'

// MUIS
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconAddAPhoto from '@mui/icons-material/AddAPhoto'
import IconAnimation from '@mui/icons-material/Animation'
import IconChromeReaderMode from '@mui/icons-material/ChromeReaderMode'
import IconClose from '@mui/icons-material/Close'
import IconEventNote from '@mui/icons-material/EventNote'
import IconLabel from '@mui/icons-material/Label'
import IconLocalGasStation from '@mui/icons-material/LocalGasStation'
import IconNearMe from '@mui/icons-material/NearMe'

// SERVICES
import { getTrackerList } from 'services/trackerServices'
import { postCreateVehicle, putUpdateVehicle } from 'services/vehicleServices'

// STYLES
import useStyles from './dialogAddOrEditVehicleUseStyles'

// UTILS
import { getIconVehicleById } from '../VehiclesUtils'

const DialogAddOrEditVehicle = (props) => {
  const {
    dialogAddOrEditVehicle, 
    setDialogAddOrEditVehicle,
    pageRef,
    setIsLoading,
    setShouldReloadTableData,
  } = props

  const classes = useStyles()
  
  const { changeSnackbarObject } = useContext(MainLayoutContext)

  const initialDialogParams = {
    vehicleName: '',
    gpsId: '',
    licenseNumber: '',
    type: '',
    year: '',
    fuel: '',
  }

  const [ dialogParams, setDialogParams ] = useState(initialDialogParams)
  const [ isDialogUploadImageShown, setIsDialogUploadImageShown ] = useState(null)
  const [ selectedImage, setSelectedImage ] = useState(null)
  const [ trackerList, setTrackerList ] = useState([])

  const fetchTrackerList = async () => {
    const response = await getTrackerList()

    if (response?.status === 200) setTrackerList(response?.data)
    else setTrackerList([])
  }

  const handleDialogParamsChange = (event) => {
    setDialogParams({
      ...dialogParams,
      [event.target.name]: event.target.value,
    })
  }

  const handleSaveButtonClick = async () => {
    let severity, message, isFormInvalid = false

    Object.keys(dialogParams).forEach(key => {
      if (!dialogParams[key]) {
        isFormInvalid = true
      }
    })

    if (isFormInvalid || !selectedImage) {
      severity = 'error'
      message = 'All form must filled.'
    }

    setIsLoading(true)

    if (dialogAddOrEditVehicle?.type === 'add' && !isFormInvalid && selectedImage) {
      const resultCreateVehicle = await postCreateVehicle({
        ...dialogParams,
        icon: Number(selectedImage)
      })
      
      if (resultCreateVehicle?.status === 200) {
        setShouldReloadTableData(true)
        setDialogParams(initialDialogParams)
        
        severity = 'success'
        message = 'Successfully creating a new vehicle'

        setDialogAddOrEditVehicle(null)
      }
      else {
        severity = 'error'
        message = resultCreateVehicle?.data?.message ?? 'Error when creating a new vehicle'
      }
    } else if (dialogAddOrEditVehicle?.type === 'edit' && !isFormInvalid && selectedImage) {
      const resultEditVehicle = await putUpdateVehicle(
        dialogAddOrEditVehicle?.data?.id,
        {
          ...dialogParams,
          icon: selectedImage
        }
      )

      if (resultEditVehicle?.status === 200) {
        setShouldReloadTableData(true)
        setDialogParams(initialDialogParams)
        
        severity = 'success'
        message = 'Successfully updating a vehicle'

        setDialogAddOrEditVehicle(null)
      }
      else {
        severity = 'error'
        message = 'Error when updating a vehicle'
      }
    }

    setIsLoading(false)

    changeSnackbarObject({
      open: true,
      severity,
      message,
    })
  }

  const findTrackerItemById = (id) => {
    return trackerList?.find(item => item.id === id) ?? { id: 0, label: '' }
  }

  const getCurrentVehicleIcon = () => {
    if (selectedImage) {
      const Icon = getIconVehicleById(selectedImage)?.Icon
      return <Icon className={classes.iconAddPhoto} />

    } else {
      return <IconAddAPhoto className={classes.iconAddPhoto} />
    }
  }

  useEffect(() => {
    fetchTrackerList()
  }, [])

  useEffect(() => {
    if (dialogAddOrEditVehicle?.type === 'edit') {
      const itemVehicle = dialogAddOrEditVehicle?.data
      setDialogParams({
        vehicleName: itemVehicle?.vehicleName,
        gpsId: itemVehicle?.deviceObject?.id ?? null,
        licenseNumber: itemVehicle?.licenseNumber,
        type: itemVehicle?.type,
        year: itemVehicle?.year,
        fuel: itemVehicle?.fuel,
        icon: itemVehicle?.icon
      })
      setSelectedImage(itemVehicle?.icon)
    } else if (dialogAddOrEditVehicle?.type === 'add') {
      setDialogParams(initialDialogParams)
      setSelectedImage(null)
    }
  }, [dialogAddOrEditVehicle])

  return (
    <DialogAddOrEdit
      open={Boolean(dialogAddOrEditVehicle)}
      containerRef={pageRef}
    >
      {/* DIALOG TITLE */}
      <CustomDialogTitle className={classes.dialogTitle}>
        {/* CLOSE ICON */}
        <IconClose
          className={classes.iconClose}
          onClick={() => setDialogAddOrEditVehicle(false)}
        />

        {/* TITLE */}
        <Typography className={classes.titleText}>
          {dialogAddOrEditVehicle?.type === 'add' && 'Add New Vehicle'}
          {dialogAddOrEditVehicle?.type === 'edit' && 'Edit Vehicle'}
        </Typography>

        {/* UPLOAD PHOTO */}
        <Box 
          className={classes.uploadPhotoContainer}
          onClick={() => setIsDialogUploadImageShown(true)}
        >
          <Box className={classes.uploadPhoto}>
            {getCurrentVehicleIcon()}
          </Box>
        </Box>
      </CustomDialogTitle>

      {/* DIALOG UPLOAD IMAGE */}
      <DialogUploadImage
        isDialogUploadImageShown={isDialogUploadImageShown}
        setIsDialogUploadImageShown={setIsDialogUploadImageShown}
        setSelectedImage={setSelectedImage}
        selectedImage={selectedImage}
      />

      {/* DIALOG CONTENT */}
      <CustomDialogContent>
        {/* MAIN SECTION TEXT */}
        <Typography 
          variant='h6'
          className={classes.sectionText}
        >
          Main Information
        </Typography>

        {/* NAME INPUT */}
        <Box className={classes.iconAndFormControlContainer}>
          <IconLabel className={classes.iconFormControl}/>
          <FormControl 
            variant='standard' 
            className={classes.formControl}
          >
            <CustomInputLabel>
              Vehicle Name
            </CustomInputLabel>
            <CustomInput
              type='text'
              name='vehicleName'
              value={dialogParams.vehicleName}
              onChange={handleDialogParamsChange}
            />
          </FormControl>
        </Box>

        {/* GPS ID INPUT */}
        <Box className={classes.iconAndFormControlContainer}>
          <IconNearMe className={classes.iconFormControl}/>
          {/* <FormControl 
            variant='standard' 
            className={classes.formControl}
          >
            <CustomInputLabel>
              GPS ID
            </CustomInputLabel>
            <CustomInput
              type='text'
              name='gpsId'
              value={dialogParams.gpsId}
              onChange={handleDialogParamsChange}
            />
          </FormControl> */}

          <Autocomplete
            disablePortal
            options={trackerList}
            className={classes.autocompleteGpsId}
            renderInput={(params) => <TextField
              {...params}
              variant='filled'
              label='GPS ID'
            />}
            onInputChange={(event, newValue) => {
              const findObject = trackerList.find(item => item.label === newValue)
              setDialogParams({
                ...dialogParams,
                gpsId: findObject?.id
              })
            }}
            value={findTrackerItemById(dialogParams?.gpsId)?.label}
          />
        </Box>

        {/* LICENSE NUMBER INPUT */}
        <Box className={classes.iconAndFormControlContainer}>
          <IconChromeReaderMode className={classes.iconFormControl}/>
          <FormControl 
            variant='standard' 
            className={classes.formControl}
          >
            <CustomInputLabel>
              License Plate
            </CustomInputLabel>
            <CustomInput
              type='text'
              name='licenseNumber'
              value={dialogParams.licenseNumber}
              onChange={handleDialogParamsChange}
            />
          </FormControl>
        </Box>

        {/* TYPE INPUT */}
        <Box className={classes.iconAndFormControlContainer}>
          <IconAnimation className={classes.iconFormControl}/>
          <FormControl 
            variant='standard' 
            className={classes.formControl}
          >
            <CustomInputLabel>
              Type
            </CustomInputLabel>
            <CustomInput
              type='text'
              name='type'
              value={dialogParams.type}
              onChange={handleDialogParamsChange}
            />
          </FormControl>
        </Box>

        {/* YEAR INPUT */}
        <Box className={classes.iconAndFormControlContainer}>
          <IconEventNote className={classes.iconFormControl}/>
          <FormControl 
            variant='standard' 
            className={classes.formControl}
          >
            <CustomInputLabel>
              Year
            </CustomInputLabel>
            <CustomInput
              type='number'
              name='year'
              value={dialogParams.year}
              onChange={handleDialogParamsChange}
            />
          </FormControl>
        </Box>

        {/* FUEL INPUT */}
        <Box className={classes.iconAndFormControlContainer}>
          <IconLocalGasStation className={classes.iconFormControl}/>
          <FormControl 
            variant='standard' 
            className={classes.formControl}
          >
            <CustomInputLabel>
              Fuel
            </CustomInputLabel>
            <CustomInput
              type='text'
              name='fuel'
              value={dialogParams.fuel}
              onChange={handleDialogParamsChange}
            />
          </FormControl>
        </Box>
      </CustomDialogContent>

      {/* DIALOG ACTIONS */}
      <CustomDialogActions>
        {/* CANCEL BUTTON */}
        <CustomDialogActionButton 
          className={classes.cancelButton}
          onClick={() => setDialogAddOrEditVehicle(null)}
        >
          Cancel
        </CustomDialogActionButton>

        {/* OK BUTTON */}
        <CustomDialogActionButton onClick={handleSaveButtonClick}>
          Save
        </CustomDialogActionButton>
      </CustomDialogActions>
    </DialogAddOrEdit>
  )
}

export default DialogAddOrEditVehicle