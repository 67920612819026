import { useState, useEffect, useRef, useContext } from 'react'

// COMPONENTS
import CellChips from './CellChips/CellChips'
import DataGridCellExpand from 'components/DataGridCellExpand/DataGridCellExpand'
import DialogAddOrEditCycle from './DialogAddOrEditCycle/DialogAddOrEditCycle'
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'
import Flyout from './Flyout/Flyout'
import LoadingBox from 'components/LoadingBox/LoadingBox'
import DataGridFilters from 'components/DataGridFilters/DataGridFilters'
import PageHeader from 'components/PageHeader/PageHeader'

// CONSTANTS
import { entLacakColors } from 'constants/colors'

// COTEXTS
import { MainLayoutContext } from 'contexts/MainLayoutContext'

// CUSTOM COMPONENTS
import CustomDataGridPro from 'components/Customs/CustomDataGridPro'
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// MUIS
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'

// MUI ICONS
import IconDelete from '@mui/icons-material/Delete'
import IconEdit from '@mui/icons-material/Edit'

// SERVICES
import { getCycleList, deleteCycle } from 'services/cycleServices'
import { getTrackerList } from 'services/trackerServices'
import { getZoneList } from 'services/zoneServices'

// STYELS
import pageStyles from 'styles/layoutMainPageUseStyles'

// UTILITIES
import { convertDateToUserTimezone } from 'utils/date'

const Cycles = () => {
  const pageClasses = pageStyles()

  const pageRef = useRef()

  const { changeSnackbarObject } = useContext(MainLayoutContext)

  const initialColumns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 75,
      hide: false,
    },
    {
      field: 'calculatorName',
      headerName: 'Cycle Name',
      flex: 1,
      minWidth: 200,
      hide: false,
      renderCell: (params) => (
        <DataGridCellExpand 
          value={params.value || ''} 
          width={params.colDef.computedWidth} 
        />
      ),
    },
    {
      field: 'pitList',
      headerName: 'PITs',
      flex: 1,
      minWidth: 250,
      hide: false,
      renderCell: (params) => (
        params.value.length > 0 &&
        <CellChips 
          type='pit'
          list={params.value}
        />
      ),
    },
    {
      field: 'haulList',
      headerName: 'HAULs',
      width: 250,
      hide: false,
      renderCell: (params) => (
        params.value.length > 0 &&
        <CellChips 
          type='haul'
          list={params.value}
        />
      ),
    },
    {
      field: 'portList',
      headerName: 'PORTs',
      flex: 1,
      minWidth: 250,
      hide: false,
      renderCell: (params) => (
        params.value.length > 0 &&
        <CellChips 
          type='port'
          list={params.value}
        />
      ),
    },
    {
      field: 'deviceList',
      headerName: 'Devices',
      flex: 1,
      minWidth: 250,
      hide: false,
      renderCell: (params) => (
        params.value.length > 0 &&
        <CellChips list={params.value}/>
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      width: 200,
      hide: false,
      valueGetter: (params) => convertDateToUserTimezone(params.value),
    },
    {
      field: 'updatedAt',
      headerName: 'Last Updated',
      width: 200,
      hide: false,
      valueGetter: (params) => convertDateToUserTimezone(params.value),
    },
    {
      field: 'actions',
      headerName: '',
      width: 120,
      hide: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Stack
          width='100%'
          direction='row'
          justifyContent='center'
          alignItems='center'
        >
          {/* EDIT ICON */}
          <CustomTooltipBlack
            title='Edit'
            placement='top'
          >
            <IconButton onClick={(event) => handleEditButtonClick(event, params.row)}>
              <IconEdit/>
            </IconButton>
          </CustomTooltipBlack>

          {/* DELETE ICON */}
          <CustomTooltipBlack
            title='Delete'
            placement='top'
          >
            <IconButton onClick={(event) => handleDeleteButtonClick(event, params.row)}>
              <IconDelete/>
            </IconButton>
          </CustomTooltipBlack>
        </Stack>
      ),
    },
  ]
  
  const [ search, setSearch ] = useState('')
  const [ isLoading, setIsLoading ] = useState(false)
  const [ shouldReloadData, setShouldReloadData ] = useState(true)
  const [ tableData, setTableData ] = useState([])
  const [ selectedColumnList, setSelectedColumnList ] = useState(initialColumns)
  const [ selectionModel, setSelectionModel ] = useState([])
  const [ dialogAddOrEditType, setDialogAddOrEditType ] = useState(null)
  const [ dialogAddOrEditCycle, setDialogAddOrEditCycle ] = useState(null)
  const [ dialogDeleteCycle, setDialogDeleteCycle ] = useState(null)
  const [ cycleDetail, setCycleDetail ] = useState(null)

  const handleAddButtonClick = () => {
    setDialogAddOrEditType('add')
    setDialogAddOrEditCycle(true)
  }

  const handleEditButtonClick = (event, row) => {
    event.stopPropagation()
    setDialogAddOrEditType('edit')
    setDialogAddOrEditCycle(row)
  }

  const handleDeleteButtonClick = async (event, row) => {
    event.stopPropagation()

    setDialogDeleteCycle(row)
  }

  const handleContinueDeleteButtonClick = async () => {
    const resultDeleteCycle = await deleteCycle(dialogDeleteCycle.id)

    if (resultDeleteCycle.status === 200) {
      setDialogDeleteCycle(null)
      setShouldReloadData(true)
    }
    else changeSnackbarObject({
      open: true,
      severity: 'error',
      message: resultDeleteCycle.data.message,
    })
  }

  const getZoneListByIdList = (zoneList, idList) => {
    const output = idList.map(id => {
      const selectedZone = zoneList.find(zoneItem => zoneItem.id === id)

      if (selectedZone) return selectedZone 
      else return null
    })

    return output
  }

  const getCycleData = async () => {
    setIsLoading(true)

    const resultCycleList = await getCycleList()
    const resultZoneList = await getZoneList()
    const resultTrackerList = await getTrackerList()

    if (
      resultCycleList.status === 200 
      && resultZoneList.status === 200
      && resultTrackerList.status === 200
    ) {
      const newTableData = resultCycleList.data.map(cycleItem => {
        return {
          ...cycleItem,
          pitList: getZoneListByIdList(resultZoneList.data, cycleItem.pitList.map(item => item.id)),
          haulList: getZoneListByIdList(resultZoneList.data, cycleItem.haulList.map(item => item.id)),
          portList: getZoneListByIdList(resultZoneList.data, cycleItem.portList.map(item => item.id)),
          deviceList: getZoneListByIdList(resultTrackerList.data, cycleItem.deviceList.map(item => item.id)).map(deviceItem => {
            return {
              ...deviceItem,
              name: deviceItem.label,
              color: entLacakColors.backgroundPage,
            }
          }),
        }
      })

      setTableData(newTableData)
    }

    setIsLoading(false)
    setShouldReloadData(false)
  }

  useEffect(() => {
    shouldReloadData && getCycleData()
  }, [shouldReloadData])

  useEffect(() => {
    if (selectionModel.length === 1) {
      const selectedCycle = tableData.filter(item => item.id === selectionModel[0])
      if (selectedCycle) setCycleDetail(selectedCycle[0])
    }
    else setCycleDetail(null)
  }, [selectionModel])

  return (
    <Box 
      className={pageClasses.pageRoot}
      ref={pageRef}
    >
      {/* PAGE HEADER */}
      <PageHeader
        isAddButtonAvailable={true}
        onAddButtonIsClicked={handleAddButtonClick}
        isBackButtonAvailable={false}
        targetBackUrl={null}
        title='Cycles'
        isSearchAvailable={true}
        search={search}
        setSearch={setSearch}
        isExtraComponentAvailable={false}
        extraComponent={null}
      />

      {/* CONTENT */}
      <LoadingBox isLoading={isLoading}>
        {/* DATA GRID FILTERS */}
        <DataGridFilters
          isWithTabs={false}
          title='Cycle List' 
          selectedTab={null}
          setSelectedTab={null}
          tabItemList={null}
          isWithDownload={false}
          isWithCustomizeColumn={true}
          columns={initialColumns}
          selectedColumnList={selectedColumnList}
          setSelectedColumnList={setSelectedColumnList}
          isWithDateRange={false}
          dateRangeValue={null}
          setDateRangeValue={null}
        />

        {/* TABLE */}
        <CustomDataGridPro
          columns={selectedColumnList}
          rows={tableData}
          selectionModel={selectionModel}
          setSelectionModel={setSelectionModel}
        />
      </LoadingBox>

      {/* DIALOG ADD OR EDIT CYCLE */}
      <DialogAddOrEditCycle
        dialogType={dialogAddOrEditType}
        setDialogAddOrEditType={setDialogAddOrEditType}
        dialogAddOrEditCycle={dialogAddOrEditCycle}
        setDialogAddOrEditCycle={setDialogAddOrEditCycle}
        pageRef={pageRef}
        setShouldReloadData={setShouldReloadData}
      />

      {/* DIALOG DELETE CYCLE */}
      <DialogConfirmation
        title='Delete Cycle'
        caption='Are you sure you want to delete this cycle?'
        dialogConfirmationObject={dialogDeleteCycle}
        setDialogConfirmationObject={setDialogDeleteCycle}
        cancelButtonText='Cancel'
        continueButtonText='Delete'
        onContinueButtonClick={handleContinueDeleteButtonClick}
        onCancelButtonClick={() => setDialogDeleteCycle(null)}
      />

      {/* FLYOUT */}
      <Flyout
        cycleDetail={cycleDetail}
        setCycleDetail={setCycleDetail}
        pageRef={pageRef}
        setShouldReloadData={setShouldReloadData}
      />
    </Box>
  )
}

export default Cycles