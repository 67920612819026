import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// ASSETS
import PictureAuthenticationPpa from 'assets/images/pictures/authentication1.png'
import PictureAuthenticationIot from 'assets/images/pictures/authentication-iot.jfif'

// CONSTANTS
import { values } from 'constants/values'

// CUSTOM COMPONENTS
import CustomInput from 'components/Customs/CustomInput'
import CustomInputLabel from 'components/Customs/CustomInputLabel'

// MUIS
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputAdornment from '@mui/material/InputAdornment'

// MUI ICONS
import IconLock from '@mui/icons-material/Lock'
import IconPerson from '@mui/icons-material/Person'
import IconVisibility from '@mui/icons-material/Visibility'
import IconVisibilityOff from '@mui/icons-material/VisibilityOff'

// SERVICES
import { postSignInUser } from 'services/authenticationServices'

// STYLES
import useStyles from './signInUseStyles'

// UTILS
import { getCompanyLogo } from 'utils/assets'
import { 
  isEmailFormatValid, 
  isPasswordFormatValid, 
} from 'utils/authenticationUtil'
import { 
  saveUserProfileToLocalStorage, 
  saveUserAccessTokenToLocalStorage,
  saveUserRefreshTokenToLocalStorage,
} from 'utils/localStorage'

const SignIn = () => {
  const classes = useStyles()
  
  const navigate = useNavigate()

  const initialSignInForm = {
    email: '',
    password: '',
  }

  const initialErrorForm = {
    email: null,
    password: null,
  }

  const [ signInForm, setSignInForm ] = useState(initialSignInForm)
  const [ errorForm, setErrorForm ] = useState(initialErrorForm)
  const [ showPassword, setShowPassword ] = useState(false)
  const [ isRendered, setIsRendered ] = useState(false)
  const [ isButtonDisabled, setIsButtonDisabled ] = useState(true)

  const handleSignInFormChange = (event) => {
    setSignInForm(current => ({
      ...current,
      [event.target.name]: event.target.value,
    }))
  }

  const handleSignInButtonClick = async () => {
    const resultSignIn = await postSignInUser(signInForm.email, signInForm.password)
    if(resultSignIn.status === 200) {
      setErrorForm(initialErrorForm)
      saveUserProfileToLocalStorage(resultSignIn.data.email)
      saveUserAccessTokenToLocalStorage(resultSignIn.data.accessToken)
      saveUserRefreshTokenToLocalStorage(resultSignIn.data.refreshToken)
      navigate('/')
    }
    else if(resultSignIn.status === 401) {
      setErrorForm({
        email: 'Wrong username or password',
        password: null,
      })
    }
  }

  const getAuthenticationPicture = () => {
    if (values.dashboardType === values.dashboardTypePpa) return PictureAuthenticationPpa
    else if (
      values.dashboardType === values.dashboardTypeIot 
      || values.dashboardType === values.dashboardTypeGmm
    ) return PictureAuthenticationIot
  }

  useEffect(() => {
    if(!isRendered) {
      setIsRendered(true)
      setErrorForm(initialErrorForm)
    }
  }, [])
  
  useEffect(() => {
    if(isRendered) {
      if(!isEmailFormatValid(signInForm.email)) {
        if(signInForm.email === '') {
          setErrorForm(current => ({
            ...current,
            email: 'This field is required',
          }))
        }
        else {
          setErrorForm(current => ({
            ...current,
            email: 'E-mail must be valid',
          }))
        }
      }
      else {
        setErrorForm(current => ({
          ...current,
          email: null,
        }))
      }
    }
  }, [signInForm.email])

  useEffect(() => {
    if(isRendered) {  
      if(!isPasswordFormatValid(signInForm.password)) {
        if(signInForm.password === '') {
          setErrorForm(current => ({
            ...current,
            password: 'This field is required',
          }))
        }
        else {
          setErrorForm(current => ({
            ...current,
            password: 'The minimum length for this field is 6',
          }))
        }
      }
      else {
        setErrorForm(current => ({
          ...current,
          password: null,
        }))
      }
    }
  }, [signInForm.password])

  useEffect(() => {
    if(isRendered) {
      if(signInForm.email === '' || signInForm.password === '') setIsButtonDisabled(true)
      else if(Boolean(errorForm.email) || Boolean(errorForm.password)) setIsButtonDisabled(true)
      else setIsButtonDisabled(false)
    }
  }, [signInForm.email, signInForm.password, errorForm.email, errorForm.password])

  return (
    <Box className={classes.signInRoot}>
      {/* IMAGE CONTAINER */}
      <Box className={classes.imageContainer}>
        <Box
          component='img'
          src={getAuthenticationPicture()}
          alt=''
          className={classes.image}
        />
      </Box>

      {/* FORM */}
      <Box className={classes.form}>
        {/* COMPANY LOGO */}
        <Box
          component='img'
          src={getCompanyLogo('full')}
          alt=''
          className={classes.logoCompany}
        />

        {/* EMAIL INPUT */}
        <Box className={classes.formItem}>
          <IconPerson className={classes.formItemIcon}/>

          <FormControl 
            variant='standard'
            className={classes.input}
            error={Boolean(errorForm.email)}
          >
            <CustomInputLabel>
              Login or Email Address
            </CustomInputLabel>

            <CustomInput 
              autoFocus
              name='email'
              value={signInForm.email}
              onChange={handleSignInFormChange}
            />

            <FormHelperText className={classes.inputHelper}>
              {errorForm.email}
            </FormHelperText>
          </FormControl>
        </Box>

        {/* PASSWORD INPUT */}
        <Box className={classes.formItem}>
          <IconLock className={classes.formItemIcon}/>

          <FormControl 
            variant='standard'
            className={classes.input}
            error={Boolean(errorForm.password)}
          >
            <CustomInputLabel>
              Password
            </CustomInputLabel>

            <CustomInput 
              type={showPassword ? 'text' : 'password'}
              name='password'
              value={signInForm.password}
              onChange={handleSignInFormChange}
              endAdornment={
                <InputAdornment 
                  position='end'
                  onClick={() => setShowPassword(current => !current)}
                >
                  {showPassword ? 
                  <IconVisibilityOff className={classes.iconPassword}/> : 
                  <IconVisibility className={classes.iconPassword}/>}
                </InputAdornment>
              }
            />

            <FormHelperText className={classes.inputHelper}>
              {errorForm.password}
            </FormHelperText>
          </FormControl>
        </Box>

        {/* SIGN IN BUTTON */}
        <Button 
          disabled={isButtonDisabled}
          variant='contained'
          className={classes.button}
          onClick={handleSignInButtonClick}
        >
          Login
        </Button>
      </Box>
    </Box>
  )
}

export default SignIn