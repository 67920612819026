import { useRef } from 'react'
import PropTypes from 'prop-types'

// MUIS
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// PATH ICONS
import IconHaul from 'assets/images/icons/IconHaul'
import IconPit from 'assets/images/icons/IconPit'
import IconPort from 'assets/images/icons/IconPort'

// UTILITIES
import { lightOrDarkColor } from 'utils/color'

// STYLES
import useStyles from './cellChipsUseStyles'

const typeList = ['pit', 'haul', 'port']

const CellChips = (props) => {
  const { 
    type,
    list, 
  } = props
  
  const classes = useStyles()

  const wrapperRef = useRef()

  const getChipIcon = (type, backgroundColor) => {
    if (type === typeList[0]) return (
      <IconPit 
        fontSize='small'
        color='primary'
        className={classes.chipIcon}
        sx={{ color: lightOrDarkColor(backgroundColor) === 'light' ? '#000000' : '#FFFFFF' }}
      />
    )
    else if (type === typeList[1]) return (
      <IconHaul 
        fontSize='small'
        color='primary'
        className={classes.chipIcon}
        sx={{ color: lightOrDarkColor(backgroundColor) === 'light' ? '#000000' : '#FFFFFF' }}
      />
    )
    else if (type === typeList[2]) return (
      <IconPort 
        fontSize='small'
        color='primary'
        className={classes.chipIcon}
        sx={{ color: lightOrDarkColor(backgroundColor) === 'light' ? '#000000' : '#FFFFFF' }}
      />
    )
  }

  const calculateAndCutValue = () => {
    if(wrapperRef.current) {
      const totalMaxString = wrapperRef.current?.offsetWidth / 11
      const restructureVal = list?.map(item => item.name.toString().replace(/\,/g, ';'))
      const shownData = restructureVal?.toString()?.substring(0, totalMaxString)?.split(',')?.filter(item => {
        const isValidName = list?.find(itemCheck => itemCheck.name.toString().replace(/\,/g, ';') === item)
        return Boolean(isValidName)
      })

      const maxLimitValues = shownData.length

      return (
        <Stack 
          direction='row'
          spacing='4px'
          alignItems='center'
        >
          {/* CHIP LIST */}
          {list.slice(0, shownData.length).map((item, index) => (
            <Chip
              key={index}
              icon={getChipIcon(type, item.color)}
              label={item.name} 
              variant='filled'
              size='small'
              sx={{
                backgroundColor: item.color,
                color: lightOrDarkColor(item.color) === 'light' ? '#000000' : '#FFFFFF',
              }}
            />
          ))}

          {/* TOTAL OF THE REST TEXT */}
          {list.length > maxLimitValues && 
          <Typography variant='inherit'>
            +{list.length - maxLimitValues}
          </Typography>}
        </Stack>
      )
    } 
    else return (list.length >= 1
      ? list.map(item => item).toString().replace(/\,/g, ', ').replace(/\;/g, ',')
      : 'Default'
    )
  }

  return (
    <Stack 
      ref={wrapperRef} 
      direction='row' 
      alignItems='center' 
      width='100%'
    >
      {calculateAndCutValue()}
    </Stack>
  )
}

CellChips.defaultProps = {
  list: [],
}

CellChips.propTypes = {
  list: PropTypes.array.isRequired,
  type: PropTypes.oneOf(typeList),
}

export default CellChips