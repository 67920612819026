import { format } from 'date-fns'
import moment from 'moment'

export const convertTimeStampToDateOnly = (inputTimeStamp) => {
  const date = (new Date(inputTimeStamp)).toLocaleString('en-US', { })
  const formattedDate = format(new Date(date), 'yyyy-MM-dd')
  return formattedDate
}

export const convertTimeStampToTimeStampAt12PM = (inputTimeStamp) => {
  const date = new Date(inputTimeStamp)
  date.setHours(12, 0, 0)
  return date.getTime()
}

export const convertTimeStampToTimeStampAt12AM = (inputTimeStamp) => {
  const date = new Date(inputTimeStamp)
  date.setHours(24, 0, 0)
  return date.getTime()
}

export const convertSecondToHMS = (inputSecond) => {
  const hour = Math.floor(inputSecond / 3600)
  const minute = Math.floor(inputSecond % 3600 / 60)
  const second = Math.floor(inputSecond % 3600 % 60)

  const hourDisplay = hour >= 10 ? hour : `0${hour}`
  const minuteDisplay = minute >= 10 ? minute : `0${minute}`
  const secondDisplay = second >= 10 ? second : `0${second}`

  if(inputSecond === 0){
    return '00:00:00'
  }
  else{
    return `${hourDisplay}:${minuteDisplay}:${secondDisplay}`
  }
}

export const convertValueTo1DecimalPoint = (inputValue) => {
  const rounded = Math.round(inputValue * 10) / 10
  return rounded
}

export const convertTimeStampToDateAndTimeGMT7 = (inputTimeStamp) => {
  const date = (new Date(inputTimeStamp)).toLocaleString('en-US', {timeZone: 'Asia/Jakarta'})
  const formattedDate = format(new Date(date), 'yyyy-MM-dd HH:mm:ss')
  return formattedDate
}

export const convertTimeStampToDateAndTimeGMT8 = (inputTimeStamp) => {
  const date = (new Date(inputTimeStamp)).toLocaleString('en-US', {timeZone: 'Asia/Brunei'})
  const formattedDate = format(new Date(date), 'yyyy-MM-dd HH:mm:ss')
  return formattedDate
}

export const convertTimeStampToHourMinute = (inputTimeStamp) => {
  const date = (new Date(inputTimeStamp)).toLocaleString('en-US', {timeZone: 'Asia/Jakarta'})
  const formattedDate = format(new Date(date), 'HH:mm')
  return formattedDate
} 

export const convertTimeStampToHMS = (inputTimeStamp) => {
  const date = (new Date(inputTimeStamp)).toLocaleString('en-US', {timeZone: 'Asia/Jakarta'})
  const formattedDate = format(new Date(date), 'HH:mm:ss')
  return formattedDate
} 

export const convertDateToIsoStringDate = (inputDate) => {
  return `${inputDate.getFullYear()}-${inputDate.getMonth() + 1}-${inputDate.getDate()}T${inputDate.getHours()}:${inputDate.getMinutes()}:${inputDate.getSeconds()}`
}

export const convertDateToUserTimezone = (inputTimeStamp) => {
  if (!inputTimeStamp) return ''
  else {
    const date = (new Date(inputTimeStamp)).toLocaleString('en-US')
    const formattedDate = format(new Date(date), 'yyyy-MM-dd HH:mm:ss')
    const timezone = new Date().toTimeString().slice(9, 15)

    return date === 'Invalid Date' ? 'Invalid Date' : `${formattedDate} ${timezone}`
  }
}

export const convertTimerToText = (input) => {
  if (!input) return { hour: 0, minute: 0 }
  const splitTimer = input.split(':')
  const hour = splitTimer[0] - (Math.floor(splitTimer[0]/60) * 60)
  const minute = Number(splitTimer[1])

  return { hour, minute }
}

export const convertCurrentDateToGMT8 = (date) => {
  if (typeof date === 'string') {
    return new Date(
      new Date(date).toLocaleString('en-US', {
        timeZone: 'Asia/Makassar'
      }),
    );
  }

  return new Date(
    date.toLocaleString('en-US', {
      timeZone: 'Asia/Makassar',
    }),
  )
}

export const totalDurationBetweenDate = (inputBegin, inputEnd) => {
  const begin = moment(inputBegin)
  const end = moment(inputEnd)
  const totalDiffSecond = end.diff(begin, 'second')
  const totalHour = Math.floor(totalDiffSecond/60)
  const totalMinute = totalDiffSecond - (totalHour*60)
  
  return {
    hour: totalHour <= 9 ? `0${totalHour}` : totalHour,
    minute: totalMinute <= 9 ? `0${totalMinute}` : totalMinute
  }
}