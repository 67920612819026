const mapOptionList = [
  {
    name: "Google Maps Street",
    url: "http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&language=en",
  },
  {
    name: "Google Maps Hybrid",
    url: "http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}&language=en",
  },
  {
    name: "Google Maps Satellite",
    url: "http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&language=en",
  },
];

export default mapOptionList;
