import PropTypes from 'prop-types'

// MUIS
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI DATA GRID
import {
  gridFilteredDescendantCountLookupSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-premium'

// MUIS ICON
import IconExpandLess from '@mui/icons-material/ExpandLess'
import IconExpandMore from '@mui/icons-material/ExpandMore'

const GroupingCell = (props) => {
  const {
    id,
    rowNode,
    field,
    row,
  } = props

  const apiRef = useGridApiContext()
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector
  )
  
  const childrenCount = filteredDescendantCountLookup[rowNode.id] ?? 0
  
  const handleGroupClick = (event) => {
    apiRef.current.setRowChildrenExpansion(
      id,
      !rowNode.childrenExpanded
    )
    apiRef.current.setCellFocus(id, field)
    event.stopPropagation()
  }
  
  // GROUP ITEM
  if (!rowNode.isAutoGenerated) {
    return (
      <Stack
        direction='row'
        position='relative'
      >
        <Typography
          variant='inherit'
          color='text.primary'
          flexWrap='nowrap'
          className='fullWidth'
          flex={1}
          noWrap
          paddingRight='4px'
        >
          {row.label || '[Tidak Ada Label]'}
        </Typography>
      </Stack>
    )
  }
  
  // GROUP TITLE
  return (
    <Stack
      direction='row'
      onClick={handleGroupClick}
      flexWrap='nowrap'
      alignItems='center'
    >
      {/* TEXT */}
      <Typography
        variant='inherit'
        flexWrap='nowrap'
        className='fullWidth'
        flex={1}
        fontWeight={600}
        overflow='hidden'
      >
        {`${rowNode.groupingKey} (${childrenCount})`}
      </Typography>
  
      {/* EXPAND/COLLAPSE ICON */}
      <Box>
        {rowNode.childrenExpanded ? <IconExpandLess className='textSecondary' /> : <IconExpandMore className='textSecondary' />}
      </Box>
    </Stack>
  )
}

// TO DO: FIX THE PROPS
GroupingCell.defaultProps = {
}

GroupingCell.propTypes = {
  id: PropTypes.any.isRequired,
  rowNode: PropTypes.any.isRequired,
  field: PropTypes.any.isRequired,
  row: PropTypes.any.isRequired,
}

export default GroupingCell