// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconVehicleNine = (props) => {
  return (
    <SvgIcon {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M21.9529 11.7538L19.9059 6.38769C19.6353 5.68615 18.9882 5.23077 18.2706 5.23077H15.5412C15.3059 5.23077 15.0824 5.28 14.8824 5.36615C14.6824 4.57846 14 4 13.1882 4H3.76471C2.78824 4 2 4.82462 2 5.84615V6.92923C2 7.07692 2.02353 7.22462 2.04706 7.37231L3.04706 11.5446C2.43529 11.8277 2 12.48 2 13.2308V15.6923C2 16.7138 2.78824 17.5385 3.76471 17.5385H4.41177C4.68235 18.9415 5.87059 20 7.29412 20C8.71765 20 9.90588 18.9415 10.1765 17.5385H13.8235C14.0941 18.9415 15.2824 20 16.7059 20C18.1294 20 19.3176 18.9415 19.5882 17.5385H20.2353C21.2118 17.5385 22 16.7138 22 15.6923V12C22 11.9262 21.9882 11.84 21.9529 11.7662V11.7538ZM5.52941 7.06462C5.52941 6.72 5.78824 6.44923 6.11765 6.44923C6.44706 6.44923 6.70588 6.72 6.70588 7.06462V9.52615C6.70588 9.87077 6.44706 10.1415 6.11765 10.1415C5.78824 10.1415 5.52941 9.87077 5.52941 9.52615V7.06462ZM9.05882 9.52615C9.05882 9.87077 8.8 10.1415 8.47059 10.1415C8.14118 10.1415 7.88235 9.87077 7.88235 9.52615V7.06462C7.88235 6.72 8.14118 6.44923 8.47059 6.44923C8.8 6.44923 9.05882 6.72 9.05882 7.06462V9.52615ZM11.4118 9.52615C11.4118 9.87077 11.1529 10.1415 10.8235 10.1415C10.4941 10.1415 10.2353 9.87077 10.2353 9.52615V7.06462C10.2353 6.72 10.4941 6.44923 10.8235 6.44923C11.1529 6.44923 11.4118 6.72 11.4118 7.06462V9.52615ZM14.9412 11.3723V7.06462C14.9412 6.72 15.2 6.44923 15.5294 6.44923H18.2588C18.5059 6.44923 18.7176 6.59692 18.8 6.83077L20.5294 11.3723H14.9412ZM8.28571 17.037C8.28571 17.6916 7.77404 18.2222 7.14286 18.2222C6.51167 18.2222 6 17.6916 6 17.037C6 16.3825 6.51167 15.8519 7.14286 15.8519C7.77404 15.8519 8.28571 16.3825 8.28571 17.037ZM16.8571 18.2222C17.4883 18.2222 18 17.6916 18 17.037C18 16.3825 17.4883 15.8519 16.8571 15.8519C16.226 15.8519 15.7143 16.3825 15.7143 17.037C15.7143 17.6916 16.226 18.2222 16.8571 18.2222Z" />
    </SvgIcon>
  )
}

export default IconVehicleNine