// CONSTANTS
import { values } from 'constants/values'

// ASSETS
import IconAssessmentOutlined from '@mui/icons-material/AssessmentOutlined'
import IconCanbus from 'assets/images/icons/IconCanbus'
import IconFuelReport from 'assets/images/icons/IconFuelReport'
import IconInsight from 'assets/images/icons/IconInsight'
import IconPayload from 'assets/images/icons/IconPayload'
import IconRoutes from 'assets/images/icons/IconRoute'
import IconSettings from 'assets/images/icons/IconSettings'
import IconTracking from 'assets/images/icons/IconTracking'
import IconVehicle from 'assets/images/icons/IconVehicle'
import IconZone from 'assets/images/icons/IconZone'

export const defaultTopNavigationList = [
  {
    title: 'Insight',
    icon: IconInsight,
    path: '/',
  },
  {
    type: 'collection',
    title: 'Tracking',
    icon: IconTracking,
    path: '/tracking',
    children: [
      {
        path: '/tracking/live-cycle',
        title: 'Live Cycle',
      },
    ],
  },
  {
    type: 'collection',
    title: 'Zones',
    icon: IconZone,
    path: '/zones',
    children: [
      {
        path: '/zones/cycles',
        title: 'Cycles',
      },
    ],
  },
  {
    title: 'Routes',
    icon: IconRoutes,
    path: '/routes',
  },
  {
    title: 'Reports',
    icon: IconAssessmentOutlined,
    path: '/reports',
  },
  {
    title: 'Canbus',
    icon: IconCanbus,
    path: '/canbus',
  },
  {
    title: 'Fuel Reports',
    icon: IconFuelReport,
    path: '/fuel-report',
  },
  {
    title: 'Payload',
    icon: IconPayload,
    path: '/payload',
  },
  {
    title: 'Vehicles',
    icon: IconVehicle,
    path: '/vehicles',
  },
]

const gmmTopNavigationList = [
  {
    title: 'Routes',
    icon: IconRoutes,
    path: '/routes',
  },
]

export const getTopNavigationList = () => {
  if (values.dashboardType === values.dashboardTypeGmm) return gmmTopNavigationList
  else return defaultTopNavigationList
}

export const defaultBottomNavigationList = [
  {
    title: 'Settings',
    icon: IconSettings,
    path: '/settings',
  },
]

export const getBottomNavigationList = () => {
  if (values.dashboardType === values.dashboardTypeGmm) return []
  else return defaultBottomNavigationList
}