// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  headerColumnIcon: {
    fontSize: 14,
    marginRight: 4
  },
  dataGridLiveCycle: {
    '& .MuiDataGrid-columnHeader': {
      borderLeft: `1px solid ${theme.palette.divider}`,
      height: 40,
      padding: '0 20px',
      '&[data-field="totalCycle"]': {
        borderLeft: 'none'
      }
    },
    '& .MuiDataGrid-cell': {
      padding: 0,
      alignItems: 'flex-end',
      overflow: 'unset !important',
      '&[data-field="totalCycle"]': {
        borderRight: `1px solid ${theme.palette.divider}`,
      }
    }
  },
  timelineBar: {
    backgroundColor: '#EEEEEE',
    height: 8,
  },
  timelineCircle: {
    height: 16,
    width: 16,
    borderRadius: '100%',
    backgroundColor: theme.palette.common.black,
    border: `2px solid ${theme.palette.common.white}`,
    position: 'relative',
    zIndex: 9,
    '&.circleEnd': {
      marginTop: -12,
      marginRight: -10
    },
    '&.inactive': {
      backgroundColor: '#BDBDBD'
    }
  },
  timelineClock: {
    fontSize: 10,
    margin: '2px 0',
    position: 'relative',
    zIndex: 9
  },
  columnDevice: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 40,
    padding: '4px 8px',
    backgroundColor: alpha(colors.blue1, 0.1),
    cursor: 'pointer',
  },
  columnDeviceIcon: {
    marginRight: 4,
    width: 20,
    height: 20,
  },
  columnDeviceText: {
    color: colors.blue1,
    fontSize: 'inherit',
    fontWeight: 'inherit',
  },
  backgroundInfotimeline: {
    backgroundColor: '#FAFAFA'
  }
}))

export default useStyles