import PropTypes from 'prop-types'

// MUIS
import IconButton from '@mui/material/IconButton'
import Input from '@mui/material/Input'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'

// MUI ICONS
import IconClear from '@mui/icons-material/Clear'
import IconSearch from '@mui/icons-material/Search'

const GroupingHeader = (props) => {
  const { search, setSearch, headerWidth } = props

  return (
    <Stack sx={{ width: headerWidth ? headerWidth : '258px' }}>
      {/* TEXT FIELD */}
      <Input
        variant='standard'
        placeholder='Quick Search'
        type='text'
        value={search}
        onChange={(event) => setSearch(event.target.value)}
        onClick={(event) => event.stopPropagation()}
        onKeyDown={(event) => event.stopPropagation()}
        disableUnderline
        fullWidth
        endAdornment={
          <Tooltip
            title='Quick Search'
            placement='bottom'
          >
            <IconButton onClick={() => search !== '' && setSearch('')}>
              {search === '' ? <IconSearch/> : <IconClear/>}
            </IconButton>
          </Tooltip>
        }
      />
    </Stack>
  )
}

GroupingHeader.defaultProps = {
  search: '',
  headerWidth: '',
}

GroupingHeader.propTypes = {
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
  headerWidth: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired,
}

export default GroupingHeader