// ASSETS
import LogoIOTFull from 'assets/images/logos/iot-full.png'
import LogoPPAFull from 'assets/images/logos/ppa-full.png'
import LogoIOT from 'assets/images/logos/iot-logo-only.png'
import LogoPPA from 'assets/images/logos/ppa-logo-only.png'

// CONSTANTS
import { values } from 'constants/values'

export const getCompanyLogo = (inputType = 'full') => {
  // PPA
  if (values.dashboardType === values.dashboardTypePpa) {
    if (inputType === 'full') return LogoPPAFull
    else if (inputType === 'logo') return LogoPPA
  }
  // IOT
  if (values.dashboardType === values.dashboardTypeIot) {
    if (inputType === 'full') return LogoIOTFull
    else if (inputType === 'logo') return LogoIOT
  }
  // GMM
  if (values.dashboardType === values.dashboardTypeGmm) {
    if (inputType === 'full') return 'https://gmmteletech.com/api/static/paas/1/logo.png'
    else if (inputType === 'logo') return 'https://gmmteletech.com/api/static/paas/1/favicon.ico'
  }
}