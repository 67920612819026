import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// ASSETS
import IconRouteVehicle from 'assets/images/icons/IconRouteVehicle'

// COMPONENTS
import LoadingBox from 'components/LoadingBox/LoadingBox'
import DataGridFilters from 'components/DataGridFilters/DataGridFilters'
import PageHeader from 'components/PageHeader/PageHeader'

// CUSTOM COMPONENTS
import CustomDataGridPro from 'components/Customs/CustomDataGridPro'
import CustomMenu from 'components/Customs/CustomMenu'
import CustomMenuItem from 'components/Customs/CustomMenuItem'

// MUIS
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// SERVICES
import { 
  getSummaryList, 
  formatSummaryListOutput, 
  getCycleList,
  formatCycleListOutput,
} from 'services/routesServices'

// STYLES
import pageStyles from 'styles/layoutMainPageUseStyles'
import useStyles from './routesUseStyles'

// UTILS
import {
  getStartDateFromLocalStorage,
  getEndDateFromLocalStorage,
} from 'utils/localStorage'
import { 
  exportSummaryListToExcel,
  exportSummaryListAndCycleListToExcel, 
} from 'utils/routesUtil'

const Routes = () => {
  const pageClasses = pageStyles()
  const classes = useStyles()

  const navigate = useNavigate()

  const tabItemList = [
    {
      title: 'All Shifts',
      param: 'all',
    },
    {
      title: 'Day Shift',
      param: 'day',
    },
    {
      title: 'Night Shift',
      param: 'night',
    },
  ]

  const initialDateRangeValue = [ 
    getStartDateFromLocalStorage(), 
    getEndDateFromLocalStorage() 
  ]

  const downloadOptionList = [
    {
      title: 'Download Summary',
      param: 'summary',
    },
    {
      title: 'Download Raw',
      param: 'raw',
    },
  ]

  const initialColumns = [
    {
      field: 'deviceName',
      headerName: 'CN',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <Box 
          className={classes.columnCN}
          onClick={() => navigate(`/routes/deviceId=${params.id}/shift=${tabItemList[selectedTab].param}`)}
        >
          {/* ICON */}
          <IconRouteVehicle 
            color='info'
            className={classes.columnCNIcon}
            inheritViewBox={true}
          />

          {/* TEXT */}
          <Typography
            variant='subtitle2'
            className={classes.columnCNText}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'totalCycle',
      headerName: 'Cycle',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'averagePayload',
      headerName: 'Pld',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'averageEmptySpeed',
      headerName: 'ES',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'averageLoadedSpeed',
      headerName: 'LS',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'averageSpeed',
      headerName: 'Speed',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'averageLoadingTime',
      headerName: 'LT',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'averageLoadedDriveTime',
      headerName: 'LDT',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'averageLoadedStopTime',
      headerName: 'LST',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'averageEmptyDriveTime',
      headerName: 'EDT',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'averageEmptyStopTime',
      headerName: 'EST',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'averageCycleTime',
      headerName: 'CT',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'averageLoadedDriveDistance',
      headerName: 'LDD',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'averageEmptyDriveDistance',
      headerName: 'EDD',
      flex: 1,
      minWidth: 100,
    },
  ]

  const [ selectedTab, setSelectedTab ] = useState(0)
  const [ dateRangeValue, setDateRangeValue ] = useState(initialDateRangeValue)
  const [ downloadMenuAnchor, setDownloadMenuAnchor ] = useState(null)
  const [ search, setSearch ] = useState('')
  const [ tableData, setTableData ] = useState([])
  const [ selectionModel, setSelectionModel ] = useState([])
  const [ filteredTableData, setFilteredTableData ] = useState([])
  const [ isLoading, setIsLoading ] = useState(false)
  const [ selectedColumnList, setSelectedColumnList ] = useState(initialColumns)

  const handleDownloadMenuItemClick = async (inputItem, inputIndex) => {
    setIsLoading(true)
    if(inputItem.param === 'summary') {
      exportSummaryListToExcel(
        tableData, 
        dateRangeValue[0].getTime(), 
        dateRangeValue[1].getTime(),
        tabItemList[selectedTab].title,
      )
    }
    else if(inputItem.param === 'raw') {
      const resultCycleListData = await getCycleList(
        dateRangeValue[0].getTime(), 
        dateRangeValue[1].getTime(),
        'all', 
        tabItemList[selectedTab].param,
      )

      if(resultCycleListData.status === 200) {
        const formatedCycleList = formatCycleListOutput(resultCycleListData.data)
        exportSummaryListAndCycleListToExcel(
          tableData, 
          formatedCycleList,
          dateRangeValue[0].getTime(), 
          dateRangeValue[1].getTime(),
          tabItemList[selectedTab].title,
        )
      }
    }
    setDownloadMenuAnchor(null)
    setIsLoading(false)
  }

  const loadSummaryListData = async () => {
    setIsLoading(true)

    const resultSummaryList = await getSummaryList(
      dateRangeValue[0].getTime(),
      dateRangeValue[1].getTime(),
      tabItemList[selectedTab].param,
    )

    if(resultSummaryList.status === 200) {
      const formatedSummaryList = formatSummaryListOutput(resultSummaryList.data)
      setTableData(formatedSummaryList)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    loadSummaryListData()
  }, [dateRangeValue, selectedTab])

  useEffect(() => {
    if(search !== '') {
      const newTableData = tableData.filter(item => {
        if(
          item.deviceName.toLowerCase().includes(search.toLowerCase()) ||
          String(item.totalCycle).includes(search) ||
          String(item.averagePayload).includes(search) ||
          String(item.averageEmptySpeed).includes(search) ||
          String(item.averageLoadedSpeed).includes(search) ||
          String(item.averageSpeed).includes(search) ||
          item.averageLoadingTime.includes(search) ||
          item.averageLoadedDriveTime.includes(search) ||
          item.averageLoadedStopTime.includes(search) ||
          item.averageEmptyDriveTime.includes(search) ||
          item.averageEmptyStopTime.includes(search) ||
          item.averageCycleTime.includes(search) ||
          String(item.averageLoadedDriveDistance).includes(search) ||
          String(item.averageEmptyDriveDistance).includes(search)
        ) return item
      })
      setFilteredTableData(newTableData)
    }
  }, [search])

  return (
    <Box className={pageClasses.pageRoot}>
      {/* HEADER */}
      <PageHeader
        isAddButtonAvailable={false}
        onAddButtonIsClicked={null}
        isBackButtonAvailable={false}
        targetBackUrl={null}
        title='Routes'
        isSearchAvailable={true}
        search={search}
        setSearch={setSearch} 
        isExtraComponentAvailable={false}
        extraComponent={null}
      />

      {/* CONTENT */}
      <LoadingBox isLoading={isLoading}>
        {/* DATA GRID FILTERS */}
        <DataGridFilters
          isWithTabs={true}
          title={null}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabItemList={tabItemList}
          handleDownloadIconButtonClick={(event) => setDownloadMenuAnchor(event.currentTarget)}
          isWithDownload
          isWithCustomizeColumn={true}
          columns={initialColumns}
          selectedColumnList={selectedColumnList}
          setSelectedColumnList={setSelectedColumnList}
          isWithDateRange={true}
          dateRangeValue={dateRangeValue}
          setDateRangeValue={setDateRangeValue}
        />

        {/* DOWNLOAD MENU */}
        <CustomMenu
          anchorEl={downloadMenuAnchor}
          open={Boolean(downloadMenuAnchor)}
          onClose={() => setDownloadMenuAnchor(null)}
          className={classes.downloadMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {/* SITE OPTION LIST */}
          {downloadOptionList.map((item, index) => (
            <CustomMenuItem 
              key={index}
              onClick={() => handleDownloadMenuItemClick(item, index)}
            >
              {item.title}
            </CustomMenuItem>
          ))}
        </CustomMenu>

        {/* TABLE */}
        <CustomDataGridPro
          columns={selectedColumnList}
          rows={search === '' ? tableData : filteredTableData}
          selectionModel={selectionModel}
          setSelectionModel={setSelectionModel}
        />
      </LoadingBox>
    </Box>
  )
}

export default Routes