// CONSTANTS
import { headerHeight } from '../reportsConstants'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  headerRoot: {
    backgroundColor: '#F2F2F2'
  },
  headerContent: {
    height: headerHeight,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '12px 16px',
    position: 'relative'
  },
  headerAction: {
    position: 'absolute',
    left: 16,
    top: '50%',
    transform: 'translateY(-50%)',
    flexDirection: 'row',
    alignItems: 'center'
  },
  iconButtonAction: {
    height: 24,
    width: 24,
    backgroundColor: theme.palette.grey[300],
    borderRadius: 0,
    marginRight: 4,
  },
  headerTab: {
    borderRight: `1px solid ${theme.palette.common.white}`,
    backgroundColor: theme.palette.grey[300],
    padding: '8px 20px',
    textTransform: 'unset',
    '&.Mui-selected': {
      backgroundColor: theme.palette.common.white,
      borderBottomColor: theme.palette.common.white,
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    '&:last-child': {
      borderRight: 'none'
    },
  },
  headerTabs: {
    '& .MuiTabs-indicator': {
      display: 'none'
    },
    '& .MuiTabs-scrollButtons.Mui-disabled': {
      opacity: 0.3,
    },
  },
  iconButtonTabClose: {
    position: 'absolute',
    zIndex: 2,
    right: 4,
    top: 4,
    fontSize: 14,
    color: theme.palette.common.black
  }
}))

export default useStyles