// APIS
import axios from 'apis/axios'

// SERVICES
import { postRefreshToken } from './tokenServices'

export const getCycleList = async () => {
  const resultRefreshToken = await postRefreshToken()

  if(resultRefreshToken.status === 200) {
    try {
      const completeUrl = 'cycle-calculator/'

      const response = await axios.get(
        completeUrl,
        { 
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${resultRefreshToken.data.accessToken}`,
          },
        },
      )
  
      return response
    }
    catch (error) {
      if (!error.response) return { status: 'No Server Response' }
      else return error.response
    }
  }
}

export const postCreateCycle = async (bodyParameters) => {
  const resultRefreshToken = await postRefreshToken()

  if(resultRefreshToken.status === 200) {
    try {
      const response = await axios.post(
        '/cycle-calculator/create',
        JSON.stringify(bodyParameters),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${resultRefreshToken.data.accessToken}`,
          },
        }
      );
  
      return response
    }
    catch (error) {
      if (!error.response) return { status: 'No Server Response' }
      else return error.response
    }
  }
}

export const putEditCycle = async (cycleId, bodyParameters) => {
  const resultRefreshToken = await postRefreshToken()

  if(resultRefreshToken.status === 200) {
    try {
      const response = await axios.put(
        `/cycle-calculator/${cycleId}/update`,
        bodyParameters,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${resultRefreshToken.data.accessToken}`,
          },
        }
      );
  
      return response
    }
    catch (error) {
      if (!error.response) return { status: 'No Server Response' }
      else return error.response
    }
  }
}

export const postAssignDevicesToCycle = async (cycleId, bodyParameters) => {
  const resultRefreshToken = await postRefreshToken()

  if(resultRefreshToken.status === 200) {
    try {
      const response = await axios.post(
        `/cycle-calculator/${cycleId}/device`,
        bodyParameters,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${resultRefreshToken.data.accessToken}`,
          },
        }
      );
  
      return response
    }
    catch (error) {
      if (!error.response) return { status: 'No Server Response' }
      else return error.response
    }
  }
}

export const deleteCycle = async (id) => {
  const resultRefreshToken = await postRefreshToken()

  if(resultRefreshToken.status === 200) {
    try {
      const response = await axios.delete(
        `/cycle-calculator/${id}/delete`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${resultRefreshToken.data.accessToken}`,
          },
        }
      );
  
      return response
    }
    catch (error) {
      if (!error.response) return { status: 'No Server Response' }
      else return error.response
    }
  }
}