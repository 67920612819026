import { useRef, useState } from 'react'

// COMPONENTS
import Map from '../Map/Map'
import DialogMap from '../DialogMap/DialogMap'
import GeofenceList from 'components/GeofenceList/GeofenceList'

// LEAFLET
import L from 'leaflet'

// MUIS
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'

// MUI ICONS
import IconClose from '@mui/icons-material/Close'
import IconFullscreen from '@mui/icons-material/Fullscreen'

// STYLES
import useStyles from './flyoutMapUseStyles'

const FlyoutMap = (props) => {
  const { setCycleDetail, cycleDetail } = props

  const classes = useStyles()
  const geoJsonGeofenceListRef = useRef(L.featureGroup())

  const [ isDialogMapOpen, setIsDialogMapOpen ] = useState(false)
  const [ map, setMap ] = useState(null)
  
  return (
    <Stack
      height={312}
      position='relative'
    >
      {/* ICON CLOSE */}
      <IconButton 
        className={classes.iconClose}
        onClick={() => setCycleDetail(null)}
      >
        <IconClose/>
      </IconButton>

      {/* MAP */}
      <Map setMap={setMap}>
        {Boolean(cycleDetail) && <GeofenceList
          map={map}
          selectedGeofenceList={[...cycleDetail?.haulList, ...cycleDetail?.pitList, ...cycleDetail?.portList]}
          hasGeofenceListFinishedLoading={!Boolean(cycleDetail)}
          geoJsonGeofenceListRef={geoJsonGeofenceListRef}
        />}
      </Map>

      {/* ICON FULL SCREEN */}
      <ButtonGroup 
        className={classes.fullScreenButtonGroup}
        variant='contained'
      >
        <Button 
          className={classes.fullScreenButton}
          variant='text'
          onClick={() => setIsDialogMapOpen(true)}
        >
          <IconFullscreen color='action'/>
        </Button>
      </ButtonGroup>      

      {/* DIALOG MAP */}
      <DialogMap
        isDialogMapOpen={isDialogMapOpen} 
        setIsDialogMapOpen={setIsDialogMapOpen}
        cycleDetail={cycleDetail}
      />
    </Stack>
  )
}

export default FlyoutMap