import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

// CUSTOM COMPONENTS
import PageSearchField from 'components/PageSearchField/PageSearchField'

// MUI
import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

// MATERIAL UI ICONS
import IconAdd from '@mui/icons-material/Add'
import IconArrowBack from '@mui/icons-material/ArrowBack'

// STYLES
import useStyles from './pageHeaderUseStyles'

const PageHeader = (props) => {
  const { 
    isAddButtonAvailable,
    onAddButtonIsClicked,
    isBackButtonAvailable,
    targetBackUrl,
    title,
    isSearchAvailable,
    search,
    setSearch, 
    isExtraComponentAvailable,
    extraComponent,
    isAddButtonDisabled,
  } = props
  
  const classes = useStyles()

  const navigate = useNavigate()

  return (
    <Box className={classes.headerRoot}>
      {/* FAB */}
      {isAddButtonAvailable &&
      <Fab 
        color='primary'
        className={classes.addIconButton}
        onClick={onAddButtonIsClicked}
        disabled={isAddButtonDisabled}
      >
        <IconAdd/>
      </Fab>}

      {/* BACK ICON */}
      {isBackButtonAvailable &&
      <IconButton 
        onClick={() => navigate(targetBackUrl)}
        className={classes.backButton}
      >
        <IconArrowBack/>
      </IconButton>}

      {/* TITLE */}
      <Typography
        variant='h6'
        className={classes.title}
      >
        {title}
      </Typography>

      {/* SEARCH */}
      {isSearchAvailable &&
      <PageSearchField
        value={search}
        onChange={(event) => setSearch(event.target.value)}
      />}

      {/* EXTRA COMPONENT */}
      {isExtraComponentAvailable && extraComponent}
    </Box>
  )
}

PageHeader.defaultProps = {
  isAddButtonAvailable: false,
  isAddButtonDisabled: false,
  title: '',
  isBackButtonAvailable: false,
  targetBackUrl: '',
  search: '',
  isExtraComponentAvailable: true,
  extraComponent: null,
}

PageHeader.propTypes = {
  isAddButtonAvailable: PropTypes.bool.isRequired,
  isAddButtonDisabled: PropTypes.bool.isRequired,
  onAddButtonIsClicked: PropTypes.func, 
  isBackButtonAvailable: PropTypes.bool.isRequired,
  targetBackUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
  search: PropTypes.string,
  setSearch: PropTypes.func, 
  isExtraComponentAvailable: PropTypes.bool.isRequired,
  extraComponent: PropTypes.node,
}

export default PageHeader
