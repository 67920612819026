import React, { useState, useEffect } from 'react'

// COMPONENTS
import LoadingBox from 'components/LoadingBox/LoadingBox'
import DataGridFilters from 'components/DataGridFilters/DataGridFilters'
import PageHeader from 'components/PageHeader/PageHeader'

// CUSTOM COMPONENTS
import CustomDataGridPro from 'components/Customs/CustomDataGridPro'

// MUIS
import Box from '@mui/material/Box'

// SERVICES
import { getCanbusList } from 'services/canbusServices'

// STYLES
import pageStyles from 'styles/layoutMainPageUseStyles'

// UTILS
import {
  getStartDateFromLocalStorage,
  getEndDateFromLocalStorage,
} from 'utils/localStorage'
import { exportCanbusListToExcel } from 'utils/canbusUtil'
import { 
  convertTimeStampToDateAndTimeGMT7, 
  convertValueTo1DecimalPoint,
  convertDateToIsoStringDate,
} from 'utils/date'

const Canbus = () => {
  const pageClasses = pageStyles()

  const initialDateRangeValue = [ 
    getStartDateFromLocalStorage(), 
    getEndDateFromLocalStorage() 
  ]

  const initialColumns = [
    {
      field: 'deviceName',
      headerName: 'DT Number',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'begin',
      headerName: 'Date',
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => convertTimeStampToDateAndTimeGMT7(params.value),
    },
    {
      field: 'fuelConsumedHourly',
      headerName: 'Fuel Consumed (L/h)',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'engineHoursHourly',
      headerName: 'HM',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'engineHourStartHourly',
      headerName: 'HM Start',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'engineHourEndHourly',
      headerName: 'HM End',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'mileageStartHourly',
      headerName: 'ODO Start (Km)',
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => convertValueTo1DecimalPoint(params.value),
    },
    {
      field: 'mileageEndHourly',
      headerName: 'ODO End (Km)',
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => convertValueTo1DecimalPoint(params.value),
    },
    {
      field: 'engineLoadAvgHourly',
      headerName: 'Engine Load Avg',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'engineLoadMaxHourly',
      headerName: 'Engine Load Max',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'engineLoadMinHourly',
      headerName: 'Engine Load Min',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'engineTemperatureAvgHourly',
      headerName: 'Temperature Avg',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'engineTemperatureMaxHourly',
      headerName: 'Temperature Max',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'engineTemperatureMinHourly',
      headerName: 'Temperature Min',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'engineRpmAvgHourly',
      headerName: 'RPM Avg',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'engineRpmMaxHourly',
      headerName: 'RPM Max',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'engineRpmMinHourly',
      headerName: 'RPM Min',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'pedalLevelAvgHourly',
      headerName: 'Throttle Pedal Level Avg',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'pedalLevelMaxHourly',
      headerName: 'Throttle Pedal Level Max',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'pedalLevelMinHourly',
      headerName: 'Throttle Pedal Level Min',
      flex: 1,
      minWidth: 150,
    },
  ]

  const [ search, setSearch ] = useState('')
  const [ isLoading, setIsLoading ] = useState(false)
  const [ selectedColumnList, setSelectedColumnList ] = useState(initialColumns)
  const [ dateRangeValue, setDateRangeValue ] = useState(initialDateRangeValue)
  const [ tableData, setTableData ] = useState([])
  const [ selectionModel, setSelectionModel ] = useState([])
  const [ filteredTableData, setFilteredTableData ] = useState([])

  const handleDownloadIconButtonClick = () => {
    setIsLoading(true)
    exportCanbusListToExcel(
      tableData,
      dateRangeValue[0].getTime(), 
      dateRangeValue[1].getTime(),
    )
    setIsLoading(false)
  }

  const loadCanbusListData = async () => {
    setIsLoading(true)

    const resultCanbusList = await getCanbusList(
      convertDateToIsoStringDate(dateRangeValue[0]),
      convertDateToIsoStringDate(dateRangeValue[1])
    )

    if(resultCanbusList.status === 200) {
      setTableData(resultCanbusList.data.content)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    loadCanbusListData()
  }, [dateRangeValue])

  useEffect(() => {
    if(search !== '') {
      const newTableData = tableData.filter(item => item.deviceName.toLowerCase().includes(search.toLowerCase()))
      setFilteredTableData(newTableData)
    }
  }, [search])

  return (
    <Box className={pageClasses.pageRoot}>
      {/* PAGE HEADER */}
      <PageHeader
        isAddButtonAvailable={false}
        onAddButtonIsClicked={null}
        isBackButtonAvailable={false}
        targetBackUrl={null}
        title='Canbus'
        isSearchAvailable={true}
        search={search}
        setSearch={setSearch}
        isExtraComponentAvailable={false}
        extraComponent={null}
      />

      {/* CONTENT */}
      <LoadingBox isLoading={isLoading}>
        {/* DATA GRID FILTERS */}
        <DataGridFilters
          isWithTabs={false}
          title='Canbus'
          selectedTab={null}
          setSelectedTab={null}
          tabItemList={null}
          handleDownloadIconButtonClick={handleDownloadIconButtonClick}
          isWithDownload
          isWithCustomizeColumn={true}
          columns={initialColumns}
          selectedColumnList={selectedColumnList}
          setSelectedColumnList={setSelectedColumnList}
          isWithDateRange={true}
          dateRangeValue={dateRangeValue}
          setDateRangeValue={setDateRangeValue}
        />

        {/* TABLE */}
        <CustomDataGridPro
          columns={selectedColumnList}
          rows={search === '' ? tableData : filteredTableData}
          selectionModel={selectionModel}
          setSelectionModel={setSelectionModel}
        />
      </LoadingBox>
    </Box>
  )
}

export default Canbus