// MUIS
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { colors } from 'constants/colors'

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    '& .MuiPaper-root': {
      borderRadius: 0,
      width: 332,
    },
  },
  dialogTitle: {
    padding: '20px 24px 0 24px',
  },
  dialogSubtitle: {
    fontWeight: 400,
    padding: '4px 24px 0 24px',

  },
  dialogContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 14px',
  },
  boxIcon: {
    width: 56,
    height: 56,
    border: `1px solid ${theme.palette.divider}`,
    cursor: 'pointer',
    margin: '8px 10px',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    '&.selected': {
      borderColor: theme.palette.primary.main,
    }
  },
  iconVehicle: {
    fontSize: 40,
    color: colors.icon
  },
  cancelButton: {
    color: colors.icon,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: alpha(colors.icon, 0.1),
    },
  },
  saveButton: {
    textTransform: 'capitalize'
  },
  dialogActions: {
    padding: 16
  }
}))

export default useStyles