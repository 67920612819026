import React, { useState, useEffect } from 'react'

// CUSTOM COMPONENTS
import CustomInput from 'components/Customs/CustomInput'
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// MUIS
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ListItemButton from '@mui/material/ListItemButton'
import Slide from '@mui/material/Slide'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconClear from '@mui/icons-material/Clear'
import IconMenuOpen from '@mui/icons-material/MenuOpen'
import IconSearch from '@mui/icons-material/Search'

// STYLES
import useStyles from './panelDeviceUseStyles'

const PanelDevice = (props) => {
  const { 
    deviceList, 
    setDeviceList,
    pageRef,
    isDevicePanelShown, 
    setIsDevicePanelShown,
    setMapBounds,
  } = props

  const classes = useStyles()

  const [ isAllChecked, setIsAllChecked ] = useState(false)
  const [ search, setSearch ] = useState('')
  const [selectedDevices, setSelectedDevices] = useState([])

  const handleListItemButtonClick = (inputEvent, inputItem, inputIndex) => {
    inputEvent.stopPropagation()

    let newDeviceList = [...deviceList.map(item => {
      return {
        ...item,
        isSelected: false,
      }
    })]

    newDeviceList[inputIndex].isSelected = true
    setDeviceList(newDeviceList)
    setSelectedDevices(newDeviceList.filter(item => item.isSelected))
  }

  const handleListItemCheckboxClick = (inputEvent, inputItem, inputIndex) => {
    inputEvent.stopPropagation()

    let newDeviceList = [...deviceList]

    newDeviceList[inputIndex].isSelected = !newDeviceList[inputIndex].isSelected
    setDeviceList(newDeviceList)
    setSelectedDevices(newDeviceList.filter(item => item.isSelected))
  }

  const handleCheckboxAllClick = () => {
    let newDeviceList = []
    if(isAllChecked) {
      newDeviceList = [...deviceList.map(item => {
        return {
          ...item,
          isSelected: false,
        }
      })]
    }
    else {
      newDeviceList = [...deviceList.map(item => {
        return {
          ...item,
          isSelected: true,
        }
      })]
    }
    setDeviceList(newDeviceList)
    setSelectedDevices(newDeviceList.filter(item => item.isSelected))
  }

  const updateCheckboxAllStatus = () => {
    const checkedList = deviceList.map(item => item.isSelected)
    const isThereAFalse = checkedList.includes(false)
    
    if(isThereAFalse) setIsAllChecked(false)
    else setIsAllChecked(true)
  }

  const updateMapBound = () => {
    let newMapBoundList = []

    if(selectedDevices.length) {
      selectedDevices.forEach(item => {
        const latitude = item.state.gps.location.lat
        const longitude = item.state.gps.location.lng

        if (latitude && longitude && item.isSelected) {
          newMapBoundList.push([ parseFloat(latitude), parseFloat(longitude) ])
        }
      })
    } else {
      deviceList.forEach(item => {
        const latitude = item.state.gps.location.lat
        const longitude = item.state.gps.location.lng

        if (latitude && longitude) {
          newMapBoundList.push([ parseFloat(latitude), parseFloat(longitude) ])
        }
      })
    }
    

    if (newMapBoundList.length > 0) setMapBounds(newMapBoundList)
  }

  useEffect(() => {
    updateCheckboxAllStatus()
  }, [deviceList])

  useEffect(() => {
    updateMapBound()
  }, [selectedDevices])

  useEffect(() => {
    let newDeviceList = [...deviceList]

    newDeviceList.forEach(item => {
      if(item.label.toLowerCase().includes(search.toLowerCase())) item.isShown = true
      else item.isShown = false
    })

    setDeviceList(newDeviceList)
  }, [search])

  return (
    <Slide 
      direction='right' 
      in={isDevicePanelShown} 
      container={pageRef.current}
    >
      <Box className={classes.panelContainer}>
        {/* HEADER */}
        <Box className={classes.panelHeader}>
          {/* MINIMIZE ICON */}
          <IconButton 
            className={classes.iconToggle}
            onClick={() => setIsDevicePanelShown(false)}
          >
            <IconMenuOpen/>
          </IconButton>

          {/* PANEL TITLE */}
          <Typography
            variant='h6'
            className={classes.panelTitle}
          >
            Devices
          </Typography>
        </Box>

        <Divider/>

        {/* SEARCH BOX */}
        <Box className={classes.panelHeader}>
          {/* ALL CHECKBOX */}
          <CustomTooltipBlack
            title='Select All'
            placement='bottom'
          >
            <Checkbox
              checked={isAllChecked}
              onClick={() => handleCheckboxAllClick()}
              className={classes.checkboxAll}
            />
          </CustomTooltipBlack>

          {/* INPUT */}
          <CustomInput
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            className={classes.searchInput}
            placeholder='Quick Search'
            disableUnderline
          />

          {/* ICON */}
          {search === '' ?
          <IconSearch className={classes.searchIcon}/> :
          <IconClear
            className={classes.searchIcon}
            onClick={() => setSearch('')}
          />}
        </Box>

        <Divider/>

        {/* DEVICE LIST */}
        <Box className={classes.deviceList}>
          {deviceList.map((item, index) => (
            item.isShown &&
            <ListItemButton
              key={index}
              className={classes.deviceItem}
              onClick={(event) => handleListItemButtonClick(event, item, index)}
            >
              {/* CHECKBOX */}
              <Checkbox
                checked={item.isSelected}
                onClick={(event) => handleListItemCheckboxClick(event, item, index)}
                className={classes.deviceItemCheckbox}
              />

              {/* DEVICE NAME */}
              <Typography
                variant='body2'
                className={classes.deviceItemName}
              >
                {item.label}
              </Typography>
            </ListItemButton>
          ))}
        </Box>
      </Box>
    </Slide>
  )
}

export default PanelDevice