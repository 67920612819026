// UTILITIES
import { convertTimeStampToDateAndTimeGMT8 } from 'utils/date'

export const columnsCanbus = [
  {
    field: 'dateTime',
    headerName: 'Date and Time',
    flex: 1,
    valueGetter: (params) => convertTimeStampToDateAndTimeGMT8(params.value),
    minWidth: 140,
  },
  {
    field: 'fuelConsumed',
    headerName: 'Fuel Consumed (L)',
    flex: 1,
    minWidth: 140,
  },
  {
    field: 'hm',
    headerName: 'HM (Hour)',
    flex: 1,
    minWidth: 80,
  },
  {
    field: 'km',
    headerName: 'ODO (Km)',
    flex: 1,
    minWidth: 80,
  },
]

export const columnsTrip = [
  {
    field: 'start',
    headerName: 'Start',
    flex: 1,
    valueGetter: (params) => convertTimeStampToDateAndTimeGMT8(params.value),
    minWidth: 140,
  },
  {
    field: 'duration',
    headerName: 'Duration',
    flex: 1,
    minWidth: 80,
  },
  {
    field: 'zone',
    headerName: 'Zone',
    flex: 1,
    minWidth: 80,
  },
  {
    field: 'km',
    headerName: 'Km',
    flex: 1,
    minWidth: 80,
  },
  {
    field: 'maxSpeed',
    headerName: 'Speed Max',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'avgSpeed',
    headerName: 'Speed Avg',
    flex: 1,
    minWidth: 80,
  },
  {
    field: 'fuelConsumed',
    headerName: 'Fuel Consumed (L)',
    flex: 1,
    minWidth: 140,
  },
]

export const columnsSpeed = [
  {
    field: 'dateTime',
    headerName: 'Date and Time',
    flex: 1,
    valueGetter: (params) => convertTimeStampToDateAndTimeGMT8(params.value),
    minWidth: 140,
  },
  {
    field: 'speed',
    headerName: 'Speed',
    flex: 1,
    minWidth: 80,
  },
  {
    field: 'lon',
    headerName: 'Longitude',
    flex: 1,
    minWidth: 80,
  },
  {
    field: 'lat',
    headerName: 'Latitude',
    flex: 1,
    minWidth: 80,
  },
]

export const headerHeight = 48