// MUIS
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  chipIcon: {
    '&.MuiChip-icon': {
      marginLeft: 4,
    },
  },
}))

export default useStyles