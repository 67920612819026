// MUI STYLES
import { makeStyles } from '@mui/styles'
import { colors } from 'constants/colors'

const useStyles = makeStyles((theme) => ({
  columnVehicleImage: {
    width: 28,
    height: 28,
    borderRadius: 2,
    border: `1px solid ${theme.palette.divider}`
  },
  columnVehicle: {
    width: '100%',
  },
  iconVehicle: {
    color: colors.icon
  }
}))

export default useStyles