import React, { useState, useEffect, useRef } from 'react'
import ReactDOMServer from 'react-dom/server'

// COMPONENTS
import CustomMarker from './CustomMarker/CustomMarker'
import PanelDevice from './PanelDevice/PanelDevice'
import PanelDeviceDetail from './PanelDeviceDetail/PanelDeviceDetail'
import PanelDeviceToggle from './PanelDeviceToggle/PanelDeviceToggle'
import PanelChangeMap from '../../components/PanelChangeMap/PanelChangeMap'
import PanelZoom from '../../components/PanelZoom/PanelZoom'

// CONSTANTS
import { values } from 'constants/values'
import customTheme from 'constants/customTheme'
import mapOptionList from 'constants/mapOptionList'

// MUIS
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import { ThemeProvider } from '@mui/material/styles'

// LEAFLET
import { 
  MapContainer, 
  TileLayer,
} from 'react-leaflet'
import L from 'leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'

// SERVICES
import { 
  getTrackerList,
  getDeviceStateList, 
} from 'services/trackerServices'

// SUPERCLUSTER
// import Supercluster from 'supercluster'

// STYLES
import useStyles from './trackingUseStyles'
import 'leaflet/dist/leaflet.css'
import MapChildrenZoom from 'components/PanelZoom/MapChildrenZoom'

const customCluster = (cluster) => {
  return L.divIcon({
    className: 'marker-cluster-custom',
    html: ReactDOMServer.renderToString(
      <ThemeProvider theme={customTheme}>
        <Avatar
          sx={(theme) => ({
            '&.MuiAvatar-root': {
              width: 40,
              height: 40,
              fontSize: 14,
              backgroundColor: theme.palette.primary.main,
            },
          })}
        >
          {cluster.getChildCount()}
        </Avatar>
      </ThemeProvider>
    ),
  })
}

const Tracking = () => {
  const classes = useStyles()

  const pageRef = useRef()

  const minimalZoom = 2
  const maximalZoom = 20

  const [ selectedMapObject, setSelectedMapObject ] = useState(mapOptionList[0])
  const [ map, setMap ] = useState(null)
  const [ zoom, setZoom ] = useState(5) 
  const [ boundsBox, setBoundsBox ] = useState([])
  const [ mapBounds, setMapBounds ] = useState(null)
  // const [ clusterList, setClusterList ] = useState(null)
  const [ deviceList, setDeviceList ] = useState([])
  const [ isDevicePanelShown, setIsDevicePanelShown ] = useState(true)
  const [ panelDeviceDetail, setPanelDeviceDetail ] = useState(null)

  const integrateData = (inputTrackerList, inputStateList) => {
    let integratedList = inputTrackerList.map(trackerItem => {
      const stateObject = inputStateList.find(stateItem => {
        if (stateItem.states[Object.keys(stateItem.states)].source_id === trackerItem.source.id) return stateItem
      })

      return stateObject ? {
        ...trackerItem,
        isSelected: false,
        isShown: true,
        state: { ...stateObject.states[Object.keys(stateObject.states)] },
      } : null
    })

    integratedList = integratedList.filter(item => item)
    return integratedList
  }

  const loadDataFirstTime = async () => {
    const resultTrackerList = await getTrackerList()
    const resultStateList = await getDeviceStateList()
    
    setDeviceList(integrateData(resultTrackerList.data, resultStateList.data))
  }

  const updateDeviceList = async () => {
    const resultStateList = await getDeviceStateList()

    const newDeviceList = deviceList.map(objectItem => {
      const stateObject = resultStateList.data.find(stateItem => {
        if (stateItem.states[Object.keys(stateItem.states)].source_id === objectItem.source.id) return stateItem
      })

      return stateObject ? {
        ...objectItem,
        state: {
          ...stateObject.states[Object.keys(stateObject.states)],
        },
      } : null
    })

    setDeviceList(newDeviceList)
  }

  useEffect(() => {
    loadDataFirstTime()
  }, [])

  useEffect(() => {
    const updateData = setTimeout(() => {
      updateDeviceList()
    }, values.apiRequestInterval * 1000)

    return () => clearTimeout(updateData)
  }, [deviceList])

  useEffect(() => {
    mapBounds && map.fitBounds(mapBounds)
  }, [mapBounds])

  // useEffect(() => {
  //   console.log('udpate cluster')

  //   const superclusterObject = new Supercluster({
  //     radius: 75,
  //     maxZoom: maximalZoom,
  //   })

  //   const points = deviceList.map((item, index) => ({
  //     type: 'Feature',
  //     properties: { cluster: false },
  //     geometry: {
  //       type: 'Point',
  //       coordinates: [ item.source.lastX, item.source.lastY ],
  //     },
  //   }))

  //   superclusterObject.load(points)
  //   const newClusterList = superclusterObject.getClusters(boundsBox, zoom)
  //   setClusterList(newClusterList)
  // }, [boundsBox, zoom])
  
  // console.log('boundsBox: ', boundsBox)
  // console.log('clusterList: ', clusterList)

  return (
    <Box
      className={classes.pageRoot}
      ref={pageRef}
    >
      {/* MAP */}
      <MapContainer 
        center={[ -0.7893, 113.9213 ]} 
        className={classes.mapContainer}
        zoomControl={false}
        zoom={zoom}
        whenCreated={(mapObject) => setMap(mapObject)}
      >
        {/* BASE MAP */}
        <TileLayer
          // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url={selectedMapObject.url}
          subdomains={['mt1','mt2','mt3']}
          minZoom={minimalZoom}
          maxZoom={maximalZoom}
          key={selectedMapObject.name}
        />

        {/* MARKERS */}
        <MarkerClusterGroup iconCreateFunction={customCluster}>
          {deviceList.map((item, index) => (
            item.state.gps.location.lng && item.state.gps.location.lat &&
            <CustomMarker
              key={index}
              item={item}
              setPanelDeviceDetail={setPanelDeviceDetail}
            />
          ))}
        </MarkerClusterGroup>

        {/* MAP CHILDREN ZOOM */}
        <MapChildrenZoom 
          zoom={zoom}
          setZoom={setZoom}
        />
      </MapContainer>

      {/* DEVICE PANEL TOGGLE */}
      <PanelDeviceToggle
        isDevicePanelShown={isDevicePanelShown}
        setIsDevicePanelShown={setIsDevicePanelShown}
        pageRef={pageRef}
      />

      {/* DEVICE PANEL */}
      <PanelDevice 
        deviceList={deviceList}
        setDeviceList={setDeviceList}
        pageRef={pageRef}
        isDevicePanelShown={isDevicePanelShown}
        setIsDevicePanelShown={setIsDevicePanelShown}
        setMapBounds={setMapBounds}
      />

      {/* DEVICE DETAIL PANEL */}
      {panelDeviceDetail &&
      <PanelDeviceDetail
        panelDeviceDetail={panelDeviceDetail}
        setPanelDeviceDetail={setPanelDeviceDetail}
        isDevicePanelShown={isDevicePanelShown}
      />}

      {/* MENU PANEL */}
      <PanelChangeMap
        mapOptionList={mapOptionList}
        selectedMapObject={selectedMapObject}
        setSelectedMapObject={setSelectedMapObject}
      />

      {/* ZOOM PANEL */}
      <PanelZoom
        zoom={zoom}
        setZoom={setZoom}
      />
    </Box>
  )
}

export default Tracking