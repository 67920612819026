// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconVehicleFive = (props) => {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M2.25776 12.1042L3.58798 13.4474H2.23433C2.10545 13.4474 2 13.5553 2 13.6873V15.6541C2 16.2897 2.48037 16.8053 3.08963 16.8293C3.2068 15.6421 4.17926 14.7186 5.36262 14.7186C6.5577 14.7186 7.53017 15.6421 7.64733 16.8293H7.88166C7.99883 15.6421 8.9713 14.7186 10.1664 14.7186C11.3497 14.7186 12.3222 15.6421 12.4394 16.8293H16.1418C16.2472 15.6421 17.2314 14.7186 18.4148 14.7186C19.5981 14.7186 20.5823 15.6421 20.6878 16.8293H20.8401C21.4728 16.8293 21.9883 16.3017 22 15.6541V12.5959H18.5905C17.8758 12.5959 17.3017 12.0083 17.3017 11.2767V8.1107H15.9309C15.802 8.1107 15.6965 8.21863 15.6965 8.35055V13.4474H14.8881V7.54705H15.6145C16.6104 7.54705 16.6104 6 15.6145 6H13.9508C13.4236 6 13.0018 6.43173 13.0018 6.9714V8.13469H2.42179C2.29291 8.13469 2.18746 8.24262 2.18746 8.37454V11.9364C2.18746 11.9963 2.2109 12.0683 2.25776 12.1042ZM5.06971 12.6679C4.94083 12.6679 4.83538 12.56 4.83538 12.428V9.80166C4.83538 9.48985 5.30404 9.48985 5.30404 9.80166V12.428C5.30404 12.56 5.19859 12.6679 5.06971 12.6679ZM7.5536 12.6679C7.42472 12.6679 7.31927 12.56 7.31927 12.428V9.80166C7.31927 9.48985 7.78793 9.48985 7.78793 9.80166V12.428C7.78793 12.56 7.68248 12.6679 7.5536 12.6679ZM10.0258 12.6679C9.8969 12.6679 9.79145 12.56 9.79145 12.428V9.80166C9.79145 9.48985 10.2601 9.48985 10.2601 9.80166V12.428C10.2601 12.56 10.1547 12.6679 10.0258 12.6679ZM12.5097 12.6679C12.3808 12.6679 12.2753 12.56 12.2753 12.428V9.80166C12.2753 9.48985 12.744 9.48985 12.744 9.80166V12.428C12.744 12.56 12.6385 12.6679 12.5097 12.6679ZM17.7958 13.6819C17.88 13.6786 17.9664 13.6753 18.0516 13.6753C18.3562 13.6753 18.3562 13.1956 18.0516 13.1956C17.9664 13.1956 17.88 13.1922 17.7958 13.189C17.4283 13.1748 17.1025 13.1622 17.1025 13.4354C17.1025 13.7086 17.4283 13.696 17.7958 13.6819Z"/>
      <path d="M19.1529 8.1107H17.7704V11.2767C17.7704 11.7445 18.1336 12.1162 18.5905 12.1162H22V11.0249C22 9.41789 20.7229 8.1107 19.1529 8.1107Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.26831 17.0572C8.26831 18.1245 9.11189 19 10.1664 19C11.2091 19 12.0527 18.1245 12.0527 17.0572C12.0527 15.9898 11.2091 15.1144 10.1664 15.1144C9.11189 15.1144 8.26831 15.9898 8.26831 17.0572ZM10.1664 18.0166C8.93615 18.0166 8.93615 16.0978 10.1664 16.0978C11.3849 16.0978 11.3849 18.0166 10.1664 18.0166Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M5.36262 19C4.31986 19 3.47627 18.1245 3.47627 17.0572C3.47627 15.9898 4.31986 15.1144 5.36262 15.1144C6.41711 15.1144 7.26069 15.9898 7.26069 17.0572C7.26069 18.1245 6.41711 19 5.36262 19ZM5.36262 18.0166C4.14411 18.0166 4.14411 16.0978 5.36262 16.0978C6.59285 16.0978 6.59285 18.0166 5.36262 18.0166Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M16.5167 17.0572C16.5167 18.1245 17.372 19 18.4148 19C19.4575 19 20.3128 18.1245 20.3128 17.0572C20.3128 15.9898 19.4575 15.1144 18.4148 15.1144C17.372 15.1144 16.5167 15.9898 16.5167 17.0572ZM18.4148 18.0166C17.1845 18.0166 17.1845 16.0978 18.4148 16.0978C19.645 16.0978 19.645 18.0166 18.4148 18.0166Z" />
    </SvgIcon>
  )
}

export default IconVehicleFive