// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconVehicleEight = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M13.0916 2H17V4.05L18.8832 5.3084C20.8332 6.6084 22 8.7916 22 11.1668V18.6668H19.5C19.5 20.5084 18.0084 22 16.1668 22C14.3252 22 12.8336 20.5084 12.8336 18.6668H10.3336C10.3336 20.5084 8.842 22 7.0004 22C5.1588 22 3.6672 20.5084 3.6672 18.6668H2V8.6668H10.5916L13.0916 2ZM20.2668 10.3332C20.042 8.858 19.2168 7.5332 17.9584 6.7L17 6.0584V10.3336L20.2668 10.3332ZM16.1668 20.3332C17.0836 20.3332 17.8336 19.5832 17.8336 18.6664C17.8336 17.7496 17.0836 16.9996 16.1668 16.9996C15.25 16.9996 14.5 17.7496 14.5 18.6664C14.5 19.5832 15.25 20.3332 16.1668 20.3332ZM7 17C6.0832 17 5.3332 17.75 5.3332 18.6668C5.3332 19.5836 6.0832 20.3336 7 20.3336C7.9168 20.3336 8.6668 19.5836 8.6668 18.6668C8.6668 17.75 7.9168 17 7 17Z" />
    </SvgIcon>
  )
}

export default IconVehicleEight