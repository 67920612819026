// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  iconNoReport: {
    fontSize: 80,
    color: theme.palette.action.active,
    marginBottom: 24
  },
  createReportBackdrop: {
    zIndex: theme.zIndex.drawer + 1, 
    position: 'absolute',
    backgroundColor: theme.palette.action.disabledBackground,
    justifyContent: 'flex-start',
  },
}))

export default useStyles