import { useState, useEffect, useContext } from 'react'

// COMPONENTS
import DialogAddOrEdit from 'components/DialogAddOrEdit/DialogAddOrEdit'
import FlyoutChipList from '../FlyoutChipList/FlyoutChipList'
import FlyoutMap from '../FlyoutMap/FlyoutMap'
import FlyoutMenuManageDevices from '../FlyoutMenuManageDevices/FlyoutMenuManageDevices'

// CONTEXTS
import { MainLayoutContext } from 'contexts/MainLayoutContext'

// MUIS
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconExpandLess from '@mui/icons-material/ExpandLess'
import IconExpandMore from '@mui/icons-material/ExpandMore'

// SERVICES
import { postAssignDevicesToCycle } from 'services/cycleServices'

// STYLES
import useStyles from './flyoutUseStyles'

// UTILITIES
import { convertDateToUserTimezone } from 'utils/date'

const Flyout = (props) => {
  const { 
    cycleDetail, setCycleDetail, 
    pageRef,
    setShouldReloadData,
  } = props

  const classes = useStyles()

  const { changeSnackbarObject } = useContext(MainLayoutContext)

  const [ isCycleOpen, setIsCycleOpen ] = useState(true)
  const [ devicesMenuAnchor, setDevicesMenuAnchor ] = useState(null)
  const [ shouldAssignDevices, setShouldAssignDevices ] = useState(false)
  const [ deviceList, setDeviceList ] = useState([])

  const handleAssignDevicesToCycle = async () => {
    const selectedDeviceIdList = deviceList.filter(item => item.isSelected).map(item => item.id)

    const resultAssignDevices = await postAssignDevicesToCycle(cycleDetail.id, { deviceObjectId: selectedDeviceIdList })

    if (resultAssignDevices.status === 200) {
      setCycleDetail(current => {
        return {
          ...current,
          deviceList: resultAssignDevices.data.deviceList,
        }
      })

      changeSnackbarObject({
        open: true,
        severity: 'success',
        message: 'Success assigning devices',
      })
    }
    else changeSnackbarObject({
      open: true,
      severity: 'error',
      message: resultAssignDevices.data.message,
    })

    setShouldAssignDevices(false)
    setShouldReloadData(true)
  }

  const integrateInitialDeviceList = () => {
    const initialDeviceIdList = cycleDetail?.deviceList?.map(item => item.id)

    setDeviceList(current => {
      const newDeviceList = current.map(currentItem => {
        return {
          ...currentItem,
          isSelected: initialDeviceIdList?.length > 0 
            ? initialDeviceIdList?.includes(currentItem.id) ? true : false
            : false,
        }
      })

      return newDeviceList
    })
  }

  useEffect(() => {
    integrateInitialDeviceList()
  }, [cycleDetail])

  useEffect(() => {
    if (!Boolean(devicesMenuAnchor) && shouldAssignDevices) handleAssignDevicesToCycle()
  },[devicesMenuAnchor, shouldAssignDevices])

  return (
    <DialogAddOrEdit
      open={Boolean(cycleDetail)}
      containerRef={pageRef}
      direction='left'
      position='right'
    >
      {/* MAP */}
      <FlyoutMap setCycleDetail={setCycleDetail} cycleDetail={cycleDetail} />

      {/* CONTENT */}
      <Stack padding='24px'>
        {/* CYCLE HEADER */}
        <ListItemButton 
          className={classes.cycleTitle}
          onClick={() => setIsCycleOpen(current => !current)}
        >
          {/* TEXTS */}
          <ListItemText 
            primary={cycleDetail?.calculatorName}
            secondary={cycleDetail?.id}
          />

          {/* ICON EXPAND/COLLAPSE */}
          {isCycleOpen ? <IconExpandLess/> : <IconExpandMore/>}
        </ListItemButton>
        
        {/* CYCLE CONTENT */}
        <Collapse 
          in={isCycleOpen} 
          timeout='auto' 
          unmountOnExit
        >
          <Stack>
            {/* PITS */}
            <FlyoutChipList
              title='PITS'
              list={cycleDetail?.pitList}
              marginTop='12px'
            />

            {/* HAUL */}
            <FlyoutChipList
              title='HAUL'
              list={cycleDetail?.haulList}
              marginTop='12px'
            />
            
            {/* PORTS */}
            <FlyoutChipList
              title='PORTS'
              list={cycleDetail?.portList}
              marginTop='12px'
            />

            {/* CREATED TIME */}
            <Typography
              variant='caption'
              marginTop='20px'
              color='text.secondary'
            >
              Created at {convertDateToUserTimezone(cycleDetail?.createdAt)}
            </Typography>

            {/* LAST UPDATED TIME */}
            <Typography
              variant='caption'
              color='text.secondary'
            >
              Last updated at {convertDateToUserTimezone(cycleDetail?.updatedAt)}
            </Typography>
          </Stack>
        </Collapse>

        <Divider className={classes.cycleDivider}/>

        {/* DEVICE HEADER */}
        <Stack
          direction='row'
          justifyContent='space-between'
          margin='16px 0px 12px'
        >
          {/* SUB TITLE */}
          <Typography
            fontSize={20}
            fontWeight={600}
          >
            Devices
          </Typography>

          {/* MANAGE DEVICES BUTTON */}
          <Button 
            className={classes.deviceButton}
            onClick={(event) => setDevicesMenuAnchor(event.currentTarget)}
          >
            Manage Devices
          </Button>
        </Stack>

        {/* DEVICE CONTENT */}
        {cycleDetail?.deviceList?.length > 0 ? (
          <Stack
            direction='row'
            gap='4px'
            flexWrap='wrap'
            width='100%'
            maxWidth='100%'
          >
            {cycleDetail?.deviceList?.map((item, index) => (
              <Chip
                key={index}
                variant='filled'
                label={item.label}
                size='small'
              />
            ))}
          </Stack>
        ) : (
          <Typography
            variant='caption'
            padding='12px'
            backgroundColor={(theme) => theme.palette.grey[200]}
            borderRadius='4px'
          >
            No device added yet. Click&nbsp;
            <Typography
              variant='inherit'
              fontWeight={600}
              display='inline-block'
            >
              “Manage Devices”
            </Typography> 
            &nbsp;to add and remove devices for this cycle.
          </Typography>
        )}
      </Stack>

      {/* MANAGE DEVICES MENU */}
      <FlyoutMenuManageDevices
        devicesMenuAnchor={devicesMenuAnchor}
        setDevicesMenuAnchor={setDevicesMenuAnchor}
        initialDeviceIdList={cycleDetail?.device?.map(item => item.id)}
        deviceList={deviceList} 
        setDeviceList={setDeviceList}
        setShouldAssignDevices={setShouldAssignDevices}
      />
    </DialogAddOrEdit>
  )
}

export default Flyout