import { useState, useEffect } from 'react'

// MUIS
import Checkbox from '@mui/material/Checkbox'
import Menu from '@mui/material/Menu'
import FormControl from '@mui/material/FormControl'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

// SERVICES
import { getTrackerList } from 'services/trackerServices'

// STYLES
import useStyles from './flyoutMenuManageDevicesUseStyles'

const FlyoutMenuManageDevices = (props) => {
  const { 
    devicesMenuAnchor, setDevicesMenuAnchor, 
    initialDeviceIdList,
    deviceList, setDeviceList,
    setShouldAssignDevices,
  } = props

  const classes = useStyles()

  const [ search, setSearch ] = useState('')
  const [ allCheckboxStatus, setAllCheckboxStatus ] = useState(null)

  const handelCloseMenu = () => {
    const selectedDeviceIdList = deviceList.filter(item => item.isSelected).map(item => item.id)

    if (JSON.stringify(initialDeviceIdList) !== JSON.stringify(selectedDeviceIdList)) {
      setShouldAssignDevices(true)
    }

    setDevicesMenuAnchor(null)
  }

  const handleAllCheckboxClick = (isChecked) => {
    setDeviceList(current => {
      const newDeviceList = [...current].map(item => {
        return {
          ...item,
          isSelected: isChecked,
        }
      })

      return newDeviceList
    })
  }

  const updateAllCheckboxStatus = () => {
    const isSelectedList = deviceList.map(item => item.isSelected)
    const trueCount = isSelectedList.filter(item => item === true).length
    const falseCount = isSelectedList.filter(item => item === false).length

    let status
    if (trueCount > 0 && falseCount > 0) status = 'indeterminate' 
    else if (trueCount === 0 && falseCount > 0) status = null
    else if (trueCount > 0  && falseCount === 0) status = 'checked'

    setAllCheckboxStatus(status)
  }

  const handleSearchChange = (value) => {
    setSearch(value)
    
    setDeviceList(current => {
      let newDeviceList

      if (value !== '') {
        newDeviceList = current.map(item => {
          return {
            ...item,
            isShown: item.label.toLowerCase().includes(value.toLowerCase()) ? true : false,
          }
        })
      }
      else {
        newDeviceList = current.map(item => {
          return {
            ...item,
            isShown: true,
          }
        })  
      }

      return newDeviceList
    })
  }

  const handleDeviceOptionClick = (index) => {
    setDeviceList(current => {
      const newDeviceList = current.map((deviceItem, deviceIndex) => {
        return {
          ...deviceItem,
          isSelected: index === deviceIndex ? !deviceItem.isSelected : deviceItem.isSelected,
        }
      })

      return newDeviceList
    })
  }

  const getTrackerListData = async () => {
    const resultTrackerList = await getTrackerList()
    
    if (resultTrackerList.status === 200) {
      const newDeviceList = resultTrackerList.data.map(item => {
        return {
          ...item,
          isShown: true,
          isSelected: false,
        }
      })

      setDeviceList(newDeviceList)
    }
  }

  useEffect(() => {
    getTrackerListData()
  }, [])

  useEffect(() => {
    updateAllCheckboxStatus()
  }, [deviceList])

  return (
    <Menu
      anchorEl={devicesMenuAnchor}
      open={Boolean(devicesMenuAnchor)}
      onClose={handelCloseMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      className={classes.menu}
    >
      {/* SEARCH */}
      <FormControl 
        variant='standard'
        fullWidth
        className={classes.menuSearchInput}
      >
        <Input
          placeholder='Quick Search'
          value={search}
          onChange={(event) => handleSearchChange(event.target.value)}
          startAdornment={
            <InputAdornment position='start'>
              <Checkbox
                checked={allCheckboxStatus === 'checked'}
                indeterminate={allCheckboxStatus === 'indeterminate'}
                onChange={(event) => handleAllCheckboxClick(event.target.checked)}
                size='small'
              />
            </InputAdornment>
          }
        />
      </FormControl>

      {/* DEVICE OPTION LIST */}
      <List 
        disablePadding
        className={classes.menuList}
      >
        {deviceList.map((item, index) => (
          item.isShown &&
          <ListItemButton 
            key={index} 
            className={classes.menuOption}
            onClick={() => handleDeviceOptionClick(index)}
          >
            {/* CHECKBOX */}
            <ListItemIcon>
              <Checkbox 
                size='small'
                checked={item.isSelected}
                onChange={() => {}}
              />
            </ListItemIcon>

            {/* LABEL */}
            <ListItemText primary={item.label}/>
          </ListItemButton>
        ))}
      </List>
    </Menu>
  )
}

export default FlyoutMenuManageDevices